import React, { cloneElement, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Typography,
  ListItemIcon
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { selectedIcons } from '../custom_fields/CustomFields';
import { createCustomField } from '../../hooks/mutations';
import { InputField } from '../ui/Fields';
import { getRandomInts } from '../../lib/Functions';

const Step3CreateCustomFields = (props) => {
  const { trigger, setIsAddingField, setIsConnectingFields, incomingNewCustomFields, headers, preview, setReload } = props;
  const theme = useTheme();
  const [newCustomFields, setNewCustomFields] = useState(incomingNewCustomFields ? incomingNewCustomFields : []);

  const screenSize = useScreenSize();

  // console.log('incomingNewCustomFields', incomingNewCustomFields);
  // console.log('preview', preview);
  // console.log('headers', headers);

  useEffect(() => {
    if (incomingNewCustomFields) {
      setNewCustomFields(incomingNewCustomFields);
    }
  }, [incomingNewCustomFields])

  const customFieldData = {
    "id": getRandomInts(16), // crypto.randomUUID(),
    "data_type": "text",
    "field_name": ""
  };

  const handleAddCustomField = () => {
    setNewCustomFields(prev => [
      ...prev,
      customFieldData
    ]);
  };

  const handleFieldType = (value, id) => {
    const newFields = [...newCustomFields];
    const updated = newFields.map(x => {
      if (x.id === id) {
        return {
          ...x,
          data_type: value
        };
      } else {
        return x;
      }
    });

    setNewCustomFields(updated);
  };

  const handleFieldName = (value, id) => {
    const newFields = [...newCustomFields];
    const updated = newFields.map(x => {
      if (x.id === id) {
        return {
          ...x,
          field_name: value
        };
      } else {
        return x;
      }
    });
    setNewCustomFields(updated);
  };

  const cancelNewFields = () => {
    if (setIsAddingField) {
      setIsAddingField(false);
    }
    setNewCustomFields([]);
  };

  const postCustomField = async (customField) => {
    try {
      const customFieldObj = {
          payload: {
          'field_name': customField.field_name,
          'data_type': customField.data_type,
          'validation_rule': customField.data_type === 'list' ? 'enum_values' : null,
          'enum_values': customField.data_type === 'list' || customField.data_type === 'enum' ? ['placeholder'] : null
      }
      }
      const posted = await createCustomField(customFieldObj);
      if (posted.status === 'success') {
        console.log(`posted ${customField.field_name}:`, posted);
      } else {
        throw new Error('Failed')
      }
    }

    catch (error) {
      console.log('Error posting custom field', error);
    }
  }

  
  useEffect(() => {
    if (trigger) {
      handleSubmit();
    }
  }, [trigger])

  const handleSubmit = async () => {
    try {
        // Use Promise.all() to wait for all postCustomField promises to resolve
        await Promise.all(newCustomFields.map(customField => postCustomField(customField)));
        setIsConnectingFields(true); // This line will only execute if all postCustomField calls succeed
        setReload(true);
      } catch (error) {
        console.log('error', error);
    }
};

  return (
    <Grid
      container
      // style={{
      //   border: `2px solid ${theme.palette.primary.main}`,
      //   borderRadius: 8,
      //   padding: 24
      // }}
    >
      <Grid
        container
        display={screenSize.xs ? 'none' : 'inherit'}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
        >
          <Typography

          >
            Field Name
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
        >
          <Typography

          >
            Field Type
          </Typography>
        </Grid>
      </Grid>
      {newCustomFields.map((field, index) => (
        <Grid
          container
          style={{
            marginTop: index === 0 ? '16px' : null,
            alignItems: 'center',
            // background: index % 2 ? `${theme.palette.primary.light}30` : 'transparent'
          }}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              color="mid.main"
              style={{
                fontStyle:'italic'
              }}
            >
              From {headers[field.headerIndex]}
            </Typography>
          </Grid>

          <Grid
            item
            paddingRight={screenSize.xs ? 0 : 2}
            marginBottom={screenSize.xs ? 1 : 0}
            xs={12}
            sm={6}
            md={4}
          >
            <InputField
              placeholder='Field Name'
              value={newCustomFields[index]?.["field_name"]}
              onChange={(e) => handleFieldName(e.target.value, field.id)}
            />
          </Grid>

          <Grid
            item
            px={0.5}
            xs={12}
            sm={6}
            md={8}
          >
            <Grid
              container
              style={{
                gap: 8,
              }}
            >
              {screenSize.lg
                ?
                <TextField
                  select
                  fullWidth
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight:1,
                      height:55
                    }
                  }}
                  style={{
                    margin: 0,
                    borderRadius: 4,
                    padding:'0 16px',
                    boxShadow: '0 0 5px #00000030',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  value={newCustomFields[index]["data_type"]}
                  onChange={(e) => handleFieldType(e.target.value, field.id)}
                >
                  {Object.entries(selectedIcons).map(([key, value]) => (
                    <MenuItem
                      key={key}
                      value={key}
                    >
                      <ListItemIcon>
                        {cloneElement(value.icon, { width: key === 'number' || key === 'large_text_field' ? 24 : 20, color1: theme.palette.primary.main, style:{height:20} })}
                      </ListItemIcon>

                      {value.label}

                    </MenuItem>
                  ))}
                </TextField>
                :
                Object.entries(selectedIcons).map(([key, value], iconIndex) => (
                  <Grid
                    item
                    xs
                    sm={2.32}
                  >
                    <InputButtons
                      type={key}
                      value={value}
                      fieldType={newCustomFields[index]["data_type"]}
                      onClick={() => handleFieldType(key, newCustomFields[index].id)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography
              color="mid.main"
              style={{
                fontStyle:'italic',
                marginTop:16
              }}
            >
              {
                preview[field.headerIndex].length
                  ? `Examples from ${headers[field.headerIndex]}`
                  : 'No examples found in uploaded data'
              }
            </Typography>

            <Grid
              container
            >
              {
                preview[field.headerIndex].map(item => (
                  <Grid
                    item
                    style={{
                      padding:'2px 8px',
                      margin:'4px',
                      background:'#ddd',
                      borderRadius:'4px'
                    }}
                  >
                    <Typography

                    >
                      {item}
                    </Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Divider style={{width:'100%', margin:'24px 0'}} />

        </Grid>
      ))}
      <Button
        onClick={handleAddCustomField}
        disabled={newCustomFields.filter(x => x.field_name.length < 1).length > 0}
        fullWidth
        style={{
          outline: `2px dashed ${newCustomFields.filter(x => x.field_name.length < 1).length > 0 ? "#ccc" : theme.palette.primary.main}`,
          height: 50,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 8,
          gap: 8,
          margin: '8px 0',
          textTransform: 'initial'
        }}
      >
        <AddSVG width={16} color1={newCustomFields.filter(x => x.field_name.length < 1).length > 0 ? '#ccc' : theme.palette.primary.main} />
        <Typography style={{ fontWeight: 600 }}>New Field</Typography>
      </Button>

      {/* <Grid
        container
        sx={{
          justifyContent: 'space-between',
          marginTop: '8px'
        }}
      >
        <Button
          onClick={cancelNewFields}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!newCustomFields.filter(x => x.field_name.length > 0).length}
        >
          Save
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default Step3CreateCustomFields

export const InputButtons = (props) => {
  const { fieldType, type, value, onClick } = props;
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Button
      onClick={onClick}
      fullWidth
      style={{
        outline: fieldType === type ? `2px solid ${theme.palette.primary.main}` : 'none',
        textTransform:'lowercase',
        lineHeight:1,
        height:'100%',
        boxShadow:'0 0 5px #00000030',
        alignItems:'center',
        // padding:'16px 16px',
        maxHeight:27,
        gap:8,
        borderRadius:8,
        display:'flex',
        color: fieldType ? (fieldType === type ? theme.palette.primary.main : '#aaa') : 'inherit'
      }}
    >
      <Grid
        sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          width:25
        }}
      >
        {cloneElement(value.icon, {
          color1:fieldType === type ? theme.palette.primary.main : "#aaa"
        })}
      </Grid>
      {screenSize.lg ? null : value.label}

    </Button>
  )
}
