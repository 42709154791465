import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography, Stack } from '@mui/material'
import { theme } from '../../theme'
import { UpFilledSVG } from '../../assets/icons'
import { useLocation } from 'react-router-dom'
import { hashScroll } from '../../lib/Functions'
import PriceTableItem from './PriceTableItem'
import PriceFeatureList from './PriceFeatureList'
import PricingDetails from './PricingDetails'
import { featureList } from '../../lib/Data'



const Pricing = () => {
  const [priceModel, setPriceModel] = useState('monthly');

  const location = useLocation();

  //SignedPic app places the priceTable array in Product.js in /lib and imports it into Pricing.js.
  const priceTable = [
    {
      title:'Basic',
      id:'basic',
      priceMon:'38',
      priceAnn:'29',
      priceMonId: 1,
      priceAnnId: 2,
      term:'month',
      description:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      checkout:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      list:[
        {
          name: `Unlimited contacts`,
          short: `Expand your reach without limits.`,
          description: `There are no boundaries to the number of contacts you can manage, ensuring you can nurture relationships and grow your audience without constraints.`,
          icon: `unlimited-contacts`,
          availability: `now`,
        },
        {
          name: `Email Templates`,
          short: `Make every message memorable.`,
          description: `Choose from our wide array of professionally designed email templates, customizable to reflect your brand identity, ensuring your emails captivate and engage your audience.`,
          icon: `email-love`,
          availability: `now`,
        },
        // {
        //   name: `Custom email builder`,
        //   short: `Personalize your communications with ease.`,
        //   description: `Design custom emails quick and easy with our drag and drop email builder. Tailored to your audience's preferences, fostering meaningful connections and driving engagement has never been easier.`,
        //   icon: ``,
        //   availability: `soon`,
        // },
        // {
        //   name: `Automated email campaigns`,
        //   short: `Save time and boost efficiency.`,
        //   description: `Utilize Fill Marketing's automated email campaign feature to schedule and send targeted messages at the optimal times, nurturing leads and guiding them through the customer journey effortlessly.`,
        //   icon: ``,
        //   availability: `later`,
        // },
        {
          name: `Client Relationship Management`,
          short: `Strengthen connections and drive growth.`,
          description: `Centralize customer data, track interactions, and gain valuable insights to enhance customer satisfaction and drive informed decision-making.`,
          icon: ``,
          availability: `now`,
        },
        {
          name: `Custom properties`,
          short: `Capture unique insights and segment your audience effectively.`,
          description: `Define custom properties on contacts with Fill Marketing, enabling targeted campaigns and personalized messaging based on specific criteria.`,
          icon: ``,
          availability: `now`,
        },
        {
          name: `Dedicated IP for email sending`,
          short: `Ensure deliverability and reputation.`,
          description: `Leverage a dedicated IP for email sending to maintain control over your email reputation, maximize deliverability, and foster trust with your audience.`,
          icon: ``,
          availability: `now`,
        },
        // {
        //   name: `Role-based access`,
        //   short: `Safeguard sensitive data and streamline collaboration.`,
        //   description: `Define user roles and permissions, empowering your team to work efficiently while maintaining security and control.`,
        //   icon: ``,
        //   availability: `later`,
        // },
        {
          name: `Mailing list subscription forms`,
          short: `Grow your subscriber base effortlessly.`,
          description: `Create and embed mailing list subscription forms on your website, allowing visitors to opt-in and stay connected with your brand seamlessly.`,
          icon: ``,
          availability: `now`,
        },
        // {
        //   name: `Send emails directly from CRM`,
        //   short: `Streamline your workflow and enhance efficiency.`,
        //   description: `Send emails directly from your CRM system, ensuring seamless communication and eliminating the need for switching between platforms.`,
        //   icon: ``,
        //   availability: `later`,
        // },
        {
          name: `Double opt-in`,
          short: `Build trust and enhance deliverability.`,
          description: `Use double opt-in to confirm subscribers' intentions and ensure compliance with regulations, fostering meaningful engagement and better deliverability rates.`,
          icon: ``,
          availability: `now`,
        },
        // {
        //   name: `Build custom surveys`,
        //   short: `Gain valuable insights and feedback.`,
        //   description: `Create custom surveys tailored to your specific needs, enabling you to gather actionable data and make informed decisions to drive growth.`,
        //   icon: ``,
        //   availability: `soon`,
        // },
        {
          name: `Embedded surveys`,
          short: `Gather feedback and engage your audience wherever they are.`,
          description: `Send surveys directly in emails or embed them on your website, making it convenient for recipients to provide valuable input and insights.`,
          icon: ``,
          availability: `soon`,
        },
        // {
        //   name: `Add contacts from email`,
        //   short: `Add email senders directly to your contact list.`,
        //   description: `Forward one or multiple emails to our automated contact creation to add the sender of each email to your contact list.`,
        //   icon: ``,
        //   availability: `later`,
        // },
      ],
      moreButton:
        <Button
          onClick={() => hashScroll('basic')}
        >
          See More Details
        </Button>
      ,
      ctaButton:
      <Button
        variant="contained"
        href=""
      >
        GET STARTED
      </Button>
    }
  ]

  //SignedPic app places the plans object in Product.js in /lib  and imports it into Pricing.js.
  const plans = {
    basic:[
      {
        label:'Example Feature #1',
        info:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        icon:null,
      },
      {
        label:'Example Feature #2',
        info:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        icon:null,
      },
      {
        label:'Example Feature #3',
        info:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        icon:null,
      },
    ]
  }

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location])

  return (
    <>
      <Grid
        id="top"
        variant="center"
        style={{
          minHeight: 'calc(100vh - 80px)'
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 1400,
            zIndex: 1
          }}
        >
          <Grid
            container
            display="column"
            style={{
              // background: `${theme.palette.background.main}aa`,
              borderRadius: 16,
              // boxShadow: '0 0 10px #000000030',
              justifyContent:'center',
              paddingBottom:16
            }}
          >
            <Grid
              container
              style={{
                padding: 16,
                marginTop: 100,
                justifyContent: 'center'
              }}
            >
              <Grid
                variant="center"
                flexDirection="column"
              >

                <Typography variant="h4">
                  <span style={{fontWeight:700}}>MORE BANG!</span> Less Buck.
                </Typography>
                <Typography>
                  One price for everything we have. It's that simple. Forever.
                </Typography>
                <Grid
                  variant="center"
                  onClick={() => {
                    if (priceModel === 'annual') {
                      setPriceModel('monthly')
                    } else {
                      setPriceModel('annual')
                    }
                  }}
                  style={{
                    outline: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 32,
                    background: `${theme.palette.background.main}cc`,
                    padding: 4,
                    margin: '16px 0'
                  }}
                >
                  <Grid
                    style={{
                      padding: '4px 16px',
                      background: priceModel === 'monthly' ? theme.palette.primary.main : 'transparent',
                      transition: '.4s',
                      borderRadius: 32,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: priceModel === 'monthly' ? '#ffffff' : '#777777',
                      }}
                    >
                      Monthly
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: '4px 16px',
                      background: priceModel === 'annual' ? theme.palette.primary.dark : 'transparent',
                      transition: '.4s',
                      borderRadius: 32,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: priceModel === 'annual' ? '#ffffff' : '#777777',
                      }}
                    >
                      Annual
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              priceTable.map((item, index) => (
                <Grid
                  item
                  xs={11.5}
                  // sm={6}
                  // md={12 / priceTable.length}
                  // p={2}
                >
                  <PriceTableItem
                    stacked
                    title={item.title}
                    priceMon={item.priceMon}
                    priceAnn={item.priceAnn}
                    priceModel={priceModel}
                    term={item.term}
                    description={item.description}
                    list={item.list}
                    moreButton={item.moreButton}
                    ctaButton={item.ctaButton}
                    subscriptionDetails={item}
                  />
                </Grid>
              ))
            }

          </Grid>
        </Grid>
      </Grid>

      
      {/* <PricingDetails
        id="basic"
        title="Basic"
        subscription="Basic"
        subscriptionDetails={priceTable.filter(x => x.title === "Basic")[0]}
        showOptions
        heading={
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit 
          `
        }
        description={
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  
          `
        }
      >
        <PriceFeatureList
          features={plans.basic}
          info
          wide
        />
      </PricingDetails>       */}

      {/* <UpFilledSVG
        width={60}
        color1={theme.palette.primary.light}
        onClick={() => hashScroll("top")}
        style={{
          position: 'fixed',
          bottom: 50,
          right: 50,
          zIndex: 1
        }}
      /> */}


    </>
  )
}

export default Pricing