import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const formDataState = [
  {
    type: 'radio',
    question: 'Question Radio',
    answers: [
      {
        answer: 'Answer 1',
        value: 'Answer 1',
      },
      {
        answer: 'Answer 2',
        value: 'Answer 2',
      },
      {
        answer: 'Answer 3',
        value: 'Answer 3',
      },
      {
        answer: 'Answer 4',
        value: 'Answer 4',
      },
    ],
  },
  {
    type: 'checkbox',
    question: 'Question Checkbox',
    answers: [
      {
        answer: 'Answer 1',
        value: 'Answer 1',
      },
      {
        answer: 'Answer 2',
        value: 'Answer 2',
      },
      {
        answer: 'Answer 3',
        value: 'Answer 3',
      },
      {
        answer: 'Answer 4',
        value: 'Answer 4',
      },
    ],
  },
  {
    type: 'bool',
    question: 'Do you like question strings?',
    answers: [
      {
        answer: 'Yes',
        value: true,
      },
      {
        answer: 'No',
        value: false,
      },
    ],
  },
  {
    type: 'textfield',
    question: 'Question string',
    answers: [
      {
        answer: 'No',
        value: false,
      },
    ],
  },
];

const DragAndDropForm = (props) => {
  const { incomingData, updateData, currentPage, setCurrentPage, title, style } = props;
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [questionData, setQuestionData] = useState(incomingData);

  useEffect(() => {
    if (incomingData) {
      setQuestionData(incomingData);
    }
  }, [incomingData]);

  const handleDataUpdate = () => {
    updateData(prev => ({
      ...prev,
      questions:questionData
    }));
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnter = (index) => {
    if (draggedIndex === null || index === draggedIndex) return;

    setPlaceholderIndex(index);

    const newData = [...questionData];
    const [draggedItem] = newData.splice(draggedIndex, 1);
    newData.splice(index, 0, draggedItem);

    setQuestionData(newData);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setPlaceholderIndex(null);
    setDraggedIndex(null);
    handleDataUpdate();
  };

  return (
    <div style={{...style}}>
      {questionData?.map((item, index) => (
        <div
          key={index}
          draggable
          onClick={() => setCurrentPage(index)}
          onDragStart={() => handleDragStart(index)}
          onDragEnter={() => handleDragEnter(index)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnd={handleDragEnd}
        >
          {
            // placeholderIndex === index &&
            //   <PlaceholderElement>
            //   {item.question
            //     ? item.question
            //     : 'No Question'
            //   }
            //   </PlaceholderElement>
          }
          <ItemElement index={index} item={item} draggedIndex={draggedIndex} currentPage={currentPage}>
            <Typography
              style={{
                fontSize:'.8rem',
                textTransform:'uppercase'
              }}
            >
              {title ? title : `Question ${index+1}`}
            </Typography>
            {item.text
              ? item.text
              : 'No Question'
            }
          </ItemElement>
        </div>
      ))}
    </div>
  );
};
export default DragAndDropForm;

const ItemElement = (props) => {
  const { children, index, item, draggedIndex, currentPage } = props;
  const theme = useTheme();

  return (
    <div
      style={{
        padding:'8px 24px',
        borderRadius:4,
        margin:'4px 0',
        background: currentPage === index ? `${theme.palette.primary.light}77` : '#fff',
        pointerEvents: draggedIndex === index ? 'none' : 'inherit' ,
        maxWidth:150,
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        overflow:'hidden',
        // opacity: draggedIndex === index ? 0 : 1
      }}
    >
      <Typography
        style={{
          fontSize:'1.2rem',
          color: item.text ? 'inherit' : currentPage === index ?'#fff' : '#ccc',
          whiteSpace:'nowrap',
          textOverflow:'ellipsis',
          overflow:'hidden'
        }}
      >
        {children}
      </Typography>
        
    </div>

  )
}

const PlaceholderElement = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        padding:'8px 24px',
        margin:'16px 0',
        background:'#fff',
        border:'3px dashed #ccc'
      }}
    >
      <Typography
        style={{
          fontSize:'1.2rem'
        }}
      >
        {children}
      </Typography>
        
    </div>

  )
}
