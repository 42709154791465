import React, { useState } from 'react';
import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-newsletter';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { createEmailTemplate } from '../../hooks/mutations';

const NewEmailTemplateForm = (props) => {
  const { handleCloseCreateTemplate, setReset } = props;

  const [templateName, setTemplateName] = useState('');

  const theme = useTheme();

  const remoteStoragePlugin = (editor) => {

    editor.Storage.add('remote', {

      //*** NON-IDEAL HACK TO PREVENT 'STORAGE.LOAD IS NOT A FUNCTION' JS ERROR:
      // PASS IN SEEDED DATA FOR EDITOR TO LOAD IN HIDDEN COLLAPSABLE ELEMENT IN EMAILTEMPLATESMENU COMPONENT ***//
      async load() {
        const result = {
          data: {"assets":[],"styles":[],"pages":[{"frames":[{"component":{"type":"wrapper","stylable":["background","background-color","background-image","background-repeat","background-attachment","background-position","background-size"]},"id":"KIXcrfRZQ4AlL8PZ"}],"type":"main","id":"Eal7lkIrOh4SKg0F"}]}
        }
        console.log('result', result);
        return result;
      },

      async store(data) {
        try {
          const jsonBlob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
          const fileName = 'template.json';
          const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
          const formData = new FormData();
          formData.append('file', jsonFileObj);
          formData.append('name', templateName);
          formData.append('folder', 1);

          const emailTemplateObj = {
            templatePayload: {
              'name': templateName,
              'content': '',
              'update_date': new Date(),
              'editor_json': null,
              'last_used_campaign': null 
            },
            storagePayload: formData
          }

          console.log('emailTemplateObj', emailTemplateObj)

          const savedTemplate = createEmailTemplate(emailTemplateObj);
          if (savedTemplate.status === "success") {
            setTemplateName('');
            handleCloseCreateTemplate();
            setReset(true);
          }
        } catch (error) {
          console.log('Error creating new email template', error)
        }
      }
    });
  }

  const editor = grapesjs.init({
    container: '#gjs',
    storageManager: {
      type: 'remote',
      options: {
        remote: {
          //Enrich remote call to include HTML/CSS of pages along with IDs and data
          onStore: (data, editor) => {

            //***THESE TWO LINES FOR DEBUGGING PURPOSES ONLY ***//
            console.log(editor.runCommand('gjs-get-inlined-html'));
            console.log(JSON.stringify(editor.getProjectData()));

            const htmlWithCss = editor.runCommand(`gjs-get-inlined-html`);
            return { data, content: htmlWithCss }
          },
          onLoad: result => result.data,
          contentTypeJson: true
        }
      }
    },
    plugins: [plugin, remoteStoragePlugin]
  });

  console.log('editor', editor)

  const createNewTemplate = async () => {
    if (templateName != null && templateName != '') {
      await editor.store();
    } else {
      return;
    }
  }

  return (
      <Grid
        container
        style={{
          justifyContent: 'center',
        }}
      >
        <Grid
          ts={12}
          // md={8}
          style={{
            padding: 16
          }}
        >
          <Grid
            variant="center"
            sx={{
              // justifyContent:'center'
            }}
          >
            <Typography
              variant="h4"
              sx={{
                ml: 2,
                color: theme.palette.primary.dark,
                fontWeight: 600
              }}
            >
              Create a new template
            </Typography>
          </Grid>
          <Grid>
            <TextField
              label="Template Name"
              placeholder="Template Name"
              fullWidth
              value={templateName}
              onChange={(e) => { setTemplateName(e.target.value); }}
              required
            />
          </Grid>
          <Grid>
            <Button
              onClick={createNewTemplate}
              variant="contained"
              fullWidth
              disabled={templateName != null && templateName != '' ? false : true}
            >
              <Typography
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 900,
                  fontSize: 18,
                  color: '#FFFFFF'
                }}
              >
                Create Template
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    
  );
}

export default NewEmailTemplateForm;