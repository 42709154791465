import { useTheme } from '@emotion/react';
import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useScreenSize } from '../../lib/Interface';

const Paginate = (props) => {
  const { nextClick, prevClick, directPage, currentPage, count } = props;
  const theme = useTheme();
  const screenSize = useScreenSize();
  const [numPages, setNumPages] = useState(1);
  const [numArray, setNumArray] = useState([1]);
  const totalPages = Math.ceil(count / 20);

  // console.log('currentPage', currentPage)

  useEffect(() => {
    if (count) {
      setNumPages(totalPages)
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);
  
      // Adjust startPage and endPage if we have less than 5 pages
      if (endPage - startPage < 4) {
        if (startPage === 1) {
          endPage = Math.min(5, totalPages);
        } else {
          startPage = Math.max(1, totalPages - 4);
        }
      }
  
      const arrayLength = Math.min(endPage - startPage + 1, 5);
      const numbersArray = Array.from({ length: arrayLength }, (_, index) => startPage + index);
      setNumArray(numbersArray);
      }
  }, [count, currentPage])


  return (
    <Grid
      container
      style={{
        justifyContent:'space-evenly',
        alignItems:'center',
        borderTop:`1px solid ${theme.palette.primary.main}`,
        padding:'8px 0',
        height:55
      }}
    >

      <Button
        onClick={prevClick}
      >
        BACK
      </Button>

      <Grid
        style={{
          display:'flex',
          alignItems:'center'
        }}
      >
        {
          screenSize.sm
            ?
              <Typography>
                {currentPage} / {numPages}
              </Typography>
            :
              <>
                {
                  numArray?.map(num => (
                    <Grid
                      key={num}
                      onClick={() => directPage(num)}
                      style={{
                        width:30,
                        height:30,
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:20,
                        background: num == currentPage ? theme.palette.primary.main : 'transparent',
                        color:num == currentPage ? '#fff' : theme.palette.primary.main,
                        cursor: 'pointer'
                      }}
                    >
                      <Typography lineHeight={1}>{num}</Typography>
                    </Grid>
                  ))
                }
                {
                  !numArray.includes(totalPages) &&
                    <>
                      <Typography>of</Typography>
                      <Grid
                        onClick={() => directPage(totalPages)}
                        style={{
                          width:30,
                          height:30,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          borderRadius:20,
                          // background: num == currentPage ? theme.palette.primary.main : 'transparent',
                          // color:num == currentPage ? '#fff' : theme.palette.primary.main,
                          cursor: 'pointer'
                        }}
                      >
                        <Typography color={theme.palette.primary.main} lineHeight={1}>{totalPages}</Typography>
                      </Grid>
                    </>
                }

              </>

        }


      </Grid>

      <Button
        onClick={nextClick}
      >
        NEXT
      </Button>

    </Grid>
  )
}

export default Paginate