import { useTheme } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getScreenSize, useScreenSize, useScreenSizeCalc } from '../../lib/Interface'
import { LogoHorizontal } from '../../assets/logos'
import { ScrollToGrow } from '../ui/ScrollAnimations'
import { Angle } from '../../assets/graphics'
import MainNav from '../nav/MainNav'
import { featureList } from '../../lib/Data'
import Pricing from '../pricing/Pricing'

const Landing = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary
      }}
    >
      <MainNav />
      <Header />
      <About />
      <Features />
      <Prices />
    </Grid>
  )
}

export default Landing

const Header = () => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Grid
      container
      style={{
        posiiton:'relative',
        background: theme.palette.primary.dark,
        height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
        justifyContent:'center',
        // marginTop:60,
        overflow:'hidden'
      }}
    >
      <Grid
        style={{
          position:'absolute',
          top:60,
          right:0,
          height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
          background:'blue',
          background:'url(https://images.unsplash.com/photo-1554902843-260acd0993f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
          backgroundPosition:'center',
          width:'50vw',
          overflow:'hidden'
        }}
      >
        <Angle width={"100%"} height="100%" color1={theme.palette.primary.dark} style={{height:'100%', width:'100%'}} />
        {/* <img
          // src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          src="https://images.unsplash.com/photo-1554902843-260acd0993f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{
            objectFit:'cover',
            width:'50vw',
            height:'100%',
            // border:'10px solid #fff',
            // zIndex:1
            // opacity:.15
          }}
        /> */}
      </Grid>

      <Grid
        container
        sx={{
          position:'relative',
          alignItems:'center',
          height:'100%',
          width:{xs:250, sm:500, md:800, xl:1000}
        }}
      >
        {/* <img
          src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{
            position:'absolute',
            // top: ( screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh') ) - 200,
            bottom:-150,
            right:-200,
            objectFit:'cover',
            width:'50vw',
            minWidth:400,
            maxWidth:600,
            height:'50vw',
            minHeight:400,
            maxHeight:600,
            borderRadius:400,
            // border:'10px solid #fff',
            zIndex:1
            // opacity:.15
          }}
        /> */}
        <Typography
          sx={{
            fontSize: {xs: '3rem', sm:'4rem', md:'5rem', lg:'5rem', xl:'8rem'},
            fontWeight:700,
            lineHeight:1,
            textTransform:'uppercase',
            color:theme.palette.primary.light, //'#fff'
            textShadow:`
              5px 5px ${theme.palette.primary.dark},
              0px 5px ${theme.palette.primary.dark},
              5px 0px ${theme.palette.primary.dark},
              -5px -5px ${theme.palette.primary.dark},
              0px -5px ${theme.palette.primary.dark},
              -5px 0px ${theme.palette.primary.dark},
              -5px 5px ${theme.palette.primary.dark},
              5px -5px ${theme.palette.primary.dark}
            `,
            zIndex:2
          }}
        >
          Built for small companies with large reach. 
        </Typography>
      </Grid>
    </Grid>
  )
}

const Navbar = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        background: '#fff',
        height:60,
        padding:'0 24px',
        justifyContent:'space-between'
      }}
    >
      <Grid
        style={{
          display:'flex',
          alignItems:'center'
        }}
      >
        <LogoHorizontal height={24} />
      </Grid>
      <Grid
        style={{
          display:'flex',
          alignItems:'center'
        }}
      >
        <NavButton
          label="About"
        />
        <NavButton
          label="Features"
        />
        <NavButton
          label="Pricing"
        />
        <NavButton
          label="Sign Up"
        />
        <NavButton
          label="Login"
        />
      </Grid>
    </Grid>
  )
}

const NavButton = (props) => {
  const { label } = props;

  return (
    <Button
      onClick={() => {}}
      style={{
        fontWeight:600
      }}
    >
      {label}
    </Button>
  )
}

const About = () => {
  const theme = useTheme();
  return (
    <CenterColumn id="about">
      <Typography
        sx={{
          fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
          fontWeight:700,
          lineHeight:1,
          // textTransform:'uppercase',
          color:theme.palette.primary.dark, //'#fff'
          zIndex:2
        }}
      >
        The best alternative to HubSpot.
      </Typography>
      <Typography
        style={{
          fontSize:'1.4rem',
          lineHeight:1.2,
          marginTop:16
        }}
      >
        Most Client Relationship Management tools focus on large enterprise businesses—and they price it that way. Not us.
      </Typography>
    </CenterColumn>
  )
}

const Features = () => {
  return (
    <CenterColumn id="features">
      <Grid
        container
      >
        {
          featureList.map((feature, index) => (
            <FeatureItem
              key={feature.name}
              feature={feature}
              index={index}
            />
          ))
        }
      </Grid>
    </CenterColumn>
  )
}

const FeatureItem = (props) => {
  const { feature, index } = props;
  const [available, setAvailable] = useState({
    label: '',
    color: null,
    background: null
  });
  const theme = useTheme();
  const reverse = index % 2;

  useEffect(() => {
    if (feature) {
      if (feature.availability === "now") {
        setAvailable({
          label: 'Available Now',
          color: '#fff', //nowTheme.color,
          background: theme.palette.primary.main //nowTheme.background
        })
      } else
      if (feature.availability === "soon") {
        setAvailable({
          label: 'Coming Soon',
          color: '#fff', //theme.palette.primary.dark, //soonTheme.color,
          background: theme.palette.primary.light //soonTheme.background
        })
      } else
      if (feature.availability === "later") {
        setAvailable({
          label: 'Later This Year',
          color: '#fff', //laterTheme.color,
          background: theme.palette.secondary.main //laterTheme.background
        })
      }
    }
  }, [feature])
  return (
    <ScrollToGrow noRepeat rootMargin="0px">

    <Grid
      container
      // flexDirection={ reverse ? "row" : "row-reverse"}
      style={{
        margin:'48px 0',
        alignItems:'stretch',
        // marginLeft: reverse ? 250 : 0
      }}
    >
      <Grid
        item
        style={{
          height:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'flex-end',
          padding:'0 48px'
        }}
        xs="auto"
        // md={6}
      >
            {
              feature.icon
                ?
                  <img
                    src={`/img/${feature.icon}_500px.png`}
                    style={{
                      height:200,
                      width:200,
                      objectFit:'contain'
                    }}
                  />
                :
                  null
            }
          {/* <Grid
            container
          >
          </Grid> */}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
          <Grid
            container
          >
            <Typography
              style={{
                width:'100%',
                // textAlign: reverse ? 'right' : 'left',
                lineHeight:1,
                fontSize:'1.65rem',
                fontWeight:600,
                marginBottom:8,
                color:theme.palette.primary.dark
              }}
            >
              {feature.name}
            </Typography>
            <Typography
              style={{
                width:'100%',
                // textAlign: reverse ? 'right' : 'left',
                textTransform:'uppercase',
                lineHeight:1.1,
                fontSize:'1.2rem',
                fontWeight:500,
                marginBottom:16,
                color:theme.palette.primary.main
              }}
            >
              {feature.short}
            </Typography>
            <Typography
              style={{
                width:'100%',
                // textAlign: reverse ? 'right' : 'left'
              }}
            >
              {feature.description}
            </Typography>
            <Grid
              style={{
                background:available.background,
                color: available.color,
                padding:'0 12px',
                borderRadius:16,
                marginTop:8
              }}
            >
              <Typography
                style={{
                  fontSize:'.85rem',
                  fontWeight:500,
                  textTransform:'uppercase',
                  color:available.color
                }}
              >
                {available.label}
              </Typography>
            </Grid>
          </Grid>
      </Grid>
    </Grid>
    </ScrollToGrow>
  )
}

const Prices = () => {
  return (
    <CenterColumn id="pricing">
      <Pricing />
    </CenterColumn>
  )
}

const CenterColumn = ({ children, id }) => {
  const screenSize = useScreenSize();
  const theme = useTheme();

  console.log('id', id);

  return (
    <Grid
      id={id}
      container
      style={{
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      <Grid
        container
        sx={{
          width:{xs:'92%', sm:500, md:800, xl:1000},
          padding:'48px 0',
          justifyContent:'center'
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}