import React, { useEffect, useState } from 'react'
import { useGetEmailTemplate, useGetEmailTemplateStorageFile, useGetStorageFile } from '../hooks/mutations'
import Loading from '../components/ui/Loading'
import MainPageTemplate from '../templates/MainPageTemplate'
import EmailBuilder from '../components/email_builder/EmailBuilder'
import { useParams } from 'react-router-dom'

const EmailBuilderPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { templateId } = useParams();
  const [emailTemplateId, setEmailTemplateId] = useState(currentEnv === "STAGING" ? (templateId ? templateId : 5) : null);
  const [reload, setReload] = useState(false);

  // Get the script element by its id
  const scriptElement = document.getElementById('django-edit-email-template');

  if (scriptElement && !emailTemplateId) {
    // Access the content of the script element
    const djangoContactString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoEmailTemplate = JSON.parse(djangoContactString);

    // Set the contact id state from the JSON data
    setEmailTemplateId(djangoEmailTemplate.id)
  }

  // This hook should make 3 api calls to get the template, templateStorageFile, and finally the file itself
  // It currently cannot get the file from the response because it needs to use 'no-cors' mode
  const emailTemplate = useGetEmailTemplateStorageFile(emailTemplateId);
  // console.log('emailTemplate', emailTemplate)
  
  // Reset 'reload' state
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])

  return (
    // <MainPageTemplate title="Contacts">
    emailTemplate
      ? <EmailBuilder emailTemplate={emailTemplate} setReload={setReload} />
      : <Loading />
    // </MainPageTemplate>
  )
}

export default EmailBuilderPage