import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  Typography,
  Collapse,
  Dialog,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, CheckmarkSVG, CloseRoundedSVG, DeleteSVG, DownTriangleSVG, EmailSVG, EventSVG, FileSVG, FilterSVG, GallerySVG, LinkSVG, SelectSVG, NumberSVG, PhoneSVG, RichTextSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG, WarningSVG, ListSVG, EditSVG, CloseFilledSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { deleteCustomField, putCustomField, useGetCustomFields } from '../../hooks/mutations';
import CreateCustomFields from './CreateCustomFields';
import { customFieldColumns } from '../../lib/Data';
import ImportCsvHeaders from '../import/ImportCsvHeaders';
import CustomFieldComparison from './CustomFieldComparison';
import Papa from 'papaparse';
import { translateFieldType } from '../../lib/Functions';
import { InputField } from '../ui/Fields';

const CustomFields = (props) => {
  const { importOnly } = props;
  const theme = useTheme();
  const [reload, setReload] = useState(true);
  const incomingCustomFields = useGetCustomFields(reload);
  const [newCustomFields, setNewCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState(incomingCustomFields);
  const [headers, setHeaders] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isImportingHeaders, setIsImportingHeaders] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [csvFile, setCsvFile] = useState(null);
  const existingNameArray = customFields ? customFields.map(x => x.field_name) : [];

  const screenSize = useScreenSize();

  // console.log('customFields', customFields)

  // Reset reload after it is switched to true
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])
  
  // Set state if importOnly is true. This is used as the first step in importing a csv
  useEffect(() => {
    if (importOnly) {
      setIsImportingHeaders(true);
    }
  }, [importOnly])
  
  // Set custom field state to incoming data on load
  useEffect(() => {
    if (incomingCustomFields && resetSearch) {
      setCustomFields(incomingCustomFields);
      setResetSearch(false);
    }
  }, [incomingCustomFields, resetSearch]);

  // Update customFields after incomingCustomFields is reloaded
  useEffect(() => {
    if (incomingCustomFields ) {
      setCustomFields(incomingCustomFields);
    }
  }, [incomingCustomFields]);

  useEffect(() => {
    if (newCustomFields.length) {
      setIsAddingField(true);
    }
  }, [newCustomFields])

  // Handle uploading of csv file
  useEffect(() => {
    if (csvFile) {
      handleUpload();
    }
  }, [csvFile]);

  const handleUpload = async () => {
    if (!csvFile) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvText = e.target.result;
      const { data } = Papa.parse(csvText, { header: false });
      const headerData = data[0];
      setHeaders(headerData);
    };

    reader.readAsText(csvFile.file);
  }

  const resetCsvFile = () => {
    setIsImportingHeaders(false);
    setCsvFile(null);
    setHeaders(null);
    setNewCustomFields([]);
  }


  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        style={{
          background:'#fff',
          borderRadius: 8,
          padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width: '100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          transition:'.4s',
          maxWidth:1600
        }}
      >
        <Grid
          container
          style={{
            justifyContent:'space-between',
            marginBottom:16
          }}
        >
          <Typography
            sx={{
              fontSize:'1.8rem',
              fontWeight:600,
              color:'#ccc',
            }}
          >
            Custom Fields
          </Typography>
          <Grid
            style={{
              display:'flex',
              gap:16
            }}
          >
            <ReusableSearch
              useSearch
              placeholder="Search Fields"
              content={incomingCustomFields}
              setContent={setCustomFields}
              searchFields={['field_name']}
            />
            {
              !isAddingField && !isImportingHeaders
                ?
                  <>
                    <ButtonCustom
                      onClick={() => setIsImportingHeaders(true)}
                      iconStart={<UploadSVG width={20} color1="#fff" />}
                      text={ screenSize.sm ? null : "Import"}
                    />
                    <ButtonCustom
                      onClick={() => setIsAddingField(true)}
                      iconStart={<AddSVG width={16} color1="#fff" />}
                      text={ screenSize.sm ? null : "New Field"}
                    />
                  </>
                :
                  null
            }
          </Grid>
        </Grid>
        <Collapse
          in={isAddingField}
          style={{
            width:'100%'
          }}
        >
          <CreateCustomFields
            setReload={setReload}
            setIsAddingField={setIsAddingField}
            incomingNewCustomFields={newCustomFields}
          />
        </Collapse>
        <Collapse
          in={isImportingHeaders}
          style={{
            width:'100%'
          }}
        >
          {
            !headers
              ?
                <ImportCsvHeaders
                  setHeaders={setHeaders}
                  csvFile={csvFile}
                  setCsvFile={setCsvFile}
                  resetCsvFile={resetCsvFile}
                />
              :
                <CustomFieldComparison
                  setIsImportingHeaders={setIsImportingHeaders}
                  customFields={[ {field_name: 'Name'}, {field_name: 'Email'}, ...customFields || [] ]}
                  headers={headers}
                  newCustomFields={newCustomFields}
                  setNewCustomFields={setNewCustomFields}
                  resetCsvFile={resetCsvFile}
                />
          }
        </Collapse>
        
        {
          importOnly
            ? null
            :
              <>
                <HeaderRow />
                <TableWrapper>
                  {
                    customFields
                      ?
                        customFields.map((customField, index) => (
                          <TableRow
                            key={customField.id}
                            customField={customField}
                            index={index}
                            setReload={setReload}
                            existingNameArray={existingNameArray}
                          />
                        ))
                      :
                        <Typography>No custom fields have been created yet.</Typography>
                  }
                </TableWrapper>
              </>
        }
      </Grid>

    </Grid>
  );
};

export default CustomFields;

export const selectedIcons = {
  text: {label: 'text', icon: <TextSVG width={20} />}, // <Typography style={{fontStyle:'italic', lineHeight:1, textTransform:'uppercase', fontWeight:800}}>T</Typography>,
  number: {label: 'number', icon: <NumberSVG width={30} />}, //<Typography style={{lineHeight:1, fontSize:'.75rem', fontWeight:900}}>123</Typography>,
  email: {label: 'email', icon: <EmailSVG width={20} />},
  date: {label: 'date', icon: <EventSVG width={20} />},
  enum: {label: 'select', icon: <SelectSVG width={17} />},
  list: {label: 'list', icon: <ListSVG width={17} />},
  bool: {label: 'checkbox', icon: <CheckmarkSVG width={20} />},
  image_file: {label: 'image', icon: <GallerySVG width={22} />},
  file: {label: 'file', icon: <FileSVG width={13} />},
  url: {label: 'link', icon: <LinkSVG width={22} />},
  large_text_field: {label: 'rich text', icon: <RichTextSVG width={28} />},
  phone_number: {label: 'phone', icon: <PhoneSVG width={13} />},
}

const HeaderRow = (props) => {
  const { align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16
      }}
    >
      {
        customFieldColumns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        maxHeight:600
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { customField, index, setReload, existingNameArray } = props;
  const [editField, setEditField] = useState(customField);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [notUnique, setNotUnique] = useState(false);
  const theme = useTheme();

  // console.log('editField', editField);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  }

  const deleteField = async () => {
    setDeleting(true);
    const deletedField = await deleteCustomField(customField.id);
    if (deletedField.status === 'success') {
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
    }
  }

  const saveField = async () => {
    try {
      setSaving(true);
  
      const customFieldObj = {
        customFieldId: editField.id,
        payload: editField
      }
      const savedField = await putCustomField (customFieldObj);
  
      if (savedField.status === 'success') {
        setOpenEdit(false);
        setSaved(true);
        setSaving(false);
        handleCloseEdit(true);
        setReload(true);
      }
    }

    catch (error) {
      console.log('error', error)
    }
  }
  
  const handleEditName = (value) => {
    const selectedFieldArray = existingNameArray.filter(x => x !== customField.field_name);
    const uniqueExistingCheck = selectedFieldArray.includes(value);

    if (uniqueExistingCheck) {
      setNotUnique(true);
    } else {
      setNotUnique(false);
    }

    setEditField({ ...editField, field_name: value })
  }

  const handleEditEnum = (value, enum_index) => {
    const newField = { ...customField };
    const enums = newField.enum_values;
    enums[enum_index] = value;
    setEditField({ ...editField, enum_values: enums})
  }

  const handleAddEnum = () => {
    const newField = { ...customField };
    const enums = [...newField.enum_values, ''];
    setEditField({ ...editField, enum_values: enums})

  }

  useEffect(() => {
    setTimeout(() => {
      setReload(true);
    }, 1000);
  }, [deleted])

  return (
    <Grid
      container
      style={{
        background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'12px 32px',
        border: deleted ? `2px solid ${theme.palette.warning.main}` : null,
        opacity: deleted ? 0 : 1,
        transition: 'opacity 1s'
      }}
    >
      {
        customFieldColumns.map(item => (
          <TableColumn item={item}>
            {
              item.id === 'data_type' &&
                <Grid
                  style={{
                    width:50,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    marginLeft:-10
                  }}
                >
                  {cloneElement(selectedIcons[customField.data_type].icon, {color1:'#777'})}
                </Grid>
            }
            {
              item.id === 'actions' &&
                <Grid
                  style={{
                    display:'flex',
                    alignItems:'center',
                    gap:8
                  }}
                >
                  {/* <VisibleSVG width={20} color1="#777" /> */}
                  <Tooltip
                    title="Delete Custom Field"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          background: theme.palette.primary.main,
                          px:2,
                          py:1
                        },
                        "& .MuiTooltip-arrow": {
                          color: theme.palette.primary.main,
                        }
                      }
                    }}
                  >
                    <IconButton
                      onClick={() => setOpenEdit(true)}
                    >
                      <EditSVG width={16} color1="#777" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Edit Custom Field"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          background: theme.palette.primary.main,
                          px:2,
                          py:1
                        },
                        "& .MuiTooltip-arrow": {
                          color: theme.palette.primary.main,
                        }
                      }
                    }}
                  >
                    <IconButton
                      onClick={() => setOpenConfirmDelete(true)}
                    >
                      <DeleteSVG width={13} color1="#777" />
                    </IconButton>
                  </Tooltip>
                </Grid>
            }
            <Typography style={{ fontSize:'1rem'}}>
              {
                item.id === 'data_type'
                  ? translateFieldType(customField[item.id])
                  : customField[item.id]
              }
            </Typography>
          </TableColumn>
        ))
      }

      <Dialog
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
      >
        <Grid
          container
          style={{
            padding:32,
            paddingTop:16,
            width:'100%',
            flexDirection:'column',
            alignItems:'center'
          }}
        >
          <Grid
            style={{
              display:'flex',
              gap:8,
              alignItems:'center',
              paddingBottom:16
            }}
          >
            <WarningSVG width={24} color1={theme.palette.warning.main} />
            <Typography
              style={{
                textTransform:'uppercase',
                fontWeight:700,
                color: theme.palette.warning.main
              }}
            >
              Delete Custom Field
            </Typography>
          </Grid>
          <Typography
            style={{
              fontSize:'1.3rem'
            }}
          >
            Are you sure you want to delete <span style={{fontWeight:700}}>{customField.field_name}</span>?
          </Typography>
          <Typography

          >
            This will remove the custom field from all contacts that currently use it. All information stored in this field will be permanently lost. <span style={{fontWeight:500, fontStyle:'italic'}}>This can not be undone</span>.
          </Typography>

          <Grid
            container
            style={{
              marginTop:16,
              justifyContent:'space-between'
            }}
          >
            <Button
              color="mid"
              variant="outlined"
              startIcon={<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />}
              onClick={handleCloseConfirmDelete}
            >
              Cancel
            </Button>
            <Button
              color="warning"
              variant="contained"
              startIcon={deleting ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : <DeleteSVG width={12} color1="#fff" />}
              onClick={deleteField}
            >
              {deleting ? 'Deleting' : 'Delete'}
            </Button>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
      >
        <Grid
          container
          style={{
            padding:32,
            paddingTop:16,
            width:'100%',
            // flexDirection:'column',
            // justifyContent:'center',
            alignItems:'center'
          }}
        >
          <Grid
            container
            style={{
              display:'flex',
              gap:8,
              justifyContent:'center',
              paddingBottom:16
            }}
          >
            <EditSVG width={18} color1={theme.palette.primary.main} />
            <Typography
              style={{
                textTransform:'uppercase',
                fontWeight:700,
                color: theme.palette.primary.main
              }}
            >
              Edit Custom Field
            </Typography>
          </Grid>
          {/* <Typography
            style={{
              fontSize:'1.3rem',
              fontWeight:700
            }}
          >
            {editField.field_name}
          </Typography> */}

          <InputField
            label='Field Name'
            placeholder='Field Name'
            value={editField.field_name}
            onChange={(e) => handleEditName(e.target.value)}
            margin={notUnique || !editField.field_name.length ? false : true}
          />
          <Grid
            container
            style={{
              marginBottom: notUnique || !editField.field_name.length ? 16 : 0

            }}
          >
            {
              notUnique
                ?
                  <>
                    <Typography
                      color="warning.main"
                      style={{
                        marginTop:8,
                      }}
                    >
                      That field already exists.
                    </Typography>
                  </>
                :
                  null
            }
            {
              !editField.field_name.length
                ?
                  <>
                    <Typography
                      color="warning.main"
                      style={{
                        marginTop:8
                      }}
                    >
                      Names cannot be blank.
                    </Typography>
                  </>
                :
                  null
            }

          </Grid>


          {
            editField.enum_values
              ?
                <>
                  <Typography
                    style={{
                      margin:'0 16px',
                      fontWeight:600,
                      color: theme.palette.primary.main
                    }}
                  >
                    Options
                  </Typography>

                  {
                    editField.enum_values.map((value, enum_index) => (
                      <InputField
                        // label='Field Name'
                        placeholder='Field Name'
                        value={value}
                        onChange={(e) => handleEditEnum(e.target.value, enum_index)}
                        marginAmount="4px 0"
                        customInputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              // onClick={removeOption}
                            >
                              <CloseFilledSVG width={20} color1={theme.palette.mid.main} />
                            </IconButton>
                          </InputAdornment>,
                        }}
                      />
                    ))
                  }
                  <Button
                    onClick={handleAddEnum}
                    // variant="outlined"
                    startIcon={<AddSVG width={10} color1={theme.palette.primary.main} />}
                    fullWidth
                  >
                    Add Option
                  </Button>
                </>
              :
                <InputField
                  label='Default Value'
                  placeholder='Default Value'
                  value={editField.field_default_value}
                  onChange={(e) => setEditField({ ...editField, field_default_value: e.target.value })}
                  margin
                />

          }

          <Grid
            container
            style={{
              marginTop:16,
              justifyContent:'space-between'
            }}
          >
            <Button
              color="mid"
              variant="outlined"
              startIcon={<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />}
              onClick={handleCloseEdit}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={notUnique}
              startIcon={saving ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : null } //<DeleteSVG width={12} color1="#fff" />}
              onClick={saveField}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </Dialog>

    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}
