import React, { cloneElement, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  TextField, MenuItem,
  Typography, ListItemIcon, IconButton
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, ArrowSVG, DownloadFileSVG, UploadSVG, VisibleSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { selectedIcons } from './CustomFields';
import { useGetCustomFields } from '../../hooks/mutations';
import { customFieldColumns } from '../../lib/Data';
import ReusableSearch from '../ui/ReusableSearch';
import { hashScroll, translateFieldType } from '../../lib/Functions';
import { ButtonCustom } from '../ui/Buttons';
import CustomTooltip from '../ui/CustomTooltip';

const ImportCustomFields = (props) => {
  const { setIsOpen, contactId, existingCustomFields, handleFieldImport, handleImportAllFields, showClose, setEditing, handleOpen } = props;
  const theme = useTheme();
  const [selectedCustomFields, setSelectedCustomFields] = useState({});
  const incomingCustomFields = useGetCustomFields();
  const [customFields, setCustomFields] = useState(incomingCustomFields);

  const screenSize = useScreenSize();

  // console.log('existingCustomFields', existingCustomFields)


  // Set customFields state to incoming data on load
  useEffect(() => {
    if (incomingCustomFields) {
      setCustomFields(incomingCustomFields);
    }
  }, [incomingCustomFields]);
  
  const cancelImportFields = () => {
    if (setIsOpen) setIsOpen(false);
    setSelectedCustomFields({});
  };

  const handleEditingClick = (fieldKey) => {
    if (setEditing) {
      setEditing(fieldKey);
    }

    if (handleOpen) {
      handleOpen(false);
    }

    setTimeout(() => {
      if (document.getElementById(fieldKey)) {
        document.getElementById(fieldKey).focus();
      }
      if (document.getElementById(`r-${fieldKey}`)) {
        console.log('style', document.getElementById(`r-${fieldKey}`));
        const element = document.getElementById(`r-${fieldKey}`);
        element.style.backgroundColor = `${theme.palette.primary.light}77`;
        element.style.outline = `1px solid ${theme.palette.primary.light}`;
        element.style.borderRadius = '4px';
        setTimeout(() => {
          element.style.backgroundColor = 'transparent';
          element.style.outline = `1px solid transparent`;
          element.style.borderRadius = 16;  
          element.style.transition = "1s"
        }, 1500);
      }
    }, 0);
  }


  return (
    <Grid
      container
      style={{
        // outline: `2px solid ${theme.palette.primary.main}`,
        // borderRadius: 8,
        // padding: 24,
        maxHeight:'50vh',
        overflowY:'auto'
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        {
          showClose &&
            <Button
              onClick={cancelImportFields}
            >
              Close
            </Button>
        }

        <ReusableSearch
          useSearch
          isOpen
          placeholder="Search Custom Fields"
          content={incomingCustomFields}
          setContent={setCustomFields}
          searchFields={['field_name']}
        />
        <ButtonCustom
          onClick={() => handleImportAllFields(customFields)}
          text="Add All"
          variant="outlined"
          startIcon={<ArrowSVG width={15} color1={theme.palette.primary.main} style={{transform:'rotate(90deg)'}} />}
        />
      </Grid>
      <HeaderRow
        columns={customFieldColumns}
      />
      <TableWrapper>
        {
          customFields
            ?
              customFields.map((customField, index) => (
                <TableRow
                  customField={customField}
                  existingCustomFields={existingCustomFields}
                  columns={customFieldColumns}
                  index={index}
                  handleFieldImport={handleFieldImport}
                  handleEditingClick={handleEditingClick}
                />
              ))
            :
              <Typography>No custom fields have been created yet.</Typography>
        }
      </TableWrapper>

      {/* <Grid
        container
        sx={{
          justifyContent: 'space-between',
          marginTop: '8px'
        }}
      >
        <Button
          onClick={cancelImportFields}
        >
          Close
        </Button>
        <Button

        >
          Import
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default ImportCustomFields


const HeaderRow = (props) => {
  const { columns, align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16
      }}
    >
      {
        columns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        maxHeight:240
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { customField, columns, index, handleFieldImport, existingCustomFields, handleEditingClick } = props;
  return (
    <Grid
      container
      style={{
        background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'12px 32px',
      }}
    >
      {
        columns.map(item => (
          <TableColumn item={item}>
            {
              item.id === 'data_type' &&
                <Grid
                  style={{
                    width:50,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    marginLeft:-10
                  }}
                >
                  {cloneElement(selectedIcons[customField.data_type].icon, {color1:'#777'})}
                </Grid>
            }
            {
              item.id === 'actions' ?
                existingCustomFields?.includes(String(customField.id))
                  ?
                    <CustomTooltip
                      title="Go to field"
                    >
                      <IconButton
                        onClick={() => {
                          handleEditingClick(customField.id);
                          hashScroll(`r-${customField.id}`);
                        }}
                        style={{
                          height:36
                        }}
                      >
                        <VisibleSVG width={20} color1="#777" style={{transform:'rotate(180deg)', cursor:'pointer'}} />
                      </IconButton>
                    </CustomTooltip>
                  :
                    <CustomTooltip
                      title="Import field"
                    >
                      <IconButton
                        onClick={() => handleFieldImport(customField)}
                        style={{
                          height:36
                        }}
                      >
                        <UploadSVG width={20} color1="#777" style={{transform:'rotate(180deg)', cursor:'pointer'}} />
                      </IconButton>
                    </CustomTooltip>
                :
                  null
            }
            <Typography style={{ fontSize:'1rem'}}>
              {
                item.id === 'data_type'
                  ? translateFieldType(customField[item.id])
                  : customField[item.id]
              }
            </Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}
