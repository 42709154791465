import React from 'react'
import CustomFields from '../components/custom_fields/CustomFields'
import MainPageTemplate from '../templates/MainPageTemplate'

const CustomFieldsPage = () => {
  return (
    <MainPageTemplate title="Welcome">
      <CustomFields />
    </MainPageTemplate>
  )
}

export default CustomFieldsPage