import React, { useState } from 'react';
import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
import axios from 'axios';
import { useAuth } from '../../context/authContext';
//import { useNavigate } from 'react-router-dom';


import { api_endpoint, auth_request_headers } from '../../lib/Backend';



const FinalCheckoutForm = (props) => {
  const { subscriptionDetails } = props;
  const [priceModel, setPriceModel] = useState('monthly');
  const [price, setPrice] = useState(subscriptionDetails?.priceMon);

  const authContext = useAuth();
  //const navigate = useNavigate();


  const handlePriceModel = (model) => {
    setPriceModel(model);
    if (model === 'annual') {
      setPrice(subscriptionDetails.priceAnn);
    } else {
      setPrice(subscriptionDetails.priceMon);
    }
  };

  const handleCheckout = async () => {
    // try {

      //***DISABLED AUTH FOR DEVELOPMENT PURPOSES***//
      //var headers = await auth_request_headers();
      // const response = await axios.post(api_endpoint() + `/api/payment/checkout/${priceModel === 'monthly' ? subscriptionDetails.priceMonId : subscriptionDetails.priceAnnId}`, 
      //     {1: 1 }, {headers},);

      //***HARD-CODED HEADER WITH TOKEN FROM SWAGGER FOR DEV PURPOSES***//
      const response = await axios.post(api_endpoint() + `/api/payment/checkout/${priceModel === 'monthly' ? subscriptionDetails.priceMonId : subscriptionDetails.priceAnnId}/`, 
      {1: 1 }, 
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer xYka4TbvVv8jeDP9a394d0ifXWmhBZQjHIEeKh9ibIZMoHye3LjemfeXxN2Pudx2'
        },);

      if (response.status == 201) {
          window.location.href = response.data.url
      } else {
          console.log("Error checking out" + response);
      }
    // }

    // catch (error) {
    //   console.log('Error getting url', error);
    // }
  };


  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        // padding: '16px 0'
      }}
    >
      <Grid
        ts={12}
        // md={8}
      >
        <Card
          style={{
            // width: '100%',
            borderRadius: 16,
            paddingTop: 16
          }}
        >
          <Grid
            variant="space-between"
          >
            <Grid
              variant="center"
            >
              <LogoHorizontal
                width={120} style={{marginBottom:8}} />
              {/* <Typography
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 900,
                  fontSize: 18,
                  color: '#635BFF'
                }}
              >
                Checkout
              </Typography> */}
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'flex-end'
              }}
            >
              <Typography
                style={{
                  lineHeight: 1,
                  fontWeight: 600,
                  fontSize: 24
                }}
              >
                ${price}
              </Typography>
              <Typography
                style={{
                  lineHeight: 1.1,
                  fontWeight: 500,
                  fontSize: 18
                }}
              >
                /mo
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', mb: 2 }} />

          {/* PRICE MODEL */}
          <CheckoutRow
            className="checkout-users-row"
            description={
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'flex-end'
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    fontWeight: 600,
                    fontSize: 36
                  }}
                >
                  ${price.toLocaleString()}
                </Typography>
                <Typography
                  style={{
                    lineHeight: 1.3,
                    fontWeight: 500,
                    fontSize: 22
                  }}
                >
                  /mo
                </Typography>
              </Grid>
            }
            dataType="switch"
            value={priceModel}
            onChange={handlePriceModel}
          />

          {/* USERS ROW */}
          <CheckoutRow
            className="checkout-users-row"
            description={subscriptionDetails.checkout}
          />

          <Button
            onClick={handleCheckout}
            variant="contained"
            fullWidth
          >
              <Typography
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 900,
                  fontSize: 18,
                  color: '#FFFFFF'
                }}
              >
                Checkout
              </Typography>
          </Button>

          <Typography
            style={{ fontSize:14, fontWeight:500, marginTop:16, width:'100%', textAlign:'center' }}
          >
            *Sales tax calculated at checkout.
          </Typography>

        </Card>
      </Grid>
    </Grid>
  );
};

export default FinalCheckoutForm

const CheckoutRow = (props) => {
  const { className, description, placeholder, dataType, disabled, value, onChange, post } = props;
  const theme = useTheme();

  return (
    <Grid
      className={className ? className : 'checkout-row'}
      variant="center"
      container
      columnGap={2}
      mb={2}
    >
      <Grid
        variant="center"
        ts={12}
        // lg="auto"
      >
        {dataType === 'number' &&
          <>
            <TextField
              fullWidth
              type="number"
              disabled={disabled ? true : false}
              placeholder={placeholder ? placeholder : null}
              value={value}
              onChange={onChange}
              sx={{
                margin: 0,
                marginRight: 1,
                width: 110
              }}
              inputProps={{
                sx: {
                  padding: 0,
                  paddingLeft: 2,
                  textAlign:'center',
                }
              }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  padding: 0,
                  margin: 0,
                  fontSize: 36,
                  '& .muiInputBase': {
                    // padding:'0 0 0 24px'
                  }
                }
              }} />
            <Typography>
              {post}
            </Typography>
          </>}
        {dataType === 'switch' &&
          <Grid
            variant="center"
            style={{
              outline: `1px solid ${theme.palette.primary.main}`,
              borderRadius: 32,
              background: `${theme.palette.background.main}cc`,
              padding: 4,
              margin: '16px 0'
            }}
          >
            <Grid
              onClick={() => onChange('monthly')}
              style={{
                padding: '4px 16px',
                background: value === 'monthly' ? theme.palette.primary.main : 'transparent',
                transition: '.4s',
                borderRadius: 32,
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: value === 'monthly' ? '#ffffff' : '#777777',
                }}
              >
                Monthly
              </Typography>
            </Grid>
            <Grid
              onClick={() => onChange('annual')}
              style={{
                padding: '4px 16px',
                background: value === 'annual' ? theme.palette.primary.dark : 'transparent',
                transition: '.4s',
                borderRadius: 32,
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: value === 'annual' ? '#ffffff' : '#777777',
                }}
              >
                Annual
              </Typography>
            </Grid>
          </Grid>}
      </Grid>

      <Grid
        variant="center"
        ts={12}
        // lg
      >
        <Typography
          textAlign={{ xs: 'center', sm: 'left' }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};