import React, { useState } from 'react'
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({setForgot}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const theme = useTheme();
  const auth = getAuth();
  const authContext = useAuth();
  const navigate = useNavigate();

  // EMAIL LOGIN
  const login = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        authContext.setUser(user);
        navigate('/dashboard');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  // GOOGLE REGISTRATION/SIGN IN
  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        authContext.setUser(user);
        navigate('/dashboard');
        
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  
  return (
    <Card
      sx={{

      }}
    >
      <Grid
        variant="center"
        sx={{
          flexDirection:'column'
        }}
      >
        <LogoHorizontal
          width={65
          }
        />
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontSize:'1.4rem',
            fontWeight: 600
          }}
        >
          LOGIN
        </Typography>
      </Grid>
      <Grid
        variant="center"
        flexDirection="column"
      >

        <TextField
          label="Email"
          autoFocus
          style={{
            maxWidth:400
          }}
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          style={{
            maxWidth:400,
            marginTop:8
          }}
        // InputLabelProps={{ shrink: true }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              login()
            }
          }}
        />
      </Grid>

      <Grid
        variant="center"
        flexDirection="column"
      >
        <Button
          variant="contained"
          onClick={login}
          style={{
            height:65,
            width:292
          }}
        >
          {
            // registerFirstName && registerLastName && registerPassword && registerPassword === confirmPassword 
            //   ? 'Register'
            //   : 'Fill out all fields'
          }
          {/* <EmailSVG
            width={30}
            color1="#ffffff"
            style={{
              marginRight:24
            }}
          /> */}
          Login
        </Button>

        {/* <Grid
          variant="center"
        >
          <Grid
            variant="center"
            style={{
              marginTop:16
            }}
          >

            <Button
              onClick={handleSignInWithGoogle}
              variant="outlined-thin"
              style={{
                height:65,
                width:292,
                borderRadius:8
              }}
            >
              <GoogleG
                width={30}
                // color1="#ffffff90"
                // color2="#ffffffbb"
                // color3="#ffffffdd"
                // color4="#ffffff"
              />
              Sign in with Google
            </Button>
          </Grid>
        </Grid> */}

        <Button style={{marginTop:24}} onClick={() => setForgot(true)}>
          I forgot my password
        </Button>
      </Grid>

    </Card>
  )
}

export default LoginForm