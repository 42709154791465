import React, { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import newsletterPlugin from 'grapesjs-preset-newsletter';
import { getEmailTemplate, patchEmailTemplate, putEmailTemplate, putStorageFile, updateEmailTemplate, useGetEmailTemplate } from '../../hooks/mutations';
import './grapes.min.css'; // Import GrapesJS CSS file
import { useLocation } from 'react-router';
import { Snackbar } from '@mui/material';


// Load plugins

const EmailBuilder = (props) => {
  const { emailTemplate, setReload } = props;
  // const location = useLocation();
  // const { projectId } = location.state;
  // const emailTemplate = useGetEmailTemplate(projectId);
  console.log('email template', emailTemplate)

  return (
    emailTemplate
      ? <GrapeJSComponent emailTemplate={emailTemplate.data} template={emailTemplate.template} />
      : "LOADING>>>"
  )
}

export default EmailBuilder

const GrapeJSComponent = ({ emailTemplate, template }) => {
  const [openSnack, setOpenSnack] = useState(false)
  console.log('emeail teplame', emailTemplate)
  const remoteStoragePlugin = (editor) => {
    // console.log('emailTemplate from here', emailTemplate);
    editor.Storage.add('remote', {

      async load() {

        const htmlString = ``;

        try {

          // const emailTemplate = await getEmailTemplate(emailTemplate.id);
          // if (emailTemplate.status === "success") {
          //   console.log('emailTemplate data', emailTemplate.data);
          //   console.log('emailTemplate from deep inside', emailTemplate)

          //   return emailTemplate.editor_json
          // }
          if (emailTemplate) {
            return emailTemplate
          }
        } catch (error) {
          console.log('There was an error making a GET request to the /templates/api/email_templates/${projectId}/ api endpoint')
        }

        editor.setComponents(htmlString);
      },

      async store(data) {
        try {
          // // ADD THIS BACK IN TO HANDLE TEMPLATE NAMING
          // console.log('trying', data)
          // const emailTemplateObj = {
          //   emailTemplateId: emailTemplate.id,
          //   payload: {
          //     ...emailTemplate.template,
          //     'name': emailTemplate.name,
          //     'update_date': new Date(),
          //     'editor_json': data,
          //   }
          // }

          // const savedTemplate = await putEmailTemplate(emailTemplateObj);
          // console.log('savedTemplate', savedTemplate)

          console.log('data', data);
          const jsonBlob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
          const fileName = 'template.json';
          const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
          const formData = new FormData();
          formData.append('file', jsonFileObj);
          formData.append('name', 'template'); // <-- Replace this string with state variable to save and edit the name
          formData.append('folder', 1);

          setOpenSnack(true);
          const storageObj = {
            storageFilePayload: formData,
            folderId: 1,
            fileId: template.content_file
          }

          const updatedFile = await putStorageFile(storageObj);
          if (updatedFile.status === "success") {
            console.log('succes', updatedFile.data);
          }

        } catch (error) {
          console.log(`Error updating template ${emailTemplate.id}`, error)
        }
      }

    });

  }

  useEffect(() => {
      // Initialize GrapesJS editor
      if (emailTemplate) {

        const editor = grapesjs.init({
            container: '#gjs', //editorRef.current,
            // Your GrapesJS configuration options
            projectData: emailTemplate.data,
            height: 'calc(100vh - 100px)', // Set editor height
            plugins:[
              newsletterPlugin,
              remoteStoragePlugin
            ],
            storageManager: {
              type: 'remote', // Store changes remotely
              stepsBeforeSave: 1,
              options: {
                remote: {                
                  //Enrich remote call to include HTML/CSS of pages along with IDs and data
                  onStore: (data, editor) => {
                    const htmlWithCss = editor.runCommand('gjs-get-inlined-html');
                    return { data, content: htmlWithCss, existing: true };
                  },
                  onLoad: result => result.data,
                  contentTypeJson: true, 
                }                 
              } 
            },
              });
  
        // // Example of adding a custom block
        // editor.BlockManager.add('my-custom-block', {
        //     label: 'My Custom Block',
        //     // Add your block definition here
        //     content: '<div>My Custom Block Content</div>',
        // });
  
        // Cleanup function
        return () => {
            // Destroy the GrapesJS editor instance when the component unmounts
            editor.destroy();
        };
      }
  }, [emailTemplate]); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div id='gjs'>
      {/* GrapesJS editor container */}
      <Snackbar
        autoHideDuration={2000}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        message="Template Saved"
        // key={vertical + horizontal}
      />
    </div>
  );
};

