import { useTheme } from '@emotion/react';
import { Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useScreenSize } from '../../lib/Interface';
import { AddFillSVG, CheckSVG, RemoveFillSVG } from '../../assets/icons';
import { getRandomInts } from '../../lib/Functions';

const Step2CustomFieldComparison = (props) => {
  const { trigger, customFields, headers, newCustomFields, setNewCustomFields, setIsConnectingFields, resetCsvFile } = props;
  const newFields = headers.filter(item => !customFields.some(field => field.field_name.toLowerCase().trim() === item.toLowerCase().trim()));
  const overlapFields = headers.filter(item => customFields.some(field => field.field_name.toLowerCase().trim() === item.toLowerCase().trim()));
  const [fieldsToImport, setFieldsToImport] = useState(newCustomFields.length ? newCustomFields.map(x => x.field_name) : newFields);
  const [showComparison, setShowComparison] = useState(true);
  const [showExisting, setShowExisting] = useState(true);
  const screenSize = useScreenSize();

  // console.log('headers', headers);
  // console.log('customFields', customFields);
  

  const addToList = (field) => {
    setFieldsToImport(prev => [...prev, field]);
  }

  const removeFromList = (field) => {
    setFieldsToImport(prev => prev.filter(x => x !== field));
  }
  
  useEffect(() => {
    if (trigger) {
      addNewCustomFields();
    }
  }, [trigger])

  const addNewCustomFields = () => {
    const fieldObjects = fieldsToImport.map( field => {
      const headerIndex = headers.indexOf(field);
      return {
        "id": getRandomInts(16), // Remove before api call
        "headerIndex": headerIndex, // Remove before api call
        "data_type": "text",
        "field_name": field,
      }
    }) 
    if (setIsConnectingFields && !fieldsToImport.length) {
      setNewCustomFields([])
      setIsConnectingFields(true);
    } else {
      setNewCustomFields(fieldObjects);
      setIsConnectingFields(false);
    }
  }

  const cancelHeaderImport = () => {
    resetCsvFile();
  }

  return (
    showComparison && !screenSize.sm
      ?
        <Grid
          container
        >
          {/* <ComparisonHeader
            addNewCustomFields={addNewCustomFields}
            fieldsToImport={fieldsToImport}
            resetCsvFile={resetCsvFile}
            cancelHeaderImport={cancelHeaderImport}
          /> */}
          <Grid
            item
            style={{
              padding:16,
            }}
            xs={12}
            sm={6}
          >
            <Grid
              style={{
                padding:16,
                borderRadius:16,
                boxShadow:'0 0 10px #00000030',
                height:'100%'
              }}
            >
              <Typography
                style={{
                  fontWeight:500,
                  fontSize:'1.2rem',
                  padding:'0 16px'
                }}
              >
                Existing Fields
              </Typography>
              
              {
                customFields?.map((field, index) => (
                  <FieldItem field={field.field_name} overlapFields={overlapFields} />
                ))
              }
            </Grid>
          </Grid>

          <Grid
            item
            style={{
              padding:16
            }}
            xs={12}
            sm={6}
          >
            <Grid
              style={{
                padding:16,
                borderRadius:16,
                boxShadow:'0 0 10px #00000030',
                height:'100%'
              }}
            >
              <Grid
                container
                style={{
                  justifyContent:'space-between'
                }}
              >
                <Typography
                  style={{
                    fontWeight:500,
                    fontSize:'1.2rem',
                    padding:'0 16px'
                  }}
                >
                  New From CSV
                </Typography>
                <Button
                  onClick={() => setShowExisting(prev => !prev)}
                  style={{
                    padding:'0 16px'
                  }}
                >
                  {showExisting ? `Hide Existing` : `Show Existing`}
                </Button>
              </Grid>
              
              {
                (showExisting ? headers : newFields)?.map((header, index) => (
                  <FieldItem
                    field={header}
                    newFields={newFields}
                    fieldsToImport={fieldsToImport}
                    overlapFields={overlapFields}
                    addToList={addToList}
                    removeFromList={removeFromList}
                  />
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      :
        <Grid
          container
        >
          {/* <ComparisonHeader
            addNewCustomFields={addNewCustomFields}
            fieldsToImport={fieldsToImport}
            resetCsvFile={resetCsvFile}
            cancelHeaderImport={cancelHeaderImport}
          /> */}
          <Grid
            item
            style={{
              padding:16
            }}
            xs={12}
          >
            <Grid
              style={{
                padding:16,
                borderRadius:16,
                boxShadow:'0 0 10px #00000030',
                height:'100%'
              }}
            >
              <Grid
                container
                style={{
                  justifyContent:'space-between'
                }}
              >
                <Typography
                  style={{
                    fontWeight:500,
                    fontSize:'1.2rem',
                    padding:'0 16px'
                  }}
                >
                  New From CSV
                </Typography>
              </Grid>

              {
                newFields?.map((header, index) => (
                  <FieldItem
                    field={header}
                    newFields={newFields}
                    fieldsToImport={fieldsToImport}
                    overlapFields={overlapFields}
                    addToList={addToList}
                    removeFromList={removeFromList}
                  />
                ))
              }
            </Grid>

          </Grid>
        </Grid>
  )
}

export default Step2CustomFieldComparison

const ComparisonHeader = (props) => {
  const { addNewCustomFields, fieldsToImport, resetCsvFile, cancelHeaderImport } = props;
  return (
    <Grid
      container
      style={{
        justifyContent:'space-between',
        alignItems:'center'
      }}
    >
      <Typography>Create Fields From CSV File</Typography>
      {
        fieldsToImport?.length
          ?
            <Button
              onClick={addNewCustomFields}
              variant="contained"
            >
              Create Fields
            </Button>
          :
            resetCsvFile
              ?
                <Button
                  onClick={cancelHeaderImport}
                  variant="contained"
                >
                  Cancel
                </Button>
              :
                <Typography

                >
                  No new fields to import...
                </Typography>
      }
    </Grid>
  )
}

const FieldItem = (props) => {
  const { field, newFields, removeFromList, addToList, fieldsToImport, overlapFields } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        background: fieldsToImport?.includes(field) ? `${theme.palette.primary.main}30` : 'transparent',
        outline: fieldsToImport?.includes(field) ? `1px solid ${theme.palette.primary.main}` : ( newFields?.includes(field) ? `1px solid #AAA` : '1px solid #ccc'),
        borderRadius:40,
        margin:'8px 0',
        padding: '8px 16px',
        justifyContent:'space-between',
        alignItems:'center'
      }}
    >
      <Grid
        style={{
          display:'flex',
          alignItems:'center'
        }}
      >
        {
          overlapFields?.includes(field)
            ?
              <Grid
                style={{
                  width:20,
                  height:20,
                  borderRadius:20,
                  marginRight:8,
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  background:theme.palette.primary.main
                }}
              >
                <CheckSVG width={12} color1="#fff" />
              </Grid>
            :
              null

        }
        <Typography>
          {field}
        </Typography>
      </Grid>
      {
        newFields?.includes(field)
          ?
            fieldsToImport?.includes(field)
              ?
                <Grid
                  onClick={() => removeFromList(field)}
                  style={{
                    display:'flex',
                    alignItems:'center',
                    cursor:'pointer'
                  }}
                >
                  <Typography
                    style={{
                      fontSize:'.8rem',
                      fontWeight:600,
                      color:theme.palette.primary.main
                    }}
                  >
                    NEW
                  </Typography>
                  <IconButton
                    style={{
                      padding:2,
                      marginLeft:6
                    }}
                  >
                    <RemoveFillSVG width={20} />
                  </IconButton>
                </Grid>
              :
                <Grid
                  onClick={() => addToList(field)}
                  style={{
                    display:'flex',
                    alignItems:'center',
                    cursor:'pointer'
                  }}
                >
                  <Typography
                    style={{
                      fontSize:'.8rem',
                      fontWeight:600,
                      // color:theme.palette.secondary.dark
                    }}
                  >
                    CREATE
                  </Typography>
                  <IconButton
                    style={{
                      padding:2,
                      marginLeft:6
                    }}
                  >
                    <AddFillSVG
                      width={20}
                      color1="#AAA" //{theme.palette.secondary.dark}
                    />
                  </IconButton>
                </Grid>
          : 
            overlapFields?.includes(field)
              ?
                <Typography
                  style={{
                    fontSize:'.8rem',
                    fontWeight:600,
                    color: '#AAA'
                  }}
                >
                  EXISTING FIELD
                </Typography>
              :
                null

        }
    </Grid>
  )
}