import React, { } from 'react';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { CloseRoundedSVG, DeleteSVG, WarningSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';

const DialogWarning = (props) => {
  const { open, onClose, header, title, messages, deleting, icons, cancel, confirm } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
        width:'100%',
      }}
      PaperProps={{
        style:{
          overflow:'visible',
          borderRadius:16
        }
      }}
    >
      <Grid
        container
        style={{
          position:'relative',
          padding:32,
          // paddingTop:16,
          width:'100%',
          flexDirection:'column',
          alignItems:'center'
        }}
      >
        <Grid
          style={{
            position:'absolute',
            top:-25,
            display:'flex',
            gap:8,
            alignItems:'center',
            background:'#fff',
            padding:'4px 16px 20px 16px',
            borderRadius:8
          }}
        >
          <WarningSVG width={16} color1={theme.palette.warning.main} />
          <Typography
            style={{
              textTransform:'uppercase',
              fontSize:'.9rem',
              fontWeight:700,
              color: theme.palette.warning.main
            }}
          >
            {header}
          </Typography>
        </Grid>
        <Typography
          style={{
            fontSize:'1.3rem',
            marginBottom:8
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            marginBottom:8
          }}
        >
          {messages.one}
        </Typography>
        <Typography

        >
          {messages.two}
        </Typography>

        <Grid
          container
          style={{
            marginTop:24,
            justifyContent:'space-around'
          }}
        >
          <Button
            color="warning"
            variant="outlined"
            startIcon={icons ? (icons.cancel ? icons.cancel : <CloseRoundedSVG width={12} color1={theme.palette.mid.main} />) : null}
            onClick={cancel ? cancel : onClose}
            style={{
              minWidth:120
            }}
          >
            {messages.cancel ? messages.cancel : 'Nevermind'}
          </Button>
          <Button
            color="warning"
            variant="contained"
            startIcon={icons ? (deleting ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : icons.confirm ? icons.confirm : <DeleteSVG width={12} color1="#fff" />) : null}
            onClick={confirm}
            style={{
              minWidth:120
            }}
          >
            {deleting ? (messages.inAction ? messages.inAction : 'Deleting') : (messages.confirm ? messages.confirm : 'Delete')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DialogWarning