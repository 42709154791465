import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { createUploadCsv } from '../../hooks/mutations';
import { useNavigate } from 'react-router-dom';

const Step4ConfirmCsvUpload = (props) => {
  const { csvFile } = props;
  const navigate = useNavigate();


  return (
    csvFile &&
    <Grid
      container
      style={{
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      <Typography style={{fontSize:'1.3rem', marginBottom:8}}>Congratulations!</Typography>
      <Typography>You are ready to map your fields from your csv to your existing custom fields. Click continue to finish the final step to import your contacts from {csvFile.file.name}!</Typography>
    </Grid>
  )
}

export default Step4ConfirmCsvUpload