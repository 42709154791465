import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';

const CompletePage = (props) => {
  const { submitForm } = props;

  useEffect(() => {
    // submitForm(true);
  }, []);

  return (
    <Grid

    >
      <Typography>
        That's it! Good job.
      </Typography>
    </Grid>
  );
};

export default CompletePage;