import React, { useEffect, useState } from 'react'
import { getContact, useGetContacts, useGetCustomFields } from '../../hooks/mutations'
import { Button, Drawer, Grid, Typography } from '@mui/material';
import ContactsDisplayGrid from './ContactsDisplayGrid';
import ContactUpdate from './ContactUpdate';
import { initHeaderColumns } from '../../lib/Data';
import { transformFields } from '../../lib/Functions';
import { useScreenSize } from '../../lib/Interface';
import ContactUpdateWrapper from './ContactUpdateWrapper';
import Paginate from '../ui/Paginate';
import { contactMockData } from '../../lib/mockData';
import ContactEdit from '../contacts/ContactEdit';

const CRM = () => {
  const [reloadContacts, setReloadContacts] = useState(false);
  const [reloadCustomFields, setReloadCustomFields] = useState();
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [filterOn, setFilterOn] = useState(false);
  const [focusField, setFocusField] = useState(null);
  const [headerColumns, setHeaderColumns] = useState(initHeaderColumns);
  const [headersExist, setHeadersExist] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [pages, setPages] = useState({id: 1, count:null, next:null, prev:null});
  const [destinationPageId, setDestinationPageId] = useState(1);
  const receivedContacts = useGetContacts({reload: reloadContacts, pageId: destinationPageId}); //contactMockData; // Use to test when contacts api isn't available
  const customFields = useGetCustomFields(reloadCustomFields);
  const screenSize = useScreenSize();

  // console.log('customFields', customFields);

  const handleDrawerClose = () => {
    setOpen(false);
  }

  // Update currently selected contact if contacts state changes
  const updateCurrentContact = () => {
    const newContacts = [ ...contacts ];
    const currentItem = newContacts.filter(x => x.id === selectedContact.id)[0];
    setSelectedContact(currentItem);
  }

  // Update contacts when a contact is updated without having to make another api call
  const updateContacts  = (contact) => {
    const newContacts = [ ...contacts ];
    const updatedContacts = newContacts.map(item => (item.id === contact.id ? contact : item));
    setContacts(updatedContacts);
  }

  // Get a single contact and replace it in the contacts state
  const reloadContact = async (contactId) => {
    const updatedContact = await getContact(contactId);
    if (updatedContact.status === "success") {
      updateContacts(updatedContact.data);
    } 
  } 

  // Set initial contacts state and pages state from receivedContacts get contacts api response
  useEffect(() => {
    if (receivedContacts) {
      setContacts(receivedContacts.results);
      const next = receivedContacts.next?.split('?page=')[1] || null;
      // console.log('next', next);
      const prev = receivedContacts.previous?.split('?page=')[1] || null;
      // console.log('prev', prev);
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        id: id,
        count: receivedContacts.count,
        next: next,
        prev: prev
      })
    }
  }, [receivedContacts])

  // Reset reload contacts state
  useEffect(() => {
    if (reloadContacts) {
      setReloadContacts(false);
    }
  }, [reloadContacts]);

  // Reset reload custom fields state
  useEffect(() => {
    if (reloadCustomFields) {
      setReloadCustomFields(false);
    }
  }, [reloadCustomFields]);

  const nextPage = () => {
    setDestinationPageId(pages.next);    
  }

  const prevPage = () => {
    setDestinationPageId(pages.prev);    
  }

  const directPage = (pageNumber) => {
    setDestinationPageId(pageNumber);    
  }

  const handleFilterClick = () => {
    if (filterOn) {
      setActionType(null);
    } else {
      setActionType("Filter List");
    }
    setFilterOn(prev => !prev);
  }

  useEffect(() => {
    if (contacts) {
      if (!headersExist) {
        generateHeaderColumns();
      }
      
      if (selectedContact) {
        updateCurrentContact();
      }
    }
  }, [contacts])

  // Create header column list based on incoming contact fields
  const generateHeaderColumns = () => {
    const headers = transformFields(contacts, customFields);
    setHeaderColumns(headers);
    setHeadersExist(true);
  } 


  return (
    <Grid
      style={{
        // padding:screenSize.xs ? 12 : (screenSize.sm ? 24 : 48)
      }}
    >
      <ContactsDisplayGrid
        contacts={contacts}
        setSelectedContact={setSelectedContact}
        setActionType={setActionType}
        headerColumns={headerColumns}
        open={open}
        setOpen={setOpen}
        setFocusField={setFocusField}
        handleFilterClick={handleFilterClick}
        filterOn={filterOn}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />
      <Paginate
        currentPage={destinationPageId}
        count={pages.count}
        prevClick={prevPage}
        nextClick={nextPage}
        directPage={directPage}
      />
      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        disableEscapeKeyDown
        PaperProps={{
          id:'drawer-contents',
          style:{
            width:'90%'
          }
        }}
      >
        <Grid
          style={{
            // width:'90%'
          }}
        >
          <ContactEdit
            contacts={contacts}
            setSelectedContact={setSelectedContact}
            customFields={customFields}
            setReloadCustomFields={setReloadCustomFields}
            incomingContact={selectedContact}
            updateContacts={updateContacts}
            reloadContact={reloadContact}
          />
          {/* <ContactUpdateWrapper contact={selectedContact} contacts={contacts} setSelectedContact={setSelectedContact} updateContacts={updateContacts} reloadContact={reloadContact} focusField={focusField} customFields={customFields} /> */}
          {/* <ContactUpdate contact={selectedContact} contacts={contacts} setSelectedContact={setSelectedContact} focusField={focusField} customFields={customFields} headerColumns={headerColumns} setReloadContacts={setReloadContacts} /> */}
        </Grid>
      </Drawer>
    </Grid>
  )
}

export default CRM