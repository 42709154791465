import { createContext, useContext, useState } from "react";

const tempContext = createContext();

export const useTemp = () => {
  return useContext(tempContext);
}

export const TempProvider = ({children}) => {
  // TEMP STATE
  const [tempNodes, setTempNodes] = useState(null);
  const [tempEdges, setTempEdges] = useState(null);
  const [tempSurvey, setTempSurvey] = useState(null);

  return (
    <>
      <tempContext.Provider
        value={{
          tempNodes,
          setTempNodes,
          tempEdges,
          setTempEdges,
          tempSurvey,
          setTempSurvey
        }}
      >
        {children}
      </tempContext.Provider>
    </>
  )
}