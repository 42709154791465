import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export const ScrollToGrow = (props) => {
  const { children, rootMargin, noRepeat } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [entered, setEntered] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: rootMargin ? rootMargin : "100px", threshold: 1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        transform: isVisible || noRepeat && entered ? 'scale(1)' : 'scale(0)',
        opacity: isVisible || noRepeat && entered ? 1 : 0,
        transition: "opacity .75s ease-in, transform .75s ease-in",
      }}
    >
      {children}
    </div>
  );
}
