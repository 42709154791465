import { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import { addDoc, collection, deleteDoc, doc, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { api_endpoint, auth_request_headers } from "../lib/Backend";
import axios from "axios";
import { mockData } from "../lib/mockData";


// POST === createXXX
// PATCH === updateXXX
// DELETE === deleteXXX
// GET === getXXX


// ==========================================================================================================================
// ==========================================================================================================================
//      AUTH
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for registering a user
export const postRegister = async (registerObj) => {
  const { payload } = registerObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/accounts/api/register/', {...payload},
      headers);
      console.log(response.data);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error registering user', error);
    return {status:'error', data: error};
  }
}

// Function for registering a user
export const postResendEmail = async (resendObj) => {
  const { payload } = resendObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/accounts/api/resend-email/', {...payload},
      headers);
      console.log(response.data);
      return {status:'success', data: response.data};
    } catch (error) {
      console.log('Error sending email', error);
      return {status:'error', data: error};
    }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting account
// reload state allows updating by making another api resquest
export const useGetAccount = () => {
  const [account, setAccount] = useState(); // State to set and return
  const getAccount = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/accounts/api/detail/`, headers);
      setAccount(response.data);

    } catch (error) {
      console.log('Error getting account', error);
    }
  }

  useEffect(() => {
    getAccount();
  }, [])

  return account ? account : null

}


// Function for getting account
export const getAccount = async () => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/accounts/api/detail/`, headers);
    return {status: 'success', data: response.data};
    
  } catch (error) {
    console.log('Error getting account', error);
    return {status: 'error'};
  }
}





























// ==========================================================================================================================
// ==========================================================================================================================
//      CONTACTS AND CONTACT UPLOAD API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const createContact = async (contactObj) => {
  const { payload } = contactObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/contacts/api/contacts/', {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating contact', error);
  }
}

// POST CSV FILE
export const createUploadCsv = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
    };

    // const response = await axios.post(
    //   api_endpoint() + `/contacts/api/import/csv/`,
    //   formData,
    //   {headers}
    // );

    const response = await axios.request({
      method: 'POST',
      url: api_endpoint() + `/contacts/api/import/csv/`,
      data: formData,
      headers: headers,
      maxRedirects: '0'
    });
    // const url = api_endpoint() + `/contacts/api/import/csv/`;

    // const response = await fetch(url, {
    //   method: 'POST',
    //   // headers: headers,
    //   body: formData,
    //   // redirect: 'manual'
    // })

    console.log('csv response: ', response);
    return { status: 'success', data: response.data, responseURL: response.request.responseURL };
  } catch (error) {
    console.log('Error uploading CSV file', error);
    return { status: 'error', error: error };
  }
};


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const patchContact = async (contactObj) => {
  const { contactId, payload } = contactObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.patch(
      api_endpoint() + `/contacts/api/contacts/${contactId}/`, {...payload},
      headers);
      console.log(response.data
    );
    return { status: 'success', data: response.data };
  } catch (error) {
    console.log('Error updating a contact', error);
  }
}


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const putContact = async (contactObj) => {
  const { contactId, payload } = contactObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/contacts/api/contacts/${contactId}/`, {...payload},
      headers);
      console.log(response.data);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error updating a contact', error);
    return {status: 'error'};
  }
}


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting contacts
// reload state allows updating by making another api resquest
export const useGetContacts = (contactsObj) => {
  const { reload, pageId } = contactsObj;
  const [contacts, setContacts] = useState(); // State to set and return
  const getContacts = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/contacts/?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setContacts(response.data);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    getContacts();
  }, [reload, pageId])

  return contacts ? contacts : null

}


// Function for getting contacts
export const getContacts = async () => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(
      // 'http://localhost:7000/api',
      api_endpoint() + '/contacts/api/contacts/',
      headers);
    return response;
  } catch (error) {
    console.log('Error getting contacts', error);
  }
}

// Hook for getting contacts
export const useGetContact = (contactId, reload) => {
  const [contact, setContact] = useState(); // State to set and return
  const getContact = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/contacts/${contactId}/`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setContact(response.data);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    if (contactId) {
      getContact();
    }
  }, [contactId, reload])

  return contact ? contact : null

}

export const getContact = async (contactId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/contacts/api/contacts/${contactId}/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error getting contacts', error);
    return {status: 'error'};
  }
}




























// ==========================================================================================================================
// ==========================================================================================================================
//      CUSTOM FIELDS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const createCustomField = async (customFieldObj) => {
  const { payload } = customFieldObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/contacts/api/custom-fields/', {...payload},
      headers);
      console.log(response.data);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status:'error', error:'error'}
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const putCustomField = async (customFieldObj) => {
  const { payload, customFieldId } = customFieldObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/contacts/api/custom-fields/${customFieldId}/`, {...payload},
      headers);
      console.log(response.data);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status:'error', error:'error'}
  }
}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
// Function for deleting a contact
export const deleteCustomField = async (fieldId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.delete(
      api_endpoint() + `/contacts/api/custom-fields/${fieldId}/`,
      headers);
      console.log(response.data);
    return {status:'success'};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status:'error', error:'error'}
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting paginated custom fields
export const useGetAllCustomFields = (reload) => {
  const [customFields, setCustomFields] = useState(); // State to set and return
  const getCustomFields = async () => {
    try {
      let allCustomFields = [];

      // Function to fetch custom fields recursively
      const fetchCustomFields = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allCustomFields = [...allCustomFields, ...data.results];
        }

        if (data.next) {
          await fetchCustomFields(data.next); // Fetch next page
        } else {
          setCustomFields(allCustomFields); // Set the accumulated custom fields
        }
      };

      await fetchCustomFields(api_endpoint() + '/contacts/api/custom-fields/');
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getCustomFields();
    }
  }, [reload])

  return customFields ? customFields : null

}

// Hook for getting paginated custom fields
export const useGetCustomFields = (reload, pageId) => {
  const [customFields, setCustomFields] = useState(); // State to set and return
  const getCustomFields = async () => {
    try {
      var headers = {
        'accept': 'application/json',
        'withCredentials': true,
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/custom-fields/?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      console.log('response', response.data.results);
      setCustomFields(response.data.results);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    if (reload || reload === undefined) {
      getCustomFields();
    }
  }, [reload, pageId])

  return customFields ? customFields : null

}

export const getCustomFields = async () => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true,
      // 'Authorization': 'Basic '+ encodedToken
    }
    const response = await axios.get(api_endpoint() + '/contacts/api/custom-fields/', headers);

    return {status: 'success', data: response.data.results};
  } catch (error) {
    console.log('Error getting contacts', error);
    return {status: 'error'}
  }
}







































// ==========================================================================================================================
// ==========================================================================================================================
//      TAGS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const createTag = async (tagObj) => {
  const { payload } = tagObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/contacts/api/tags/', {...payload},
      headers);
      console.log(response.data);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status: 'error'}
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------













// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const putTag = async (tagObj) => {
  const { tagId, payload } = tagObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/contacts/api/tags/${tagId}/`, {...payload},
      headers);
      console.log(response.data);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error updating a tag', error);
    return {status: 'error'}
  }
}


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

// Deletes tag from database -- DO NOT USE TO REMOVE A TAG FROM A USER!!
export const deleteTag = async (tagId) => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const deletedTag = await axios.delete(api_endpoint() + `/contacts/api/tags/${tagId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting survey', error);
      return {status:'error'};
  }
}


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting custom fields
export const useGetTags = (tagObj) => {
  const { pageId = 1 } = tagObj;
  const [tags, setTags] = useState(); // State to set and return
  const getTags = async () => {
    try {
      // var username = 'user_woo@example.com';
      // var password = 'string';

      // const token = `${username}:${password}`;
      // const encodedToken = Buffer.from(token).toString('base64');
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true,
        // 'Authorization': 'Basic '+ encodedToken
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/tags/`, headers); //?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setTags(response.data.results);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    getTags();
  }, [])

  return tags ? tags : null

}






































// ==========================================================================================================================
// ==========================================================================================================================
//      FUNNELS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a funnel
export const createFunnel = async (funnelObj) => {
  const { payload } = funnelObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/cms/api/funnels/', {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating funnel', error);
  }
}

// Function for creating a stage
export const createStage = async (stageObj) => {
  const { funnelId, payload } = stageObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/cms/api/funnels/${funnelId}/stages/`, {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating stage', error);
  }
}

// Function for creating a step
export const createStep = async (stepObj) => {
  const { funnelId, payload } = stepObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/cms/api/funnels/${funnelId}/steps/`, {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating step', error);
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for patching a funnel
export const patchFunnel = async (funnelObj) => {
  const { funnelId, payload } = funnelObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.patch(
      api_endpoint() + `/cms/api/funnels/${funnelId}/`, {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating funnel', error);
  }
}


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for replacing/updating a funnel
export const putFunnel = async (funnelObj) => {
  const { funnelId, payload } = funnelObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/cms/api/funnels/${funnelId}/`, {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating funnel', error);
  }
}

// Function for replacing/updating a stage
export const putStage = async (stageObj) => {
  const { funnelId, stageId, payload } = stageObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/cms/api/funnels/${funnelId}/stages/${stageId}/`, {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating stage', error);
  }
}

// Function for replacing/updating a step
export const putStep = async (stepObj) => {
  const { funnelId, stepId, payload } = stepObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/cms/api/funnels/${funnelId}/steps/${stepId}/`, {...payload},
      headers);
      console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Error creating step', error);
  }
}


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting all funnels
export const useGetFunnels = () => {
  const [funnels, setFunnels] = useState(); // State to set and return
  const getFunnels = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + '/cms/api/funnels/', headers);

      setFunnels(response.data.results);
    } catch (error) {
      console.log('Error getting funnels', error);
    }
  }

  useEffect(() => {
      getFunnels();
  }, [])

  return funnels ? funnels : null

}

// Hook for getting a specific funnel by id
export const useGetFunnel = (funnelId) => {
  const [funnel, setFunnel] = useState(); // State to set and return
  const getFunnel = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/`, headers);

      setFunnel(response.data);
    } catch (error) {
      console.log('Error getting funnels', error);
    }
  }

  useEffect(() => {
    if (funnelId) {
      getFunnel();
    }
    
  }, [funnelId])

  return funnel ? funnel : null

}

// Hook for getting all stages
export const useGetStages = (stagesObj) => {
  const { funnelId } = stagesObj;
  const [stages, setStages] = useState(); // State to set and return
  const getStages = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/stages/`, headers);

      getStages(response.data.results);
    } catch (error) {
      console.log('Error getting stages', error);
    }
  }

  useEffect(() => {
    if (funnelId) {
      setStages();
    }
      
  }, [funnelId])

  return stages ? stages : null

}

// Hook for getting a specific stage by id
export const useGetStage = (stageObj) => {
  const { funnelId, stageId } = stageObj;
  const [stage, setStage] = useState(); // State to set and return
  const getStage = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/stages/${stageId}/`, headers);

      getStage(response.data);
    } catch (error) {
      console.log('Error getting stages', error);
    }
  }

  useEffect(() => {
    if (funnelId, stageId) {
      setStage();
    }
      
  }, [funnelId, stageId])

  return stage ? stage : null

}

// Hook for getting all steps
export const useGetSteps = (stepsObj) => {
  const { funnelId } = stepsObj;
  const [steps, setSteps] = useState(); // State to set and return
  const getSteps = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/steps/`, headers);

      getSteps(response.data.results);
    } catch (error) {
      console.log('Error getting steps', error);
    }
  }

  useEffect(() => {
    if (funnelId) {
      setSteps();
    }
      
  }, [funnelId])

  return steps ? steps : null

}

// Hook for getting a specific step by id
export const useGetStep = (stepObj) => {
  const { funnelId, stepId } = stepObj;
  const [step, setStep] = useState(); // State to set and return
  const getStep = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/steps/${stepId}/`, headers);

      getStep(response.data);
    } catch (error) {
      console.log('Error getting steps', error);
    }
  }

  useEffect(() => {
    if (funnelId, stepId) {
      setStep();
    }
      
  }, [funnelId, stepId])

  return step ? step : null

}






































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// SURVEYS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a survey
export const createSurvey = async (surveyObj) => {
  const { payload } = surveyObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/user_survey/api/surveys/`, {...payload},
      headers);
      console.log(response.data);
      return {status: 'success'};

    } catch (error) {
    console.log('Error creating survey', error);
    return {status: 'error', error: error};
  }

}
// Function for creating a survey
export const createSurveySubmission = async (submissionObj) => {
  const { payload } = submissionObj;
  
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/user_survey/api/take_survey/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating survey', error);
  }

}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a survey with put api
export const updateSurvey = async (surveyObj) => {
  const { payload, surveyId } = surveyObj;
  console.log('payload', payload)
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/user_survey/api/surveys/${surveyId}/`, {...payload}, headers);
    console.log('response', response, response.data);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating survey', error);
    return {status: 'error'};
  }

}

export const updateSurveyTransaction = async (field, obj) => {
  try {
      const surveyRef = doc(db, 'surveys', obj.id);
      const updatedSurvey = await runTransaction(db, async (transaction) => {
        const activeSurveyDoc = await transaction.get(surveyRef);
        if (!activeSurveyDoc.exists()) {
          throw "Document does not exist!";
        }
        //logic
        let newSurveyDoc = updatedSurvey.data();
        // console.log('newSurveyDoc', newSurveyDoc)

        let fieldArray = newSurveyDoc[field] ? [...newSurveyDoc[field]] : [];
        // console.log("fieldArray", fieldArray);

        fieldArray.push(obj[field]);
        // console.log("fieldArray after push", fieldArray);

        newSurveyDoc[field] = fieldArray;
        // console.log("newSurveyDoc after update", newSurveyDoc);

        // Send to firestore
        transaction.set(
          surveyRef,
          { 
            [field]: fieldArray,
            
          },
          {
            merge: true
          }
        );
  
      });

      return 'success';

  }

  catch (error) {
      console.log('Error updating survey', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteSurvey = async (surveyId) => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/user_survey/api/surveys/${surveyId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting survey', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetSurveys = ({reset: reset}) => {
  const [surveys, setSurveys] = useState(); // State to set and return
  const getSurveys = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/user_survey/api/surveys/`, headers);

      setSurveys(response.data.results);
    } catch (error) {
      console.log('Error getting surveys', error);
    }
  }

  useEffect(() => {
    if (reset) {
      getSurveys();
    }
  }, [reset])

  return surveys ? surveys : null

}

// GET SINGLE ATTACHMENT
export const useGetSurvey = (surveyId) => {
  const [survey, setSurvey] = useState();

  // GET SINGLE ATTACHMENT
  const getSurvey = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/user_survey/api/surveys/${surveyId}/`, headers);

      setSurvey(response.data);
    } catch (error) {
      console.log('Error getting surveys', error);
    }
  }

  useEffect(() => {
    if (surveyId) {
      getSurvey()
    }
    
  }, [surveyId])


  return survey ? survey : null

}










































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// FORMS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a form
export const postForm = async (formObj) => {
  const { payload } = formObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/forms/api/forms/`, {...payload},
      headers);
      console.log(response.data);
      return {status: 'success'};

    } catch (error) {
    console.log('Error creating form', error);
    return {status: 'error', error: error};
  }

}

// Function for creating a form
export const postFormShareLink = async (formObj) => {
  const { payload } = formObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/forms/api/forms/create_share_link/`, {...payload},
      headers);
      console.log(response.data);
      return {status: 'success'};

    } catch (error) {
    console.log('Error creating form', error);
    return {status: 'error', error: error};
  }

}


// Function for subscribing to a form
export const postFormSubscription = async (submissionObj) => {
  const { payload } = submissionObj;
  
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    
    const token = null; // <-- Replace with token method when known

    const response = await axios.post(
      api_endpoint() + `/forms/api/user/subscribe/create/${token}/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating form', error);
  }

}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a form with put api
export const putForm = async (formObj) => {
  const { payload, formId } = formObj;
  console.log('payload', payload)
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/forms/api/forms/${formId}/`, {...payload}, headers);
    console.log('response', response, response.data);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating form', error);
    return {status: 'error'};
  }

}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteForm = async (formId) => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/forms/api/forms/${formId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting form', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetForms = ({reset: reset}) => {
  const [forms, setForms] = useState(); // State to set and return
  const getForms = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/forms/api/forms/`, headers);

      setForms(response.data.results);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    if (reset) {
      getForms();
    }
  }, [reset])

  return forms ? forms : null

}

// GET FORM HOOK
export const useGetForm = (formId) => {
  const [form, setForm] = useState();

  const getForm = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
        const response = await axios.get(api_endpoint() + `/forms/api/forms/${formId}/`, headers);

      setForm(response.data);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    if (formId) {
      getForm()
    }
    
  }, [formId])


  return form ? form : null

}

// GET FORM SUBSCRIPTION HOOK
export const useGetFormSubscription = (token) => {
  const [form, setForm] = useState();

  const getFormSubscription = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }

      const response = await axios.get(api_endpoint() + `/forms/api/forms/subscribe/${token}/`, headers);

      setForm(response.data);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    if (token) {
      getFormSubscription()
    }
    
  }, [token])


  return form ? form : null

}










































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// EMAIL TEMPLATES
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating an email template
export const createEmailTemplate = async (emailTemplateObj) => {
  const { storagePayload, templatePayload } = emailTemplateObj;

  // STEP 1: Upload file to storage
  const uploadToStorage = async () => {
    try {
      console.log('storagePayload', storagePayload)
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.post(
        api_endpoint() + `/storage/api/folders/${storagePayload.folder ? storagePayload.folder : 1}/files/`, 
        // "https://webhook.site/5b2c3bcd-3356-4ee0-832d-3266443aa2cc",
        storagePayload,
        headers);
        return {status: "success", data: response};
  
      } catch (error) {
        console.log('Error uploading email template to storage', error);
    }  
  }

  // STEP 2: Save link to database
  const saveEmailTemplate = async (payload) => {
    try {  
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.post(
        api_endpoint() + `/templates/api/email_templates/`, {...payload},
        headers);
        console.log(response.data);
        return {status: 'success', data: response.data};
  
      } catch (error) {
      console.log('Error creating emailTemplate', error);
      return {status: 'error', error: error};
    }  
  }

  try {
    const fileUpload = await uploadToStorage();
    if (fileUpload.status === "success") {
      console.log('fileUpload', fileUpload);
      const newTemplate = await saveEmailTemplate({...templatePayload, content_file: fileUpload.data.data.id})
      if (newTemplate.status === "success") {
        console.log('saved new template', newTemplate.data );
        return {status: 'success', fileUploadData: fileUpload.data, newTemplateData: newTemplate.data};
      }
    }
  }

  catch (error) {
    console.log('Error uploading template', error);
  }



}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const putEmailTemplate = async (emailTemplateObj) => {
  const { payload, emailTemplateId } = emailTemplateObj;
  console.log('payload', payload);
  console.log('emailTemplateId', emailTemplateId);
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, {...payload}, headers);
    console.log('response', response, response.data);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating emailTemplate', error);
    return {status: 'error'};
  }

}



// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const patchEmailTemplate = async (emailTemplateObj) => {
  const { payload, emailTemplateId } = emailTemplateObj;
  console.log('payload', payload);
  console.log('emailTemplateId', emailTemplateId);
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, {...payload}, headers);
    console.log('response', response, response.data);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating emailTemplate', error);
    return {status: 'error'};
  }

}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteEmailTemplate = async (emailTemplateId) => {
  try {
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting emailTemplate', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetEmailTemplates = ({reset}) => {
  const [emailTemplates, setEmailTemplates] = useState(); // State to set and return
  console.log('reset', reset);
  const getEmailTemplates = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/templates/api/email_templates/`, headers);
      console.log('response', response);
      setEmailTemplates(response.data.results);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (reset || reset === undefined) {
      console.log('running')
      getEmailTemplates();
    }
  }, [reset])

  return emailTemplates ? emailTemplates : null

}

// GET SINGLE ATTACHMENT
export const useGetEmailTemplate = (emailTemplateId) => {
  const [emailTemplate, setEmailTemplate] = useState();

  // GET SINGLE ATTACHMENT
  const getEmailTemplate = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);

      setEmailTemplate(response.data);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (emailTemplateId) {
      getEmailTemplate()
    }
    
  }, [emailTemplateId])


  return emailTemplate ? emailTemplate : null

}

export const getEmailTemplate = async (emailTemplateId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }

    const response = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);

    return ({status: 'success', data: response.data})
  } catch (error) {
    console.log('Error getting emailTemplates', error);
  }
}

export const useGetEmailTemplateStorageFile = (emailTemplateId) => {
  const [emailTemplate, setEmailTemplate] = useState();

  // GET SINGLE TEMPLATE
  const getEmailTemplate = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const template = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);
      if (template.status === 200) {
        console.log('template', template);
        const contentFileId = template.data.content_file;
        console.log('contentFileId', contentFileId);
        const templateFile = await getStorageFile({folderId:1, fileId: contentFileId});
        if (templateFile.status == "success") {
          try {
            const templateData = templateFile.data.file
            const response = await fetch(templateData, {
              // mode: 'no-cors',
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });

            const getData = await response.text();

            console.log('getData', getData);
            setEmailTemplate({template: template.data, templateFile: templateFile.data, data: JSON.parse(getData)});

          } catch (error) {
            console.log('Error fetching data:', error);
          }

        }
      }
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (emailTemplateId) {
      getEmailTemplate()
    }
    
  }, [emailTemplateId])


  return emailTemplate ? emailTemplate : null

}











































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// LISTS (MAILING LISTS)
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetLists = ({ reset }) => {
  const [lists, setLists] = useState(); // State to set and return
  // console.log('reset', reset);
  const getLists = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/lists/api/all_lists/`, headers);
      console.log('response', response);
      setLists(response.data.results);
    } catch (error) {
      console.log('Error getting lists', error);
    }
  }

  useEffect(() => {
    if (reset || reset === undefined) {
      console.log('running')
      getLists();
    }
  }, [reset])

  return lists ? lists : null

}

export const getLists = async () => {
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/lists/api/all_lists/`, headers);
    console.log('response', response);
    return(response.data.results);
  } catch (error) {
    console.log('Error getting lists', error);
  }
}


















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// STORAGE FILES
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating an email template
export const createStorageFile = async (storageFileObj) => {
  const { storageFilePayload, folderId } = storageFileObj;

  try {
    console.log('storageFilePayload', storageFilePayload)
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/`, 
      // "https://webhook.site/5b2c3bcd-3356-4ee0-832d-3266443aa2cc",
      storageFilePayload,
      headers);
      console.log('response', response)
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error uploading file to storage', error);
      return {status: "error"};
  }
}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const putStorageFile = async (storageFileObj) => {
  const { storageFilePayload, folderId, fileId } = storageFileObj;
  console.log('storageFileObj', storageFileObj)

  try {
    console.log('storageFilePayload', storageFilePayload)
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/${fileId}/`, 
      // "https://webhook.site/5b2c3bcd-3356-4ee0-832d-3266443aa2cc",
      storageFilePayload,
      headers);
      return {status: "success", data: response};

    } catch (error) {
      console.log('Error uploading file to storage', error);
      return {status: "error"};
  }
}



// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const patchStorageFile = async (storageFileObj) => {
  const { storageFilePayload, folderId, fileId } = storageFileObj;
  console.log('storageFileObj', storageFileObj)

  try {
    console.log('storageFilePayload', storageFilePayload)
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.patch(
      api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/${fileId}/`, 
      // "https://webhook.site/5b2c3bcd-3356-4ee0-832d-3266443aa2cc",
      storageFilePayload,
      headers);
      return {status: "success", data: response};

    } catch (error) {
      console.log('Error uploading file to storage', error);
  }
}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteStorageFile = async ({ folderId, fileId }) => {
  try {
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/${fileId}/`)
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting file', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetStorageFiles = ({reload, folderId}) => {
  const [storageFiles, setStorageFiles] = useState(); // State to set and return
  const getStorageFiles = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/`, headers);
      console.log('response', response);
      setStorageFiles(response.data.results);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (reload || reload === undefined) {
      getStorageFiles();
    }
  }, [reload])

  return storageFiles ? storageFiles : null

}

// GET FILE FROM STORAGE
export const useGetStorageFile = (obj) => {
  const { reload, folderId, fileId } = obj;
  const [storageFile, setStorageFile] = useState(); // State to set and return
  const getStorageFile = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/${fileId}/`, headers);
      setStorageFile(response.data);
    } catch (error) {
      console.log('Error getting file', error);
    }
  }

  useEffect(() => {
    if ( folderId && fileId ) {
      // console.log(reset ? 'GETTING IMAGE AFTER RESET' : 'Getting initial image')
      getStorageFile();
    }
  }, [reload, folderId, fileId])

  return storageFile ? storageFile : null

}

// GET RICH TEXT FILE FROM STORAGE
export const useGetStorageFileRichText = (obj) => {
  const { reload, folderId, fileId } = obj;
  console.log('foldeId', folderId, 'fileId', fileId)
  const [storageFile, setStorageFile] = useState(); // State to set and return
  const getStorageFile = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const storageResponse = await axios.get(api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/${fileId}/`, headers);
      console.log('storageResponse', storageResponse);
      if (storageResponse.status === 200) {
        try {
          const response = await fetch(storageResponse.data.file, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const getHtml = await response.text();
          console.log('getHtml', getHtml)
          setStorageFile(getHtml);

        } catch (error) {
          console.log('Error fetching data:', error);
        }        
        
      }
    } catch (error) {
      console.log('Error getting file', error);
    }
  }

  useEffect(() => {
    if ( folderId && fileId ) {
      // console.log(reset ? 'GETTING IMAGE AFTER RESET' : 'Getting initial image')
      getStorageFile();
    }
  }, [reload, folderId, fileId])

  return storageFile ? storageFile : null

}



export const getStorageFile = async (obj) => {
  const { folderId, fileId } = obj;
  try {
    var headers = {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/storage/api/folders/${folderId ? folderId : 1}/files/${fileId}/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error getting file', error);
    return {status: 'error', error: error};
  }
}


export const getStorage = async (emailTemplateId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }

    const response = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);

    return ({status: 'success', data: response.data})
  } catch (error) {
    console.log('Error getting file', error);
  }
}










































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// FEEDBACK (FIREBASE)  TEMPORARY!!
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// =============================================================
// COLLECTION: feedback
// =============================================================


// Get all feedback
export const useGetFeedback = () => {
  const [feedback, setFeedback] = useState();

  const getFeedback = async () => {
    try {
      const collectionRef = collection(db, 'feedback');

      onSnapshot(collectionRef, (snapshot) => {
        setFeedback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feedback', error);
    }
  }

  useEffect(() => {
      getFeedback();
  }, [])

  return feedback ? feedback : null
}






// =============================================================
// COLLECTION: featureRequests
// =============================================================


// Get all reported feature requests
export const useGetFeatureRequests = () => {
  const [featureRequests, setFeatureRequests] = useState();

  const getFeatureRequests = async () => {
    try {
      const collectionRef = collection(db, 'featureRequests');

      onSnapshot(collectionRef, (snapshot) => {
        setFeatureRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
      getFeatureRequests();
  }, [])

  return featureRequests ? featureRequests : null
}






// =============================================================
// COLLECTION: bugs
// =============================================================


// Get all reported feature requests
export const useGetBugs = () => {
  const [bugItems, setBugItems] = useState();

  console.log('starting bugs', bugItems);

  const getBugs = async () => {
    console.log('getting bugs');
    try {
      const collectionRef = collection(db, 'bugs');
      console.log('collectionref', collectionRef);
      onSnapshot(collectionRef, (snapshot) => {
        console.log('snapshot', snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
        setBugItems(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
      getBugs();
  }, [])

  return bugItems ? bugItems : null
}





















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// SUBMISSIONS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createSubmission = async (folder, obj) => {

  if (obj.id) {
    try {
        const submissionRef = doc(db, `submissions-${folder}`, obj.id);
        const submissionPayload = {
          ...obj,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }

        const newSubmission = await setDoc(submissionRef, submissionPayload, {merge:true});
        return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }
  } else {
    try {
      const submissionRef = collection(db, `submissions-${folder}`);
      const submissionPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }

      const newSubmission = await addDoc(submissionRef, submissionPayload, {merge:true});
      return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }

  }
}

export const updateSubmission = async (folder, obj) => {
  try {
      const submissionRef = doc(db, `submissions-${folder}`, obj.id);
      const submissionPayload = {
        ...obj,
        updatedAt: serverTimestamp(),
      }

      const updatedSubmission = await setDoc(submissionRef, submissionPayload, {merge:true});
      return "success"

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteSubmission = async (folder, obj) => {
  try {
    const submissionRef = doc(db, 'deletedSubmissions', obj.id);
    const submissionPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newSubmission = await setDoc(submissionRef, submissionPayload);
    await deleteDoc(doc(db, `submissions-${folder}`, obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}

export const archiveSubmissionForConsidering = async (folder, obj) => {
  console.log('folder:', folder, 'obj', obj);
  try {
    const consideringRef = doc(db, 'considering', obj.id);
    const consideringPayload = {
      ...obj,
      voteUp: [obj.email],
      voteDown: [],
      consideredAt: serverTimestamp(),
    }

    const newConsidering = await setDoc(consideringRef, consideringPayload);
    console.log('newConsidering', newConsidering);
    await deleteDoc(doc(db, `submissions-${folder}`, obj.id));

  }

  catch (error) {
      console.log('Error archiving submission', error);
  }
}




// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET SUBMISSIONS OF A SPECIFIC FORM (FOLDER)
export const useGetSubmissions = (folder) => {
  const [submissions, setSubmissions] = useState();
  const authContext = useAuth();

  const getSubmissions = async () => {
    try {
      const collectionRef = collection(db, `submissions-${folder}`);
      const q = query(collectionRef, where('shared', 'array-contains', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setSubmissions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting submissions', error);
    }
  }

  useEffect(() => {
      getSubmissions()
  }, [])

  return submissions ? submissions : null

}

// GET SINGLE ATTACHMENT
export const useGetSubmission = (folder, submissionId) => {
  const [submission, setSubmission] = useState();
  const authContext = useAuth();

  // GET SINGLE ATTACHMENT
  const getSubmission = async () => {
    try {
      const docRef = doc(db, `submissions-${folder}`, submissionId);
      onSnapshot(docRef, (doc) => {
        setSubmission({...doc.data(), id: doc.id})
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setSubmission({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setSubmission(null)
      // }
      
    } catch (error) {
      console.log('Error getting single submission', error);
    }
  }

  useEffect(() => {
    if (submissionId) {
      getSubmission()
    }
    
  }, [submissionId])


  return submission ? submission : null

}


















































// ==========================================================================================================================
// ==========================================================================================================================
//      USER API
// ==========================================================================================================================
// ==========================================================================================================================

// ------------------------------------------------------------------------------------
// READ / GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET CLIENTS FROM CLIENTS COLLECTION
// BASED ON SPECIFIC QUERIES FROM OBJ MAP
export const useGetClients = (obj) => {
  const [clients, setClients] = useState();

  const getAllClients = async () => {
    // console.log('running all')
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  const getClientsByStatus = async () => {
    // console.log('running status')
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where('status', '==', obj.status), orderBy('updatedAt', 'desc'), limit(20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  // Get clients based on a certain field and field value
  // obj({field, value, limit})
  const getClientsByField = async (obj) => {
    // console.log('running status')
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where(obj.field, '==', obj.value), orderBy('updatedAt', 'desc'), limit(obj.limit ? obj.limit : 20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  const getClientsByCampaign = async () => {
    // console.log('running campaign')
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where('campaign', '==', obj.campaign), orderBy('updatedAt', 'desc'), limit(20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  useEffect(() => {
    if (obj.type === 'all') {
      getAllClients();
    } else
    if (obj.type === 'status') {
      getClientsByStatus();
    } else
    if (obj.type === 'campaign') {
      getClientsByCampaign();
    }

  }, [obj.type])

  return clients ? clients : null

}

// GET CLIENTS FROM CLIENTS COLLECTION
// BASED ON SPECIFIC FIELDS AND FIELD VALUES
// obj({field, value, limit})
export const useGetClientsByField = (obj) => {
  const [clients, setClients] = useState();
  const [noClients, setNoClients] = useState(false);

  // Get clients based on a certain field and field value
  // obj({field, value, limit})
  const getClientsByField = async (obj) => {
    // console.log('running status')
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where(obj.field, '==', obj.value), orderBy('updatedAt', 'desc'), limit(obj.limit ? obj.limit : 20));
      onSnapshot(q, (snapshot) => {
        if (snapshot.size > 0) {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
        } else {
          setNoClients(true);
        }
        
      })
    } catch (error) {
      console.log('Error getting clients', error);
      setNoClients(true);
    }
  }

  const getAllClients = async () => {
    // console.log('running all')
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(obj.limit ? obj.limit : 20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }


  useEffect(() => {
    if (obj) {
      // getClientsByField()
      setClients(mockData.contacts.contacts)
    }
  }, [obj])

  useEffect(() => {
    if (noClients && obj) {
      getAllClients()
    }
  }, [obj, noClients])

  return clients ? clients : null

}

// GET ALL CLIENTS FROM CLIENTS COLLECTION
export const useGetAllClients = (id) => {
  const [clients, setClients] = useState();
  const getAllClients = async () => {
    try {
      const collectionRef = collection(db, 'clients');
      // const q = query(collectionRef, where('teacherId', '==', authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(collectionRef, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
      } catch (error) {
      console.log('Error getting clients', error);
      }
    }

  useEffect(() => {
    if (id) {
      getAllClients();
    }
  }, [id])

  return clients ? clients : null

}

  
// ------------------------------------------------------------------------------------
// CREATE / POST OPERATIONS
// ------------------------------------------------------------------------------------

export const addProspect = async (obj) => {
  try {
      const clientRef = collection(db, 'clients');
      const clientPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const newClient = await addDoc(clientRef, clientPayload);

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}

export const updateProspect = async (obj) => {
  try {
      const clientRef = doc(db, 'clients', obj.id);
      const clientPayload = {
        ...obj,
        updatedAt: serverTimestamp()
      }

      const updatedClient = await setDoc(clientRef, clientPayload, {merge:true});

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}

// Hook
export const useFetchSubs = () => {
  const [subs, setSubs] = useState(); // State to set and return
  const authContext = useAuth();
  const fetchSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/', {headers});
      setSubs(response.data.results);
      authContext.setSubs(response.data.results);
    } catch (error) {
      console.log('Error fetching subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}



// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteProspect = async (obj) => {
  try {
    const clientRef = doc(db, 'deleted', obj.id);
    const clientPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newClient = await setDoc(clientRef, clientPayload);
    await deleteDoc(doc(db, 'clients', obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}

















































