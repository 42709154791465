import React, { cloneElement, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { selectedIcons } from './CustomFields';
import { createCustomField, useGetCustomFields } from '../../hooks/mutations';
import { InputField } from '../ui/Fields';
import { getRandomInts } from '../../lib/Functions';

const CreateCustomFields = (props) => {
  const { setReload, setIsAddingField, incomingNewCustomFields } = props;
  const theme = useTheme();
  const [newCustomFields, setNewCustomFields] = useState(incomingNewCustomFields ? incomingNewCustomFields : []);
  const existingFields = useGetCustomFields();
  const [notUnique, setNotUnique] = useState([]);
  const existingNameArray = existingFields ? existingFields.map(x => x.field_name) : [];

  const screenSize = useScreenSize();

  // console.log('incomingNewCustomFields', incomingNewCustomFields);
  // console.log('newCustomFields', newCustomFields);

  useEffect(() => {
    if (incomingNewCustomFields) {
      setNewCustomFields(incomingNewCustomFields);
    }
  }, [incomingNewCustomFields])

  useEffect(() => {
    if (newCustomFields) {
      checkUniqueName();
    }
  }, [newCustomFields])

  const customFieldData = {
    "id": getRandomInts(16), // crypto.randomUUID(),
    "data_type": "text",
    "field_name": ""
  };

  const handleAddCustomField = () => {
    setNewCustomFields(prev => [
      ...prev,
      customFieldData
    ]);
  };

  const handleFieldType = (value, id) => {
    const newFields = [...newCustomFields];
    const updated = newFields.map(x => {
      if (x.id === id) {
        if (value === 'enum') {
          return {
            ...x,
            data_type: value,
            enum_values: ['placeholder']
          };
        } else if (value === 'list') {
          return {
            ...x,
            data_type: value,
            validation_rule: 'enum_values',
            enum_values: ['placeholder']
          };
        } else {
          return {
            ...x,
            data_type: value
          };
        }
      } else {
        return x;
      }
    });

    setNewCustomFields(updated);
  };

  const handleFieldName = (value, id) => {
    const newFields = [...newCustomFields];
    const updated = newFields.map(x => {
      if (x.id === id) {
        return {
          ...x,
          field_name: value
        };
      } else {
        return x;
      }
    });
    setNewCustomFields(updated);
  };

  const checkUniqueName = () => {
    const checkingCustomFields = [ ...newCustomFields ];

    // Check if field name is already used in existing fields
    const uniqueExistingCheck = checkingCustomFields.filter(x => existingNameArray.find(y => y === x.field_name) );
    // console.log('uniqueExistingCheck', uniqueExistingCheck);

    // Check if field name exists in new fields    
    const seenValues = {};
    // console.log('seenValues', seenValues);
    const uniqueNewCheck = checkingCustomFields.filter(item => {
        const value = item.field_name;
        if (value !== undefined) {
            if (seenValues[value]) {
                return true; // Include the item in the result as it has a duplicate value
            } else {
                seenValues[value] = true;
            }
        }
        return false;
    });

    setNotUnique([...uniqueExistingCheck, ...uniqueNewCheck]);
  }

  const cancelNewFields = () => {
    if (setIsAddingField) {
      setIsAddingField(false);
    }
    setNewCustomFields([]);
  };

  const postCustomField = async (customField) => {
    // console.log('customField', customField);
    const customFieldObj = {
        payload: {
        'field_name': customField.field_name,
        'data_type': customField.data_type,
        'enum_values': customField.enum_values ? customField.enum_values : null,
        'validation_rule': customField.validation_rule ? customField.validation_rule : null
      }
    }
    const posted = await createCustomField(customFieldObj);
    // console.log(`posted ${customField.field_name}:`, posted);
  }

  const handleSubmit = async () => {
    try {
        // Map each postCustomField call to a promise
        const promises = newCustomFields.map(customField => postCustomField(customField));
        
        // Wait for all promises to resolve
        await Promise.all(promises);

        // All postCustomField calls have succeeded
        // Call the function you want to run after all postCustomField calls
        setReload(true);
        setNewCustomFields([])
    } catch (error) {
        console.error('Error occurred:', error);
        // Handle the error if necessary
    }
};

  return (
    <Grid
      container
      // style={{
      //   border: `2px solid ${theme.palette.primary.main}`,
      //   borderRadius: 8,
      //   padding: 24
      // }}
    >
      <Grid
        container
        display={screenSize.xs ? 'none' : 'inherit'}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
        >
          <Typography

          >
            Field Name
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
        >
          <Typography

          >
            Field Type
          </Typography>
        </Grid>
      </Grid>
      {newCustomFields.map((field, index) => (
        <Grid
          container
          style={{
            marginTop: index === 0 ? '16px' : null,
            alignItems: 'center'
          }}
        >
          <Grid
            item
            paddingRight={screenSize.xs ? 0 : 2}
            marginBottom={screenSize.xs ? 1 : 0}
            xs={12}
            sm={6}
            md={4}
          >
            <InputField
              placeholder='Field Name'
              value={newCustomFields[index]?.["field_name"]}
              onChange={(e) => handleFieldName(e.target.value, field.id)}
            />
          </Grid>
          <Grid
            item
            px={0.5}
            xs={12}
            sm={6}
            md={8}
          >
            <Grid
              container
              style={{
                gap: 8,
              }}
            >
              {screenSize.lg
                ?
                <TextField
                  select
                  fullWidth
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight:1,
                      height:55
                    }
                  }}
                  style={{
                    margin: 0,
                    borderRadius: 4,
                    padding:'0 16px',
                    boxShadow: '0 0 5px #00000030',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  value={newCustomFields[index]["data_type"]}
                  onChange={(e) => handleFieldType(e.target.value, field.id)}
                >
                  {Object.entries(selectedIcons).map(([key, value]) => (
                    <MenuItem
                      key={key}
                      value={key}
                    >
                      <ListItemIcon>
                        {cloneElement(value.icon, { width: key === 'phone_number' || key === "file" ? 16 : (key === 'number' || key === 'large_text_field' ? 24 : 20), color1: theme.palette.primary.main, style:{height:20} })}
                      </ListItemIcon>

                      {value.label}

                    </MenuItem>
                  ))}
                </TextField>
                :
                Object.entries(selectedIcons).map(([key, value], iconIndex) => (
                  <Grid
                    item
                    xs
                    sm={1.91}
                  >
                    <InputButtons
                      type={key}
                      value={value}
                      fieldType={newCustomFields[index]["data_type"]}
                      onClick={() => handleFieldType(key, newCustomFields[index].id)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {
            notUnique.find(x => x.id === field.id)
              ?
                <>
                  <Typography
                    color="warning.main"
                    style={{
                      marginTop:8
                    }}
                  >
                    That field already exists.
                  </Typography>
                </>
              :
                null
          }
          {
            newCustomFields.length &&
            index !== newCustomFields.length - 1 &&
            !field.field_name.length
              ?
                <>
                  <Typography
                    color="warning.main"
                    style={{
                      marginTop:8
                    }}
                  >
                    Names cannot be blank.
                  </Typography>
                </>
              :
                null
          }

          <Divider style={{width:'100%', margin:'24px 0'}} />

        </Grid>
      ))}
      <Button
        onClick={handleAddCustomField}
        disabled={newCustomFields.filter(x => x.field_name.length < 1).length > 0}
        fullWidth
        style={{
          outline: `2px dashed ${newCustomFields.filter(x => x.field_name.length < 1).length > 0 ? "#ccc" : theme.palette.primary.main}`,
          height: 50,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 8,
          gap: 8,
          margin: '8px 0',
          textTransform: 'initial'
        }}
      >
        <AddSVG width={16} color1={newCustomFields.filter(x => x.field_name.length < 1).length > 0 ? '#ccc' : theme.palette.primary.main} />
        <Typography style={{ fontWeight: 600 }}>New Field</Typography>
      </Button>

      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          marginTop: '8px'
        }}
      >
        <Button
          onClick={cancelNewFields}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={
            newCustomFields.filter(x => !x.field_name.length).length ||
            notUnique.length
          }
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateCustomFields

// export const InputField = (props) => {
//   const { placeholder, value, onChange } = props;

//   return (
//     <TextField
//       placeholder={placeholder}
//       value={value}
//       onChange={onChange}
//       variant='standard'
//       autoFocus
//       fullWidth
//       InputProps={{
//         disableUnderline:true,
//         style:{
//           padding:"8px 16px"
//         }
//       }}
//       style={{
//         margin:0,
//         borderRadius:8,
//         boxShadow:'0 0 5px #00000030',
//       }}
//     />
//   )
// }

export const InputButtons = (props) => {
  const { fieldType, type, value, onClick } = props;
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Button
      onClick={onClick}
      fullWidth
      style={{
        outline: fieldType === type ? `2px solid ${theme.palette.primary.main}` : 'none',
        textTransform:'lowercase',
        lineHeight:1,
        height:'100%',
        boxShadow:'0 0 5px #00000030',
        alignItems:'center',
        // padding:'16px 16px',
        maxHeight:27,
        gap:8,
        borderRadius:8,
        display:'flex',
        color: fieldType ? (fieldType === type ? theme.palette.primary.main : '#aaa') : 'inherit'
      }}
    >
      <Grid
        sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          width:25
        }}
      >
        {cloneElement(value.icon, {
          color1:fieldType === type ? theme.palette.primary.main : "#aaa"
        })}
      </Grid>
      {screenSize.lg ? null : value.label}

    </Button>
  )
}
