import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  MenuItem,
  Typography,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, CheckmarkSVG, CloseFilledSVG, CloseOutlineSVG, CloseRoundedSVG, DeleteSVG, EmailSVG, EventSVG, FilterSVG, NumberSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { createContact, useGetContacts, useGetCustomFields } from '../../hooks/mutations';
import ImportCustomFields from '../custom_fields/ImportCustomFields';
import { InputField } from '../ui/Fields';

const CreateContact = () => {
  const theme = useTheme();
  // const incomingCustomFields = useGetCustomFields();
  // const incomingContact = useGetContacts();
  const [newContact, setNewContact] = useState({
    name: '',
    primary_email: '',
    tags: [],
    description: '',
    // is_active: true,
    custom_fields: {}
  });
  const [isAddingField, setIsAddingField] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);

  const screenSize = useScreenSize();

  console.log('newContact', newContact);

  // Set custom field state to incoming data on load
  useEffect(() => {
    if (resetSearch) {
      setResetSearch(false);
    }
  }, [resetSearch]);

  const handleTextStandard = (value, name) => {
    // const copiedContact = { ...newContact };
    // const customFields = { ...copiedContact.custom_fields };
    // const updatedCustomFields = { ...customFields, [name]: value }
    setNewContact({
      ...newContact,
      [name]: value
    })
  }

  const handleTextCustomField = (value, name) => {
    const copiedContact = { ...newContact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setNewContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleImportAllFields = (customFields) => {
    const copiedContact = { ...newContact };
    const existingFields = { ...copiedContact.custom_fields };
    let newFields = { ...existingFields };
    
    customFields.forEach(field => {
      if (Object.keys(existingFields).includes(field.field_name)) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        newFields = { ...newFields, [field.field_name]: '' }
      }
    });

    setNewContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
  }

  const handleFieldImport = (field) => {
    const newItem = {[field.field_name]: ''}
    const copiedContact = { ...newContact };
    const customFields = { ...copiedContact.custom_fields };

    if (Object.keys(customFields).includes(field.field_name)) {
      console.log('THAT FIELD ALREADY EXISTS');
    } else {
      const updatedCustomFields = { ...customFields, ...newItem }
      setNewContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    }
  }

  const handleFieldRemove = (field) => {  
    const copiedContact = { ...newContact };
    const customFields = { ...copiedContact.custom_fields };
  
    // Create a copy of customFields without the specified key
    delete customFields[field.key];
  
    setNewContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));
  };
  
  const onSubmit = () => {
    const contactObj = {
      payload: {
        ...newContact
      }
    }
    const submittedContact = createContact(contactObj);
    console.log('submittedContact', submittedContact);
  }



  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
      }}
    >
      <Grid
        style={{
          background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width: '100%', //screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          transition:'.4s',
          maxWidth:1600
        }}
      >
        <Grid
          container
          style={{
            justifyContent:'space-between',
            alignItems:'center',
            marginBottom:16
          }}
        >
          <Typography
            sx={{
              fontSize:'1.8rem',
              fontWeight:600,
              color:'#ccc',
            }}
          >
            Add Contact
          </Typography>
          <Grid
            style={{
              display:'flex',
              gap:16
            }}
          >
            {
              // !isAddingField
              //   ?
                  <>
                    <ButtonCustom
                      iconStart={<UploadSVG width={20} color1="#fff" />}
                      text={ screenSize.sm ? null : "Import"}
                    />
                    <ButtonCustom
                      onClick={() => {
                        if (isAddingField) {
                          setIsAddingField(false);
                        } else {
                          setIsAddingField(true);
                        }
                      }}
                      iconStart={<AddSVG width={16} color1="#fff" style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                      text={ screenSize.sm ? null : ( isAddingField ? "Close" : "New Field" )}
                    />
                  </>
                // :
                  // <>
                  //   <ButtonCustom
                  //     onClick={() => setIsAddingField(false)}
                  //     iconStart={<CloseRoundedSVG width={16} color1="#fff" />}
                  //     text={ screenSize.sm ? null : "Close Fields"}
                  //   />
                  // </>
            }
          </Grid>
        </Grid>
        <Collapse
          in={isAddingField}
          style={{
            width:'100%'
          }}
        >
          <ImportCustomFields setIsOpen={setIsAddingField} existingCustomFields={Object.keys(newContact.custom_fields)} handleFieldImport={handleFieldImport} handleImportAllFields={handleImportAllFields}  />
        </Collapse>

        <TableWrapper>
          {
            newContact
              ?
                <>
                  <TableRow
                    customField={{key: 'name', value: newContact.name}}
                    handleTextCustomField={handleTextStandard}
                  />
                  <TableRow
                    customField={{key: 'primary_email', value: newContact.primary_email}}
                    handleTextCustomField={handleTextStandard}
                  />
                  {
                    Object.entries(newContact.custom_fields).map(([key, value], index) => (
                      <TableRow
                        id={key}
                        customField={{key: key, value: value}}
                        index={index}
                        handleTextCustomField={handleTextCustomField}
                        handleFieldRemove={handleFieldRemove}
                        showDelete
                      />
                    ))
                  }
                </>
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper>

        <Grid
          container
          variant="center"
        >
          <Button
            disabled={!newContact.name || !newContact.primary_email}
            variant="contained"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Grid>

      </Grid>

    </Grid>
  );
};

export default CreateContact;

const selectedIcons = {
  text: <TextSVG width={20} />, // <Typography style={{fontStyle:'italic', lineHeight:1, textTransform:'uppercase', fontWeight:800}}>T</Typography>,
  check: <CheckmarkSVG width={20} />,
  number: <NumberSVG width={30} />, //<Typography style={{lineHeight:1, fontSize:'.75rem', fontWeight:900}}>123</Typography>,
  email: <EmailSVG width={20} />,
  date: <EventSVG width={20} />
}

const columns = [
  {
    label: 'Name',
    id: 'field_name',
    width: null
  },
  {
    label: 'Type',
    id: 'data_type',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.05
  },
]

const HeaderRow = (props) => {
  const { columns, align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        // background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16
      }}
    >
      {
        columns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:theme.palette.primary.main, fontWeight:600}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        // maxHeight:600,
        marginTop:16
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { customField, index, handleTextCustomField, handleFieldRemove, showDelete } = props;
  const theme = useTheme();

  return (
    <Grid
      id={`r-${customField.key}`}
      container
      style={{
        // background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'0 32px',
      }}
    >
      {/* <Typography
        style={{
          margin:'0 16px',
          fontWeight:600,
          color: theme.palette.primary.main
        }}
      >
        {customField.key}
      </Typography> */}
      {/* <Grid
        // item
        style={{
          width:'auto'
        }}
        // xs
      > */}
        <InputField
          id={customField.key}
          label={customField.key}
          value={customField.value}
          onChange={(e) => handleTextCustomField(e.target.value, customField.key)}
          width="calc(100% - 50px)"
          // noAutoFocus
          margin
        />
      {/* </Grid> */}
      <Grid
        style={{
          width:50,
          margin:'auto',
          textAlign:'center'
        }}
      >
        {
          showDelete &&
            <IconButton
              onClick={() => handleFieldRemove(customField)}
              style={{
                width:35,
                height:35,
                margin:'0 0 24px 0'
              }}
            >
              <CloseFilledSVG width={25} color1="#ddd" />
            </IconButton>
        }
      </Grid>
      {
        // columns.map(item => (
        //   <TableColumn item={item}>
        //     {
        //       item.id === 'data_type' &&
        //         <Grid
        //           style={{
        //             width:50,
        //             display:'flex',
        //             alignItems:'center',
        //             justifyContent:'center',
        //             marginLeft:-10
        //           }}
        //         >
        //           {cloneElement(selectedIcons[customField.data_type], {color1:'#777'})}
        //         </Grid>
        //     }
        //     {
        //       item.id === 'actions' &&
        //         <VisibleSVG width={20} color1="#777" />
        //     }
        //     <Typography style={{ fontSize:'1rem'}}>{customField[item.id]}</Typography>
        //   </TableColumn>
        // ))
      }
    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}