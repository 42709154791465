import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  MenuItem,
  Typography,
  Collapse,
  ListItemIcon,
  Menu,
  Dialog,
  useScrollTrigger,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, CheckmarkSVG, ChevronSVG, CloseFilledSVG, CloseOutlineSVG, CloseRoundedSVG, CopySVG, DeleteSVG, EditSVG, EmailSVG, EventSVG, FilterSVG, NumberSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG, WarningSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { createContact, createStorageFile, putContact, putStorageFile, useGetContacts, useGetCustomFields, useGetTags } from '../../hooks/mutations';
import ImportCustomFields from '../custom_fields/ImportCustomFields';
import { InputField, InputFieldTypeDisplay } from '../ui/Fields';
import { useGetContact } from '../../hooks/mutations'
import { copyString } from '../../lib/Functions';
import ImportTags from '../tags/ImportTags';
import { Tag, TagButton } from '../ui/Tags';
import DialogWarning from '../ui/DialogWarning';

function ElevationScroll(props) {
  const { children, window  } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const triggerOnScrollElement = document.getElementById('drawer-contents');
  const screenSize = useScreenSize();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70,
    target: triggerOnScrollElement  ? triggerOnScrollElement : undefined,
  });

  return cloneElement(children, {
    style:{
      position:'sticky',
      top:0,
      padding: screenSize.xs ? '0 8px 12px 8px' : '0 32px 12px 32px',
      paddingBottom:16,
      background:trigger ? '#fff' : 'transparent',
      zIndex:1000,
      boxShadow: trigger ? '0 4px 5px #00000030' : 'none',
      transition:'box-shadow .5s'
    }

  });
}


const ContactEdit = (props) => {
  const { contacts, setSelectedContact, customFields, setReloadCustomFields, incomingContact, updateContacts, reloadContact, setReload } = props;
  // const [reload, setReload] = useState(false);
  // const receivedContact = useGetContact(1, reload);
  // const customFields = useGetCustomFields();
  // const tags = useGetTags();
  const screenSize = useScreenSize();
  const theme = useTheme();
  const [contact, setContact] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  // const [isAddingTag, setIsAddingTag] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [editing, setEditing] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [fieldToRemove, setFieldToRemove] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);

  // console.log('contact', contact);
  // console.log('reload', reload);

  // Set contact from api response 'incomingContact' state
  useEffect(() => {
    if (incomingContact) {
      setContact(incomingContact)
    }
  }, [incomingContact])

  // Open warning dialog when a field is selected to remove
  useEffect(() => {
    if (fieldToRemove) {
      setOpenWarning(true);
    }
  }, [fieldToRemove])

  // // Set contact from api response 'intialContact' state
  // useEffect(() => {
  //   // Use if passing the whole contact in
  //   if (receivedContact) {
  //     setContact(receivedContact)
  //   }

  // }, [receivedContact])

  const handleIsAddingField = () => {
    if (isAddingField) {
      setIsAddingField(false)
    } else {
      setIsAddingField(true)
    }
  }

  const handleCloseWarning = () => {
    setOpenWarning(false);
    setTimeout(() => {
      setFieldToRemove(null);
    }, 1500); 
  }

  const handleTextStandard = (value, name) => {
    setContact({
      ...contact,
      [name]: value
    })
  }

  const handleResetContact = () => {
    setContact(incomingContact);
  }

  const handleTextCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleListCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const copiedFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...copiedFields, [name]: value }
    const currentCustomField = customFields.find(x => x.id == name);
    if (currentCustomField.enum_values.includes(value)) {
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    } else {
      if (value) {
        setReloadCustomFields(true);
        setContact(prev => ({
          ...prev,
          custom_fields: updatedCustomFields
        }))
      }
    }
  }

  const handleNumberCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: Number(value) }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleDateCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: new Date(value).toLocaleDateString() }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleBoolCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleImageCustomField = async (value, name) => {
    console.log('value', value);
    const fileExists = contact.custom_fields[name];
    console.log('fileExists', fileExists);

    // if (!value) {
    //   const copiedContact = { ...contact };
    //   const customFields = { ...copiedContact.custom_fields };
    //   const updatedCustomFields = { ...customFields, [name]: null }
    //   setContact(prev => ({
    //     ...prev,
    //     custom_fields: updatedCustomFields
    //   }))
    //   return;
    // }

    try {
      // Save file to storage
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', 1);

      const storageFileObj = {
        folderId: 1,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows whent the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          console.log('storedImage', storedImage.data);
  
          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: Number(storedImage.data.id) }
          const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
  
          const savedContact = await onSubmit(updatedContact);
      }


        // setContact(prev => ({
        //   ...prev,
        //   custom_fields: updatedCustomFields
        // }))
      }

    }

    catch (error) {

    }
  }

  const handleFileCustomField = async (value, name) => {
    // console.log('value', value);
    const fileExists = contact.custom_fields[name];
    // console.log('fileExists', fileExists);

    try {
      // Save file to storage
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', 1);

      const storageFileObj = {
        folderId: 1,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows whent the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          // console.log('storedImage', storedImage.data);
  
          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: Number(storedImage.data.id) }
          const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
  
          const savedContact = await onSubmit(updatedContact);
      }


        // setContact(prev => ({
        //   ...prev,
        //   custom_fields: updatedCustomFields
        // }))
      }

    }

    catch (error) {

    }
  }

  const handleRichTextCustomField = async (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    const savedContact = await onSubmit({...copiedContact, custom_fields: updatedCustomFields});
    if (savedContact) {
      console.log('success', savedContact);
    }

  }

  const handleImportAllFields = (customFields) => {
    const copiedContact = { ...contact };
    const existingFields = { ...copiedContact.custom_fields };
    let newFields = { ...existingFields };
    
    customFields.forEach(field => {
      if (Object.keys(existingFields).includes(field.id)) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        newFields = { ...newFields, [field.id]: '' }
      }
    });

    setContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
  }

  const handleFieldImport = (field) => {
    const newItem = {[field.id]: ''}
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };

    if (Object.keys(customFields).includes(field.id)) {
      console.log('THAT FIELD ALREADY EXISTS');
    } else {
      const updatedCustomFields = { ...customFields, ...newItem }
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    }
  }

  const confirmFieldRemove = (field) => {
    setFieldToRemove(field);
  }

  const handleFieldRemove = () => {  
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
  
    // Create a copy of customFields without the specified key
    delete customFields[fieldToRemove.key];
  
    setContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));

    setOpenWarning(false);
  };

  const removeTag = async (removedTagId) => {
    const existingTags = [ ...contact.tags ];
    const updatedTags = existingTags.filter(x => x.id !== removedTagId);
    const updatedTagNames = updatedTags.map(x => x.name);
    const contactObj = {
      contactId: contact.id,
      payload: { ...contact, tags: updatedTagNames}
    }
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully removed tag to contact')
      if (reloadContact) {
        reloadContact(incomingContact.id);
      }
    }
  }

  // ADD TAG BUTTON MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isAddingTag = Boolean(anchorEl);
  const handleAddTagClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddTagClose = () => {
    setAnchorEl(null);
  };

  // ADD TAG BUTTON MENU
  const [anchorElTag, setAnchorElTag] = React.useState(null);
  const isAddingButtonTag = Boolean(anchorElTag);
  const handleAddButtonTagClick = (event) => {
    setAnchorElTag(event.currentTarget);
  };
  const handleAddButtonTagClose = () => {
    setAnchorElTag(null);
  };

  // ADD RESET BUTTON MENU
  const [anchorElReset, setAnchorElReset] = React.useState(null);
  const openReset = Boolean(anchorElReset);
  const handleResetClick = (event) => {
    setAnchorElReset(event.currentTarget);
  };
  const handleResetClose = () => {
    setAnchorElReset(null);
  };

  // NAVIGATE NEXT/PREV CONTACT IN CRM DRAWER
  const navigateContact = (direction) => {
    const currentIndex = contacts.findIndex(x => x.id === contact.id);
    if (direction === 'next') {
      const next = contacts[currentIndex + 1];
      if ((currentIndex) < contacts.length - 1) {
        console.log('next', next);
        setSelectedContact(next);
      }  
    }
    if (direction === 'back') {
      const next = contacts[currentIndex - 1];
      if ((currentIndex) > 0) {
        console.log('back', next);
        setSelectedContact(next);
      }  
    }
  }


  const onSubmit = async (contact) => {
    console.log('contact', contact);
    const existingTags = [ ...contact.tags ];
    const existingTagNames = existingTags.map(x => x.name);
  
    const contactObj = {
      contactId: contact.id,
      payload: {
        ...contact,
        tags: existingTagNames,
      }
    }
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully updated contact');
      if (setReload) {
        setReload(true);
      }
      if (updateContacts) {
        updateContacts(updatedContact.data);
      }
    }
  }

  return (
    contact
      ?
        <Grid
          style={{
            position:'relative'
          //   background:'#fff',
          //   borderRadius: 8,
          //   padding: screenSize.sm ? '36px 24px' : '36px 72px',
          //   boxShadow:'0 2px 10px #00000020',
          //   width: screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          //   transition:'.4s',
          //   maxWidth:1600
          }}
        >
            {/* <Grid
              elevation={4}
              style={{
                position:'sticky',
                top:0,
                paddingBottom:16,
                background:'#fff',
                zIndex:1000
              }}
            > */}
          <ElevationScroll {...props}>
            <div >
              <Grid
                container
                style={{
                  justifyContent:'space-between',
                  paddingTop:8
                }}
              >
              {
                setSelectedContact
                  ?
                    <Grid
                      container
                      style={{
                        justifyContent:'space-between'
                      }}
                    >
                      <Button
                        onClick={() => navigateContact('back')}
                        startIcon={<ChevronSVG height={12} color1={theme.palette.primary.main} />}
                        style={{
                          // position:'absolute',
                          // top:0,
                          // left:0,
                          // width:25,
                          minWidth:0,
                          // height:'100%',
                          display:'flex',
                          // padding:'20px 0',
                          zIndex:1001
                        }}
                      >
                        Back
                      </Button>

                      <Button
                        onClick={() => navigateContact('next')}
                        endIcon={<ChevronSVG height={12} color1={theme.palette.primary.main} style={{transform:'scale(-1)'}} />}
                        style={{
                          // position:'absolute',
                          // top:0,
                          // right:0,
                          lineHeight:1,
                          // width:25,
                          minWidth:0,
                          // height:'100%',
                          display:'flex',
                          // padding:'20px 0',
                          zIndex:1001
                        }}
                      >
                        Next
                        
                      </Button>
                    </Grid>
                  :
                    null
              }


                <Grid
                
                >
                  {
                    editTitle
                      ?
                        <TextField
                          id="name"
                          onBlur={() => setEditTitle(false)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setEditTitle(false)
                            }
                          }}          
                          value={contact.name}
                          onChange={(e) => handleTextStandard(e.target.value, 'name')}
                          variant='standard'
                          autoFocus
                          inputProps={{
                            style:{
                              padding:0,
                              background:'transparent'
                            }
                          }}
                          InputProps={{
                            disableUnderline:true,
                            readOnly: !editTitle,
                            sx:{
                              fontSize:'2rem',
                              fontWeight:500,
                              lineHeight:1,
                              transition: '.3s',
                              borderRadius:1,
                            }
                          }}
                          style={{
                            borderRadius:8,
                            width:'auto',
                            margin:0,
                            // boxShadow:readOnly ? null : '0 0 5px #00000030',
                            padding:0,
                          }}
                        />
                      :
                        <Grid display="flex" alignItems="center">
                          <Typography
                            style={{
                              fontSize:'2rem',
                              fontWeight:500,
                              lineHeight:1,
                              color: contact.name ? 'inherit' : '#aaa',
                              fontStyle: contact.name ? 'inherit' : 'italic' 
                            }}
                          >
                            {contact.name ? contact.name : 'Unnamed Contact'}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              setEditTitle(true);
                              // document.getElementById('name').focus();
                            }}
                          >
                            <EditSVG width={15} color1={theme.palette.primary.dark} />
                          </IconButton>
                        </Grid>
                  }

                  <Typography

                  >
                    {contact.primary_email}
                  </Typography>

                  {
                    contact.created_on &&
                      <Typography
                        style={{
                          marginBottom:8
                        }}
                      >
                        Added on {new Date(contact.created_on).toLocaleString('en-US', {month:'short', day:'2-digit', year:'numeric'})}
                      </Typography>
                  }

                </Grid>


                <Grid
                  style={{
                    display:'flex',
                    gap:16,
                    alignItems:'flex-start'
                  }}
                >
                  <ButtonCustom
                    onClick={handleResetClick}
                    variant="outlined"
                    color="secondary"
                    iconStart={<WarningSVG width={15} color1={theme.palette.secondary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                    text="Reset"
                  />
                  <Menu
                    id="reset-menu"
                    anchorEl={anchorElReset}
                    open={openReset}
                    onClose={handleResetClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}          
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    style={{
                      marginTop:4
                    }}
                  >
                    <Grid
                      container
                      style={{
                        width:300,
                        padding:'4px 16px',
                        justifyContent:'center'
                      }}
                    >
                    <Typography
                      style={{
                        fontSize:'1rem',
                        fontWeight:600
                      }}
                    >
                      Do you want to reset the contact?
                    </Typography>
                      <Typography
                      >
                        Unsaved changes will be deleted.
                      </Typography>
                      <Grid
                        container
                        justifyContent="space-around"
                        marginTop={1}
                      >
                        <ButtonCustom
                          onClick={handleResetClose}
                          variant="outlined"
                          color="secondary"
                          // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                          text="No"
                        />
                        <ButtonCustom
                          onClick={() => {
                            handleResetContact();
                            handleResetClose();
                          }}
                          color="secondary"
                          // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                          text="Yes"
                        />
                      </Grid>
                    </Grid>
                  </Menu>

                  {/* <ButtonCustom
                    onClick={() => isAddingField ? setIsAddingField(false) : setIsAddingField(true)}
                    variant="outlined"
                    iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                    text={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field")}
                  /> */}

                  <Button
                    disabled={!contact.primary_email}
                    variant="contained"
                    onClick={() => onSubmit(contact)}
                  >
                    {screenSize.sm ? 'Save' : "Save Contact"}
                  </Button>

                  {
                    // !isAddingTag &&
                    //   <>
                    //   </>
                  }
                </Grid>


                {
                  // TAGS
                  contact.tags
                    ?
                      <Grid
                        container
                        gap={1}
                        alignItems="center"
                      >
                        {
                          contact.tags.map(tag => (
                            <Tag
                              tag={tag}
                              removeTag={removeTag}
                              // setReload={setReload}
                              contactId={contact.id}
                              reloadContact={reloadContact}
                              showOptions
                            />
                          ))
                        }
                        <TagButton
                          bgColor="#eeeeee"
                          fontColor="#777777"
                          outlined
                          onClick={handleAddButtonTagClick}
                          iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingButtonTag ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                          label={ isAddingButtonTag ? 'Close' : 'Tag' }
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorElTag}
                          open={isAddingButtonTag}
                          onClose={handleAddButtonTagClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}          
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          style={{
                            marginTop:4
                          }}
                        >
                          <ImportTags
                            setIsOpen={isAddingButtonTag}
                            contact={contact}
                            contactId={contact.id}
                            existingTags={contact.tags}
                            handleFieldImport={handleFieldImport}
                            closeMenu={handleAddButtonTagClose}
                            removeTag={removeTag}
                            setEditing={setEditing}
                            reloadContact={reloadContact}
                            // setReload={setReload}
                          />
                        </Menu>

                      </Grid>
                    :
                      null
                }

                {
                  // CUSTOM FIELD ADDING
                  <Grid
                    container
                    style={{
                      marginTop:8
                    }}
                  >
                    {/* <ButtonCustom
                      onClick={() => isAddingField ? setIsAddingField(false) : setIsAddingField(true)}
                      variant="outlined"
                      iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                      text={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field")}
                    /> */}
                    <TagButton
                      bgColor={`${theme.palette.primary.main}20`}
                      fontColor={theme.palette.primary.main}
                      outlined
                      onClick={handleIsAddingField}
                      iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                      label={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field") }
                    />

                  </Grid>
                }

                <Collapse
                  in={isAddingField}
                  style={{
                    width:'100%',
                    marginTop:isAddingField ? 16 : 0,
                    transition:'.75s'
                  }}
                >
                  <ImportCustomFields
                    setIsOpen={setIsAddingField}
                    existingCustomFields={Object.keys(contact.custom_fields)}
                    handleFieldImport={handleFieldImport}
                    handleImportAllFields={handleImportAllFields}
                    setEditing={setEditing}
                    handleOpen={setIsAddingField}
                  />
                </Collapse>
              </Grid>


            </div>
          </ElevationScroll>
            {/* </Grid> */}


          <TableWrapper>
            {
              contact
                ?
                  <>
                    {/* <TableRow
                      customFields={customFields}
                      customField={{key: 'primary_email', value: contact.primary_email}}
                      handleTextCustomField={handleTextStandard}
                      setEditing={setEditing}
                      active={editing === 'primary_email'}
                    /> */}
                    {
                      Object.entries(contact.custom_fields).map(([key, value], index) => {
                        // const fieldIdArr = customFields.map(x => x.id);
                        const fieldId = customFields.filter(x => x.id == key)[0]?.id;
                        if (fieldId) {
                          return (
                            <TableRow
                              contactId={contact.id}
                              customFields={customFields}
                              customField={{key: fieldId, value: value}}
                              index={index}
                              handleTextCustomField={handleTextCustomField}
                              handleListCustomField={handleListCustomField}
                              handleNumberCustomField={handleNumberCustomField}
                              handleDateCustomField={handleDateCustomField}
                              handleBoolCustomField={handleBoolCustomField}
                              handleImageCustomField={handleImageCustomField}
                              handleFileCustomField={handleFileCustomField}
                              handleRichTextCustomField={handleRichTextCustomField}
                              confirmFieldRemove={confirmFieldRemove}
                              showDelete
                              setEditing={setEditing}
                              active={editing === key}
                            />
                          )
                        }
                      })
                    }
                  </>
                :
                  <Typography>No custom fields have been created yet.</Typography>
            }
          </TableWrapper>

          <Grid
            container
            variant="center"
            style={{
              paddingBottom:36
            }}
          >
            <Button
              disabled={!contact.primary_email}
              variant="contained"
              onClick={() => onSubmit(contact)}
            >
              {screenSize.sm ? 'Save' : "Save Contact"}
            </Button>
          </Grid>

          {/* REMOVE CUSTOM FIELD DIALOG */}
          <DialogWarning
            open={openWarning}
            onClose={handleCloseWarning}
            header="Remove custom field"
            title={`You are about to remove the ${customFields.filter(x => x.id === fieldToRemove?.key )[0]?.field_name} field`}
            messages={{
              one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
              confirm:'Yes'
            }}
            // deleting
            cancel={handleCloseWarning}
            confirm={handleFieldRemove}
            // icons
          />

        </Grid>
      :
        null
  )
}

export default ContactEdit

const TableWrapper = (props) => {
  const { children } = props;
  const screenSize = useScreenSize();
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        padding: screenSize.xs ? '16px 8px' : '16px 32px',
        // transition:'.5s'
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { contactId, customField, index, handleTextCustomField, handleListCustomField, handleNumberCustomField, handleDateCustomField, handleBoolCustomField, handleImageCustomField, handleFileCustomField, handleRichTextCustomField, confirmFieldRemove, showDelete, setEditing, active, customFields } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        // width: showDelete ? 'calc(100% - 50px)' : '100%',
        alignItems:'center'
      }}
    >
      {
        index !== null && index !== undefined
          ?
            <EditableDisplay
              fieldKey={customField.key}
              fieldValue={customField.value}
              customField={customField}
              contactId={contactId}
              onTextChange={(e) => handleTextCustomField(e.target.value, customField.key)}
              onListChange={(e) => handleListCustomField(e, customField.key)}
              onNumberChange={(e) => handleNumberCustomField(e.target.value, customField.key)}
              onDateChange={(e) => handleDateCustomField(e, customField.key)}
              onBoolChange={(e) => handleBoolCustomField(e.target.checked, customField.key)}
              onImageChange={(e) => handleImageCustomField(e, customField.key)}
              onFileChange={(e) => handleFileCustomField(e, customField.key)}
              onRichTextChange={(e) => handleRichTextCustomField(e, customField.key)}
              active={active}
              setEditing={setEditing}
              showDelete={showDelete}
              onClickRemove={() => confirmFieldRemove(customField)}
              customFields={customFields}
            />
          :
            <>
              <Grid display="flex" alignItems="center">
                <Typography
                  style={{
                    marginLeft:16,
                    marginRight:4,
                    fontWeight:500,
                    color: theme.palette.primary.main,
                  }}
                >
                  {customField.key}
                </Typography>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    copyString(customField.value);
                  }}
                >
                  <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
                </IconButton>
              </Grid>
              <InputField
                // placeholder='Field Name'
                // label={customField.key}
                value={customField.value}
                onChange={(e) => handleTextCustomField(e.target.value, customField.key)}
                // width="calc(100% - 50px)"
                margin
              />
            </>

      }
      {/* <Grid
        style={{
          width:50,
          margin:'auto',
          textAlign:'center'
        }}
      >
        {
          showDelete &&
            <IconButton
              onClick={() => confirmFieldRemove(customField)}
              style={{
                width:35,
                height:35,
                margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
              }}
            >
              <CloseFilledSVG width={25} color1="#ccc" />
            </IconButton>
        }
      </Grid> */}
      {
        // columns.map(item => (
        //   <TableColumn item={item}>
        //     {
        //       item.id === 'data_type' &&
        //         <Grid
        //           style={{
        //             width:50,
        //             display:'flex',
        //             alignItems:'center',
        //             justifyContent:'center',
        //             marginLeft:-10
        //           }}
        //         >
        //           {cloneElement(selectedIcons[customField.data_type], {color1:'#777'})}
        //         </Grid>
        //     }
        //     {
        //       item.id === 'actions' &&
        //         <VisibleSVG width={20} color1="#777" />
        //     }
        //     <Typography style={{ fontSize:'1rem'}}>{customField[item.id]}</Typography>
        //   </TableColumn>
        // ))
      }
    </Grid>
  )
}

const EditableDisplay = (props) => {
  const { contactId, fieldKey, fieldValue, active, onTextChange, onListChange, onNumberChange, onDateChange, onBoolChange, onImageChange, onFileChange, onRichTextChange, setEditing, showDelete, onClickRemove, customField, customFields } = props;
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    console.log('active', active);
    if (!active) {
      setEditing(fieldKey);
      setTimeout(() => {
        if (document.getElementById(fieldKey)) {
          document.getElementById(fieldKey).focus();
        }
      }, 0);
    }
  }


  const fieldName = customFields.filter(x => x.id === fieldKey )[0].field_name;

  return (
    <Grid
      id={`r-${fieldKey}`}
      container
    >
      <Grid
        container
        tabIndex="0"
        justifyContent="space-between"
        onClick={handleClick}
        // onKeyDown={(e) => { // <-- This is handled automatically by the onFocus
        //   if (e.key === "Enter") {
        //     handleClick()
        //   }
        // }}
        onFocus={handleClick}
        style={{
          background:hover ? '#eee' : 'transparent'
        }}
      >
        <Grid display="flex" alignItems="center">
          <Typography
            style={{
              marginLeft:16,
              marginRight:4,
              fontWeight:500,
              color: theme.palette.primary.main,
            }}
          >
            {fieldName}
          </Typography>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              copyString(fieldValue);
            }}
          >
            <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
          </IconButton>
          {
            active
              ?
                <IconButton
                  onClick={() => setEditing(null)}
                >
                  <CloseRoundedSVG width={15} color1={theme.palette.primary.dark} />
                </IconButton>
              :
                <IconButton
                  // onClick={() => {
                  //   setEditing(fieldKey);
                  //   setTimeout(() => {
                  //     setFocus();
                  //   }, 0);
                  // }}
                >
                  <EditSVG width={15} color1={theme.palette.primary.dark} />
                </IconButton>
          }
        </Grid>
        <Grid display="flex" alignItems="center">
          {
            showDelete &&
              <IconButton
                onClick={onClickRemove}
                style={{
                  width:35,
                  height:35,
                  // margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
                }}
              >
                <DeleteSVG width={12} color1={hover ? "#333" : "#aaa"} />
              </IconButton>
          }
        </Grid>


      </Grid>
      <Grid
        container
        style={{
          padding:'0 8px'
        }}
      >
        <InputFieldTypeDisplay
          id={fieldKey}
          contactId={contactId}
          customField={customField}
          customFields={customFields}
          value={fieldValue}
          onTextChange={onTextChange}
          onListChange={onListChange}
          onNumberChange={onNumberChange}
          onDateChange={onDateChange}
          onBoolChange={onBoolChange}
          onRichTextChange={onRichTextChange}
          onSelectChange={onTextChange}
          onFileChange={onFileChange}
          onImageChange={onImageChange}
          enableOnClick={handleClick}
          noAutoFocus
          margin
        />

        {/* <InputField
          id={fieldKey}
          // placeholder='Field Name'
          // label={fieldKey}
          value={fieldValue}
          onChange={onChange}
          // readOnly={!active}
          enableOnClick={handleClick}
          // width="calc(100% - 50px)"
          noAutoFocus
          margin
        /> */}
      </Grid>

      {
        // active
        //   ?
        //     <>
        //       <InputField
        //         // placeholder='Field Name'
        //         // label={fieldKey}
        //         value={fieldValue}
        //         onChange={onChange}
        //         // width="calc(100% - 50px)"
        //         margin
        //       />
        //     </>
        //   :
        //     <>
        //       <Typography>{fieldValue}</Typography>
        //     </>
      }
    </Grid>

  )
}

