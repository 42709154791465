import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import { useGetContact, useGetCustomFields } from '../hooks/mutations'
import Loading from '../components/ui/Loading'
import ContactEdit from '../components/contacts/ContactEdit'

const ContactEditPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const [contactId, setContactId] = useState(currentEnv === "STAGING" ? 1 : null);
  const [reload, setReload] = useState(false);
  const [reloadCustomFields, setReloadCustomFields] = useState();
  const customFields = useGetCustomFields(reloadCustomFields);

  // Get the script element by its id
  const scriptElement = document.getElementById('django-edit-contact');

  if (scriptElement && !contactId) {
    // Access the content of the script element
    const djangoContactString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoContact = JSON.parse(djangoContactString);

    // Set the contact id state from the JSON data
    setContactId(djangoContact.id)
  }

  const incomingContact = useGetContact(contactId, reload);

  // Reset 'reload' state
  useEffect(() => {
    if (reload) {
      console.log('reloading');
      setReload(false);
    }
  }, [reload])

  return (
    // <MainPageTemplate background="#f8f8f8" title="Contacts">
    incomingContact
      ? <ContactEdit customFields={customFields} incomingContact={incomingContact} setReload={setReload} setReloadCustomFields={setReloadCustomFields} />
      : <Loading />
    // </MainPageTemplate>
  )
}

export default ContactEditPage