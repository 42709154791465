import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import LoginStaging from '../components/auth/LoginStaging'
import { Button, Grid, Typography } from '@mui/material'

const NavPage = () => {
  return (
    <MainPageTemplate title="Welcome">
      {/* <LoginStaging /> */}
      <Grid
        container
        height="calc(80vh)"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>PAGES</Typography>
        {
          [
            {
              title:'Add Contact',
              href:'/add-contact'
            },
            {
              title:'Edit Contact',
              href:'/edit-contact'
            },
            {
              title:'Custom Fields',
              href:'/custom-fields'
            },
            {
              title:'Surveys',
              href:'/surveys'
            },
            {
              title:'Forms',
              href:'/forms'
            },
            {
              title:'Automation',
              href:'/automation'
            },
            {
              title:'CRM',
              href:'/crm'
            },
            {
              title:'CSV Upload',
              href:'/csv-upload'
            },
            {
              title:'Pricing',
              href:'/pricing'
            },
            {
              title:'Email Templates',
              href:'/email-templates'
            },
            {
              title:'Signup',
              href:'/signup'
            },
            {
              title:'Feedback Panel',
              href:'/feedback-panel'
            },
          ].map(item => (
            <LandingButton
              title={item.title}
              href={item.href}
            />
          ))
        }

        <Typography>SINGLES</Typography>
        {
          [
            {
              title:'TAG SINGLE',
              href:'/single-tag'
            },
          ].map(item => (
            <LandingButton
              title={item.title}
              href={item.href}
            />
          ))
        }
      </Grid>
    </MainPageTemplate>
  )
}

export default NavPage

const LandingButton = (props) => {
  const { title, href } = props;
  return (
    <Button
      href={href}
      variant="contained"
      style={{
        width:250,
        marginBottom:12
      }}
    >
      {title}
    </Button>
  )
}