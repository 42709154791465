import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { CheckmarkSVG } from '../../assets/icons';
import Step1Register from './Step1Register'
import Step2Login from './Step2Login'
import Step3Tenant from './Step3Tenant'
import Step4Subscribe from './Step4Subscribe'


// STEP 1: Register: Register, verify email
// STEP 2: Login: Login
// STEP 3: Add Company: Create Tenant
// STEP 4: Pick Subscription: Create payment session and subscribe via stripe

const SignupWizard = ({djangoState}) => {
  const [step, setStep] = useState(djangoState ? djangoState : 1);
  console.log('step', step)

  useEffect(() => {
    if (djangoState) {
      setStep(djangoState);
    }
  }, [djangoState]);

  return (
      <WizardGuide
        // triggers = {{
        //   csvExists: csvFile ? true : false,
        //   step_1: headers ? true : false,
        //   step_2: newCustomFields.length ? true : false || isConnectingFields,
        //   step_3: isConnectingFields,
        //   step_4: goToNew
        // }}
        // next = {{
        //   step_1: handleTrigger_Step_1,
        //   step_2: handleTrigger_Step_2,
        //   step_3: handleTrigger_Step_3,
        //   step_4: handleTrigger_Step_4,
        // }}
        setStep={setStep} // for dev only -- remove for production
        step={step}
        step_1 = { // REGISTER
          <Step1Register

          />
        }
        step_2 = { // LOGIN
          <Step2Login

          />
        }
        step_3 = { // ADD COMPANY
          <Step3Tenant

          />
        }
        step_4 ={ // PICK SUBSCRIPTION
          <Step4Subscribe

          />
        }
      />
  )
}

export default SignupWizard

const WizardGuide = (props) => {
  const { step, setStep, step_1, step_2, step_3, step_4 } = props;
  const theme = useTheme();

  // useEffect(() => {
  //   if (triggers.step_3) {
  //     setCurrentStep(4);
  //   } else if (triggers.step_2) {
  //     setCurrentStep(3);
  //   } else if (triggers.step_1) {
  //     setCurrentStep(2);
  //   } else {
  //     setCurrentStep(1);
  //   }
  // }, [triggers])

  // const goDirect = (pos) => {
  //   if (triggers[`step_${pos}`]) {
  //     setCurrentStep(pos);
  //   }
  // }

  // const triggerNext = () => {
  //   if (next[`step_${step}`]) {
  //     next[`step_${step}`]();
  //   } else {
  //     setCurrentStep(prev => prev + 1);
  //   }
  // }

  return (
    <Grid
      container
      style={{
        padding:8,
      }}
    >
      <Grid
        container
      >
        {
          [
            {label: '1', position: 1, description: 'Register'},  // Register, verify email
            {label: '2', position: 2, description: 'Login'},  // Login
            {label: '3', position: 3, description: 'Add Company'},  // Create Tenant
            {label: '4', position: 4, description: 'Pick Subscription'},  // Create payment session and subscribe via stripe
          ].map(stage => (
            <Grid
              item
              // onClick={() => setStep(stage.position)} // for development only -- remove for production
              style={{
                position:'relative',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                padding:'16px 16px 0 16px',
                cursor: step === stage.position ? 'pointer' : 'default'
                // borderBottom: step === stage.position ? `4px solid ${theme.palette.primary.main}` : `2px solid #ccc`
              }}
              // xs={12}
              xs
            >
              {/* <Grid
                style={{
                  position:'absolute',
                  top:'100%',
                  width: 'calc(100% - 32px)',
                  height: step === stage.position ? 4 : 0,
                  background: theme.palette.primary.main,
                  transition:'.2s'
                }}
              /> */}
                {
                  step === stage.position
                    ?
                      <CheckmarkSVG width={25} color1={step === stage.position ? theme.palette.primary.main : '#ccc'} />
                    :
                      <Grid
                        style={{
                          width:25,
                          height:25,
                          borderRadius:25,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          background: step === stage.position || step === stage.position ? theme.palette.primary.main : '#bbb'
                        }}
                      >
                        <Typography
                          style={{
                            fontSize:'.75rem',
                            textTransform:'uppercase',
                            fontWeight:700,
                            color:'#fff'
                          }}
                        >
                          {stage.label}
                        </Typography>
                      </Grid>
                }
              <Typography
                sx={{
                  display:{xs:'none', sm: 'inherit'},
                  fontSize:'.95rem',
                  fontWeight:600,
                  color: step === stage.position ? theme.palette.primary.main : '#bbb'
                  // textTransform:'uppercase',
                }}
              >
                {stage.description}
              </Typography>
            </Grid>
          ))

        }
      </Grid>

      <Grid
        container
        style={{
          // border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 8,
          marginTop:24,
          padding: 24,
        }}
      >
        {
          // HEADER
        }
        {/* <Grid
          container
          style={{
            justifyContent:'space-between',
            marginBottom:16
          }}
        >
          <Button
            onClick={() => setCurrentStep(prev => prev - 1)}
            disabled={step <= 1}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            id="next-import-button"
            disabled={!triggers.csvExists}
            onClick={triggerNext}
            variant={!triggers.csvExists ? "outlined" : "contained"}
          >
            {step === 4 ? 'Continue' : 'Next'}
          </Button>
        </Grid> */}
        {step == 1 && step_1}
        {step == 2 && step_2}
        {step == 3 && step_3}
        {step == 4 && step_4}
      </Grid>
    </Grid>
  )
}
