import React, { useEffect, useState, cloneElement } from 'react'
import FormBuilder1 from './form-1/FormBuilder1'
import FormDisplayStartCollect from './form-1/FormDisplayStartCollect';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useGetSurveys } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { surveyColumns } from '../../lib/Data';
import { Link } from 'react-router-dom';
import { deleteSurvey } from '../../hooks/mutations';

const Surveys = () => {
  const [reset, setReset] = useState(true);
  const incomingSurveys = useGetSurveys({reset:reset});
  const screenSize = useScreenSize();
  const [surveys, setSurveys] = useState(incomingSurveys);
  const [resetSearch, setResetSearch] = useState(true);
  const theme = useTheme();

  // Reset 'reset' state to false after it is triggered
  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset])

  // Set surveys state to incoming data on load
  useEffect(() => {
    if (incomingSurveys) {
      setSurveys(incomingSurveys);
      setResetSearch(false);
    }
  }, [incomingSurveys]);

  const handleDeleteClick = async (surveyId) => {
    const deleted = await deleteSurvey(surveyId)
    if (deleted.status === 'success') {
      setReset(true); // <-- Trigger api call to get surveys again
    } else {
      console.log('NOT DELETED!');
    }
  };

  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        style={{
          background:'#fff',
          borderRadius: 8,
          padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          transition:'.4s',
          maxWidth:1600
        }}
      >
        <Grid
          container
          style={{
            justifyContent:'space-between',
            marginBottom:16
          }}
        >
          <Typography
            sx={{
              fontSize:'1.8rem',
              fontWeight:600,
              color:'#ccc',
            }}
          >
            Surveys
          </Typography>
          <Grid
            style={{
              display:'flex',
              gap:16
            }}
          >
            <ReusableSearch
              useSearch
              placeholder="Search Surveys"
              content={incomingSurveys}
              setContent={setSurveys}
              searchFields={['name']}
            />
            <ButtonCustom
              href="/surveys-create"
              iconStart={<AddSVG width={16} color1="#fff" />}
              text={ screenSize.sm ? null : "New Survey"}
            />
          </Grid>
        </Grid>

        <HeaderRow />
        <TableWrapper>
          {
            surveys
              ?
                surveys.map((survey, index) => (
                  <TableRow
                    survey={survey}
                    index={index}
                    key={survey.id}
                    onDeleteClick={() => handleDeleteClick(survey.id)}
                  />
                ))
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper>
      </Grid>

    </Grid>
  )
}

export default Surveys

const HeaderRow = (props) => {
  const { align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16,
        borderRadius:'4px 4px 0 0'
      }}
    >
      {
        surveyColumns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        maxHeight:200
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { survey, index, onDeleteClick } = props;

  return (
    <Grid
      container
      style={{
        background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'12px 32px',
      }}
    >
      {
        surveyColumns.map(item => (
          <TableColumn item={item}>
            {
              // item.id === 'data_type' &&
              //   <Grid
              //     style={{
              //       width:50,
              //       display:'flex',
              //       alignItems:'center',
              //       justifyContent:'center',
              //       marginLeft:-10
              //     }}
              //   >
              //     {cloneElement(selectedIcons[survey.data_type], {color1:'#777'})}
              //   </Grid>
            }
            {
              item.id === 'actions' &&
                <>
                  <Link
                    to={`/surveys-take/${survey.id}`}
                    style={{
                      textDecoration:'none',
                      color:'inherit'
                    }}
                  >
                    <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                    >
                      <VisibleSVG width={20} color1="#777" />
                    </Grid>
                  </Link>
                  <Link
                    to={`/surveys-update/${survey.id}`}
                    style={{
                      textDecoration:'none',
                      color:'inherit'
                    }}
                  >
                    <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                    >
                      <EditSVG width={17} color1="#777" />
                    </Grid>
                  </Link>

                  <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                      onClick={onDeleteClick}
                  >
                    <DeleteSVG width={13} color1="#777" />
                  </Grid>
                  
                </>
            }
            <Typography style={{ fontSize:'1rem'}}>{survey[item.id]}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}