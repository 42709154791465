import React, { useEffect } from 'react'

const Step2Login = () => {
  const moveIt = () => {
    // Login div to place inside of step-2-container
    const loginDiv = document.getElementById('login');
    console.log('loginDiv', loginDiv)

    // Step-2-container element
    const step2 = document.getElementById('step-2-container');
    console.log('step2', step2)
  
    if (loginDiv && step2) {
      step2.append(loginDiv);
    }
  }

  useEffect(() => {
    moveIt();
  }, [])

  
  return (
    <div id="step-2-container">Step2Login</div>
  )
}

export default Step2Login