import React, { useState } from 'react'
import { Grid } from '@mui/material';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';

const LoginStaging = () => {
  const [forgot, setForgot] = useState(false);
  
  return (
    <>
      <Grid
        container
        sx={{
          height:'80vh',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
        <Grid
          item
          zIndex={1}
          xs={12}
          sm={10}
          md={8}
          lg={6}
        >
          {
            forgot
              ? <ResetPasswordForm setForgot={setForgot} />
              : <LoginForm setForgot={setForgot} />
          }
        </Grid>

      </Grid>

    </>
  )
}

export default LoginStaging