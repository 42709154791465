import { useTheme } from "@emotion/react";
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FileDropZone from "./FileDropZone";
import RichTextEditor from "./RichTextEditor";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CloseFilledSVG, DeleteSVG, DownloadSVG, FileSVG, FlipSVG } from "../../assets/icons";
import { deleteStorageFile, putCustomField, putStorageFile, useGetStorageFile } from "../../hooks/mutations";
import { selectedIcons } from "../custom_fields/CustomFields";
import { timeDifference } from "../../lib/Functions";

export const InputField = (props) => {
  const { id, placeholder, value, onChange, label, margin, marginAmount, notFull, width, readOnly=false, enableOnClick, noAutoFocus, customInputProps, infotip, sx, ...other } = props;
  const theme = useTheme();

  return (
    <>
      {
        label &&
          <Typography
            style={{
              margin:'0 0 2px 16px',
              fontWeight:500,
              color: theme.palette.primary.main,
              display:'flex',
              alignItems:'center',
              gap:8
            }}
          >
            {label} {infotip}
          </Typography>
      }
      <TextField
        id={id}
        onClick={enableOnClick}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        variant='outlined'
        autoFocus={noAutoFocus ? false : true}
        fullWidth={notFull ? false : true}
        // disabled={disabled}
        inputProps={{
          style:{
            padding:0,
            background:'transparent'
          }
        }}
        InputProps={{
          disableUnderline:true,
          readOnly: readOnly,
          sx:{
            padding:readOnly ? "0px 16px" : "16px 16px",
            background: readOnly ? 'transparent' : '#fff',// `${theme.palette.primary.light}10}`, //'#fff',
            transition: '.3s',
            borderRadius:1,
            // "&& .MuiInputBase-input.Mui-disabled": {
            //   WebkitTextFillColor: '#777' // theme.palette.primary.main,
            // }
          },
          ...customInputProps
        }}
        sx={{
          // marginBottom: margin ? 24 : 0,
          ...sx,
          margin: margin || marginAmount ? (marginAmount ? marginAmount : '0 0 24px 0') : 0,
          borderRadius:1,
          boxShadow:readOnly ? null : '0 0 5px #00000030',
          padding:0,
          width:  width ? width : '100%', //'12px 32px'
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.light,
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'yellow',
            // },
          },
        }}
        {...other}
      />
    </>
  )
}

export const TimeField = (props) => {
  const { placeholder, value, onChange, label, margin, display={days:true, hours:true, minutes:true}, ...other } = props;
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    onChange(handleTime());
  }, [days, hours, minutes])

  const handleTime = () => {
    const dayHours = Number(days ? days*24 : 0);
    const hourHours = Number(hours ? hours : 0);
    const minuteHours = Number(minutes ? minutes/60 : 0);
    const totalHours = dayHours + hourHours + minuteHours;
    const totalHoursRounded = Math.round((totalHours + Number.EPSILON) * 100) / 100
    return totalHoursRounded;
  }

  return (
    <>
      {
        label &&
          <Typography
            style={{
              margin:'0 16px',
              fontWeight:600,
              color: theme.palette.primary.main
            }}
          >
            {label}
          </Typography>
      }
      <Grid
        container
        alignItems="center"
      >
        {
          display.days &&
          <>
            <InputField
              placeholder="0"
              value={days}
              onChange={(e) => setDays(e.target.value)}
              width={80}
              type="number"
            />
            <Typography style={{margin:'0 32px 0 8px'}}>Days</Typography>
          </>
        }
        {
          display.hours &&
          <>
            <InputField
              placeholder="0"
              value={hours}
              onChange={(e) => setHours(e.target.value)}
              width={80}
              type="number"
            />
            <Typography style={{margin:'0 32px 0 8px'}}>Hours</Typography>
          </>
        }
        {
          display.minutes &&
          <>
            <InputField
              placeholder="0"
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
              width={80}
              type="number"
            />
            <Typography style={{margin:'0 32px 0 8px'}}>Minutes</Typography>
          </>
        }
      </Grid>
    </>
  )
}

export const NumberField = (props) => {
  const { placeholder, value, onChange, label, margin, marginAmount, notFull, width, pre, post, ...other } = props;
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const theme = useTheme();

  return (
    <>
      {
        label &&
          <Typography
            style={{
              margin:'0 16px',
              fontWeight:600,
              color: theme.palette.primary.main
            }}
          >
            {label}
          </Typography>
      }
      {
        pre && <Typography style={{margin:'0 32px 0 8px'}}>{pre}</Typography>
      }
      <TextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        variant='standard'
        type="number"
        autoFocus
        fullWidth={notFull ? false : true}
        inputProps={{
          style:{
            padding:0,
          }
        }}
        InputProps={{
          disableUnderline:true,
          style:{
            padding:"16px 16px",
            background:'#fff',
            borderRadius:8,
          }
        }}
        style={{
          // marginBottom: margin ? 24 : 0,
          margin: margin || marginAmount ? (marginAmount ? marginAmount : '0 0 24px 0') : 0,
          borderRadius:8,
          boxShadow:'0 0 5px #00000030',
          padding:0,
          width: width ? width : 'inherit' //'12px 32px'
        }}
        {...other}
      />
      {
        post && <Typography style={{margin:'0 32px 0 8px'}}>{post}</Typography>
      }

    </>
  )
}

export const EmailField = (props) => {

  return (
    <InputField
      {...props}
      type="email"
    />
  )
}

export const LinkField = (props) => {
  const {  } = props;

  return (
    <InputField
      {...props}
      type="url"
    />
  )
}

export const ListField = (props) => {
  const { options, value, field, onChange } = props;
  const theme = useTheme();
  const [newOption, setNewOption] = useState(null);
  const [isAddingOption, setIsAddingOption] = useState(false);
  // const [newOptionIsUnique, setNewOptionIsUnique] = useState(true);

  // console.log('field', field)

  const handleClick = (option) => {
    if (value.includes(option)) {
      // Remove option from value array
      const updated = value.filter(x => x !== option);
      const e = {target: {value: updated}};
      onChange(updated)
    } else {
      // Add option to value array
      const e = {target: {value: [...value, option]}};
      onChange([...value, option])
    }
  }

  const saveOption = async () => {
    if (options.map(x => x.toLowerCase()).includes(newOption.toLowerCase())) {
      console.log('included already')
      return;
    }

    const customFieldObj = {
        customFieldId: field.id,
        payload: {
          ...field,
          enum_values: [ ...new Set([ ...field.enum_values, newOption ]) ]
      }
    }
    const posted = await putCustomField(customFieldObj);
    if (posted.status === 'success') {
      console.log('success saving option', posted.data);

      // Add option to value array
      const e = {target: {value: [ ...new Set([ ...value, newOption ]) ] }};
      console.log('new value array', e.target.value);
      onChange(e.target.value)

      setIsAddingOption(false);
      setNewOption(null);
    }
    // console.log(`posted ${customField.field_name}:`, posted);
  }

  return (
    // <InputField
    //   {...props}
    // />
    <FieldWrapper background="#FFF" innerPadding {...props}>
      <Grid
        container
      >
        {
          options.map((option, index) => {
            const selected = value.includes(option);
            return (
              <Grid
                item
                style={{
                  padding:4,
                }}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Button
                  color={ selected ? "primary" : "mid" }
                  onClick={() => handleClick(option)}
                  variant={ selected ? "contained" : "outlined" }
                  style={{
                    // border:`1px solid ${theme.palette.light.light}`,
                    // padding:'4px 16px',
                    overflow:'hidden',
                    textOverflow:'ellipsis',
                    whiteSpace:'nowrap',
                    justifyContent:'flex-start',
                    borderRadius:4     
                  }}
                  fullWidth
                >
                  {option}
                </Button>
              </Grid>
            )
          })
        }
        <Grid
          item
          style={{
            padding:4
          }}
          xs
        >
          {
            isAddingOption
              ?
                <TextField
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      saveOption()
                    } else if (e.key === "Escape") {
                      setIsAddingOption(false)
                    }  
                  }}
                  onBlur={() => setIsAddingOption(false)}
                  autoFocus
                  style={{
                    padding:0,
                    margin:0
                  }}
                  fullWidth
                  inputProps={{
                    style:{
                      padding:8,
                      height:'100%',
                      background:'transparent'
                    }
                  }}          
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <Button
                        onClick={saveOption}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        Add
                      </Button>
                    </InputAdornment>,
                  }}
                />
              :
                <Button
                  // color={ selected ? "primary" : "mid" }
                  onClick={() => setIsAddingOption(true)}
                  // variant={ selected ? "contained" : "outlined" }
                  style={{
                    // border:`1px solid ${theme.palette.light.light}`,
                    // padding:'4px 16px',
                    borderRadius:4     
                  }}
                  fullWidth
                >
                  Add Option
                </Button>
          }
        </Grid>

      </Grid>
    </FieldWrapper>
  )
}

export const SelectField = (props) => {
  const { options, onChange, value } = props;

  const handleChange = (e) => {
    console.log('e', e.target.value)
    onChange(e)
  }

  return (
    <InputField
      {...props}
      onChange={handleChange}
      select
    >
      {options?.map((option, index) => (
        <MenuItem
          key={option}
          value={option}
        >
          {/* <ListItemIcon>
            {cloneElement(value.icon, { width: key === 'phone_number' || key === "file" ? 16 : (key === 'number' || key === 'large_text_field' ? 24 : 20),  })}
          </ListItemIcon> */}

          {option}

        </MenuItem>
      ))}

    </InputField>


  )
}

export const ImageField = (props) => {
  const { value, onChange } = props;
  const [reset, setReset] = useState();
  const image = useGetStorageFile({folderId:1, fileId:value, fileType:'image', reload: reset});
  const [changeImage, setChangeImage] = useState(false);

  // Load image if value (image id) changes. This will only happen on a new file upload.
  useEffect(() => {
    if (value) {
      setReset(true);
    }
  }, [value])

  // Reset the reset value to false after it is set to true.
  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset])

  // Change changeImage state after image is replaced successfully.
  useEffect(() => {
    if (image) {
      keepImage();
    }
  }, [image])

  const handleChange = async (e) => {
    if (value) {
      // Save file to storage
      const fileName = e.file.name;
      const formData = new FormData();
      formData.append('file', e.file);
      formData.append('name', fileName);
      formData.append('folder', 1);

      const storageFileObj = {
        folderId: 1,
        fileId: value,
        storageFilePayload: formData
      }

        // Replace the file, keeping the same file id
        const storedImage = await putStorageFile(storageFileObj);
        console.log('storedImage', storedImage)
        if (storedImage.status === "success") {
          setReset(true);
        }

    } else {
      onChange(e);
    }
  }

  const removeImage = async () => {
    if (image) {
      console.log('image in remove', image);
      const deleted = await deleteStorageFile({fileId: image.id});
      console.log('deleted', deleted);
    } else {
      onChange(null);
    }
  }

  const replaceImage = () => {
    setChangeImage(true);
  }

  const keepImage = () => {
    setChangeImage(false);
  }


  return (
    <FieldWrapper background="#FFF" innerPadding {...props}>
        {
          image
            ?
              <Grid
                container
                sx={{
                  flexDirection:{xs:'column', sm:'row'},
                  alignItems:'center', //{xs:'column', sm:'row'}
                  gap:{xs:1, sm:0}
                }}
              >
                <Grid
                  // onMouseEnter={() => setHover(true)}
                  // onMouseLeave={() => setHover(false)}
                  // onClick={() => setHover(prev => !prev)}
                  // container
                  style={{
                    position:'relative',
                    height:150,
                    borderRadius:6,
                    overflow:'hidden'
                  }}
                >
                  <div
                    onClick={keepImage}
                    style={{
                      position:'absolute',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      width:'100%',
                      height:'100%',
                      background:'#00000090',
                      display:changeImage ? 'flex' : 'none',
                      cursor:'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color:'#fff',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        textTransform:'uppercase',
                        fontWeight:600
                      }}
                    >
                      Keep this file
                    </Typography>

                  </div>
                  { 
                    image?.file
                      ?
                        <img
                          src={image?.file}
                          style={{
                            // width:'auto',
                            height:'100%',
                            objectFit:'contain',
                          }}
                        />
                      :
                        <CircularProgress />
                  }
                </Grid>
                <Grid
                  item
                  style={{
                    padding:'0 24px'
                  }}
                  xs
                >
                  {
                    changeImage
                      ?
                        <FileDropZone setFile={handleChange} />
                      :
                        <Grid
                          container
                          style={{
                            flexDirection:'column',
                            gap:8
                          }}
                        >
                          <Typography>{image?.name}</Typography>
                          <Button
                            onClick={replaceImage}
                            variant="outlined"
                          >
                            Replace File
                          </Button>
                          <Button
                            onClick={removeImage}
                            variant="outlined"
                            color="error"
                          >
                            Delete File
                          </Button>
                        </Grid>
                  }
                </Grid>
              </Grid>
            :
              <FileDropZone setFile={handleChange} />
        }
    </FieldWrapper>

  )
}

export const FileField = (props) => {
  const { value, onChange } = props;
  const [reset, setReset] = useState();
  const file = useGetStorageFile({folderId:1, fileId:value, fileType:'file', reload: reset});
  const [changeFile, setChangeFile] = useState(false);
  const theme = useTheme();

  // Load file if value (file id) changes. This will only happen on a new file upload.
  useEffect(() => {
    if (value) {
      setReset(true);
    }
  }, [value])

  // Reset the reset value to false after it is set to true.
  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset])

  // Change changeFile state after file is replaced successfully.
  useEffect(() => {
    if (file) {
      keepFile();
    }
  }, [file])

  const handleChange = async (e) => {
    if (value) {
      // Save file to storage
      const fileName = e.file.name;
      const formData = new FormData();
      formData.append('file', e.file);
      formData.append('name', fileName);
      formData.append('folder', 1);

      const storageFileObj = {
        folderId: 1,
        fileId: value,
        storageFilePayload: formData
      }

        // Replace the file, keeping the same file id
        const storedFile = await putStorageFile(storageFileObj);
        console.log('storedFile', storedFile)
        if (storedFile.status === "success") {
          setReset(true);
        }

    } else {
      onChange(e);
    }
  }

  const removeFile = async () => {
    if (file) {
      console.log('file in remove', file);
      const deleted = await deleteStorageFile({fileId: file.id});
      console.log('deleted', deleted);
    } else {
      onChange(null);
    }
  }

  const replaceFile = () => {
    setChangeFile(true);
  }

  const downloadFile = () => {
    const a = document.createElement('a')
    a.href = file.file
    a.download = file.name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  
  }

  const keepFile = () => {
    setChangeFile(false);
  }


  return (
    <FieldWrapper background="#FFF" innerPadding {...props}>
        {
          file
            ?
              <Grid
                container
                sx={{
                  flexDirection:{xs:'column', sm:'row'},
                  alignItems:'center', //{xs:'column', sm:'row'}
                  gap:{xs:1, sm:0}
                }}
              >
                <Grid
                  // onMouseEnter={() => setHover(true)}
                  // onMouseLeave={() => setHover(false)}
                  // onClick={() => setHover(prev => !prev)}
                  // container
                  style={{
                    position:'relative',
                    height:150,
                    borderRadius:6,
                    overflow:'hidden'
                  }}
                >
                  <div
                    onClick={keepFile}
                    style={{
                      position:'absolute',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      width:'100%',
                      height:'100%',
                      background:'#00000090',
                      display:changeFile ? 'flex' : 'none',
                      cursor:'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color:'#fff',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        textTransform:'uppercase',
                        fontWeight:600
                      }}
                    >
                      Keep this file
                    </Typography>

                  </div>
                  { 
                    file?.file
                      ?
                        <div style={{padding:16, height:'100%'}}>
                          <FileSVG height="100%" color1={theme.palette.primary.main} style={{height:'calc(100% - 32px)'}} />
                        </div>
                      :
                        <CircularProgress />
                  }
                </Grid>
                <Grid
                  item
                  style={{
                    padding:'0 24px'
                  }}
                  xs
                >
                  {
                    changeFile
                      ?
                        <FileDropZone setFile={handleChange} />
                      :
                        <Grid
                          container
                          style={{
                            flexDirection:'column',
                            gap:8
                          }}
                        >
                          <Typography>{file?.name}</Typography>
                          <Button
                            onClick={replaceFile}
                            variant="outlined"
                            color="info"
                            startIcon={<FlipSVG height={15} color1={theme.palette.info.dark} />}
                          >
                            Replace File
                          </Button>
                          <Button
                            onClick={downloadFile}
                            // href={file.file}
                            // download={file.name}
                            variant="outlined"
                            startIcon={<DownloadSVG height={17} color1={theme.palette.primary.dark} />}
                          >
                            Download File
                          </Button>
                          {/* <Button
                            onClick={removeFile}
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteSVG height={15} color1={theme.palette.error.dark} />}
                          >
                            Delete File
                          </Button> */}
                        </Grid>
                  }
                </Grid>
              </Grid>
            :
              <FileDropZone setFile={handleChange} />
        }
    </FieldWrapper>

  )
}

export const RichTextField = (props) => {
  const {  } = props;

  return (
    <FieldWrapper background="#FFF" {...props}>
      <RichTextEditor {...props} />
    </FieldWrapper>
  )
}

export const DateField = (props) => {
  const { id, placeholder, value, onChange, label, margin, marginAmount, notFull, width, readOnly=false, enableOnClick, noAutoFocus, ...other } = props;
  const theme = useTheme();

  function formatDate(inputDate) {
    // Parse the input date string into a Date object
    const parts = inputDate.split('/');
    const month = parseInt(parts[0], 10);
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month - 1, day); // Month is 0-indexed in Date object

    if (date != "Invalid Date") {
      // Format the date into the desired output format
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
  
      return formattedDate;
    }

  }
  
  return (
    <FieldWrapper background="#FFF" {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid
          container
          columnGap={2}
          style={{
            // justifyContent:'center',
            alignItems:'center',
            padding:'0 16px'
          }}
        >
          {/* <Grid
            item
            xs={12}
            sm={12}
          > */}
            <DatePicker
              id={id}
              // onClick={enableOnClick}
              placeholder={placeholder}
              value={new Date(value)}
              onChange={onChange}
              variant='standard'
              // inputFormat="MM/DD/YYYY"
              autoFocus={noAutoFocus ? false : true}
              // disabled={disabled}
              inputProps={{
                style:{
                  padding:0,
                  background:'transparent'
                }
              }}
              InputProps={{
                disableUnderline:true,
                readOnly: readOnly,
              }}
            />
          {/* </Grid> */}
          <Typography

          >
            {formatDate(value)}
          </Typography>
          <Typography

          >
            {timeDifference(new Date(value))}
          </Typography>
          <Button
            onClick={() => onChange('')}
          >
            Clear Date
          </Button>
        </Grid>
      </LocalizationProvider>

    </FieldWrapper>
  )
}

export const BoolField = (props) => {
  const { value, onChange } = props;

  return (
    <FieldWrapper innerPadding background="#fff" {...props}>
      <FormControlLabel
        {...props}
        onChange={onChange}
        checked={value}
        style={{
          padding:'0 8px'
        }}
        control={<Checkbox {...props} />}
      />
    </FieldWrapper>
  )
}

export const PhoneField = (props) => {
  const { value, onChange } = props;
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (event) => {
    let input = event.target.value;
    
    // Remove all non-numeric characters
    input = input.replace(/\D/g, '');

    // Take only the first 10 digits
    input = input.slice(0, 10);

    // Apply phone number formatting
    let formattedNumber = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
    
    setPhoneNumber(formattedNumber);
    const e = {target: {value: formattedNumber}};
    onChange(e);
  };

  return (
    <InputField
      {...props}
      value={phoneNumber}
      onChange={handlePhoneNumberChange}
      type="email"
      customInputProps={{
        startAdornment: <InputAdornment position="start">+1</InputAdornment>,
      }}

    />
  )
}

export const InputFieldTypeDisplay = (props) => {
  const {
    id,
    type,
    customField,
    customFields,
    onTextChange,
    onListChange,
    onNumberChange,
    onDateChange,
    onFileChange,
    onImageChange,
    onRichTextChange,
    onBoolChange,
    onSelectChange,
    enableOnClick,
    noAutoFocus,
    margin
  } = props;

  const getFieldType = () => {
    const fieldType = customFields.filter(x => x.id === id)[0];

    if (fieldType?.data_type) {
      return {type: fieldType.data_type, options: fieldType.enum_values, field: fieldType}
    }
    
  }

  const fieldType = type ? type : getFieldType();
  
  switch (fieldType?.type) {
    case 'email': return <EmailField {...props} onChange={onTextChange} />;
    case 'number': return <NumberField {...props} onChange={onNumberChange} />;
    case 'text': return <InputField {...props} onChange={onTextChange} />;
    case 'image_file': return <ImageField {...props} onChange={onImageChange} />;
    case 'url': return <LinkField {...props} onChange={onTextChange} />;
    case 'large_text_field': return <RichTextField {...props} onChange={onRichTextChange} fieldName={fieldType.field.field_name} />;
    case 'file': return <FileField {...props} onChange={onFileChange} />;
    case 'date': return <DateField {...props} onChange={onDateChange} />;
    case 'bool': return <BoolField {...props} onChange={onBoolChange} />;
    case 'list': return <ListField {...props} onChange={onListChange} options={fieldType.options} field={fieldType.field} />;
    case 'enum': return <SelectField {...props} onChange={onTextChange} options={fieldType.options} />;
    case 'phone_number': return <PhoneField {...props} onChange={onTextChange} />;
  
    default:
      break;
  }
}

const FieldWrapper = (props) => {
  const { children, innerPadding, background } = props;

  return (
    <div
      style={{
        margin: props.margin || props.marginAmount ? (props.marginAmount ? props.marginAmount : '0 0 24px 0') : 0,
        borderRadius:4,
        boxShadow:props.readOnly ? null : '0 0 5px #00000030',
        background: background ? background : 'transparent',
        padding: innerPadding ? 8 : 0,
        width:  props.width ? props.width : '100%' //'12px 32px'
      }}
    >
      {children}
    </div>

  )
}