import React from 'react'
import RegisterForm from '../auth/RegisterForm'
import Register from '../auth/Register'

const Step1Register = () => {
  return (
    <RegisterForm />
  )
}

export default Step1Register