import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/authContext';
import axios from 'axios';
import { TempProvider } from './context/tempContext';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import ContactCreatePage from './pages/ContactCreatePage';
import CustomFieldsPage from './pages/CustomFieldsPage';
import SurveysPage from './pages/SurveysPage';
import CRMPage from './pages/CRMPage';
import CsvUploadPage from './pages/CsvUploadPage';
import ContactEditPage from './pages/ContactEditPage';
import LandingPage from './pages/LandingPage';
import TagSingle from './singles/TagSingle';
import FeedbackPopout from './components/feedback/FeedbackPopout';
import FeedbackPanelPage from './pages/FeedbackPanelPage';
import EmailTemplatesPage from './pages/EmailTemplatesPage';
import EmailBuilderPage from './pages/EmailBuilderPage';
import SignupPage from './pages/SignupPage';

// // replace console.* for disable log on production
// if (process.env.REACT_APP_CURRENT_ENV === 'LIVE') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

axios.defaults.withCredentials = true; // even for get requests if
// demand session authentication
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'

// ALL REACT PAGES (FOR TESTING)
const rootElement = document.getElementById('root');
if (rootElement != null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

// INDIVIDUAL REACT PAGES (FOR PRODUCTION)
const landingRootElement = document.getElementById('landing_root');
if(landingRootElement != null) {
  const landingRoot = ReactDOM.createRoot(landingRootElement);
  landingRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <LandingPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const addContactRootElement = document.getElementById('add_contact_root');
if(addContactRootElement != null) {
  const addContactRoot = ReactDOM.createRoot(addContactRootElement);
  addContactRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <ContactCreatePage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

// INDIVIDUAL REACT PAGES (FOR PRODUCTION)
const editContactRootElement = document.getElementById('edit_contact_root');
if(editContactRootElement != null) {
  const editContactRoot = ReactDOM.createRoot(editContactRootElement);
  editContactRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <ContactEditPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const customFieldRootElement = document.getElementById('custom_field_root');
if(customFieldRootElement != null) {
  const customFieldRoot = ReactDOM.createRoot(customFieldRootElement);
  customFieldRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <CustomFieldsPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const emailTemplateRootElement = document.getElementById('edit_email_template_root');
if(emailTemplateRootElement != null) {
  const emailTemplateRoot = ReactDOM.createRoot(emailTemplateRootElement);
  emailTemplateRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <EmailBuilderPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const surveysRootElement = document.getElementById('surveys_root');
if(surveysRootElement != null) {
  const surveysRoot = ReactDOM.createRoot(surveysRootElement);
  surveysRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <SurveysPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const crmRootElement = document.getElementById('crm_root');
if(crmRootElement != null) {
  const crmRoot = ReactDOM.createRoot(crmRootElement);
  crmRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <CRMPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const csvUploadRootElement = document.getElementById('csv_upload_root');
if(csvUploadRootElement != null) {
  const csvUploadRoot = ReactDOM.createRoot(csvUploadRootElement);
  csvUploadRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <CsvUploadPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const signupRootElement = document.getElementById('signup_root');
if(signupRootElement != null) {
  const signupRoot = ReactDOM.createRoot(signupRootElement);
  signupRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <SignupPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const registerRootElement = document.getElementById('register_root');
if(registerRootElement != null) {
  const registerRoot = ReactDOM.createRoot(registerRootElement);
  registerRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <SignupPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const tenantRootElement = document.getElementById('tenant_root');
if(tenantRootElement != null) {
  const tenantRoot = ReactDOM.createRoot(tenantRootElement);
  tenantRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <SignupPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const pricingRootElement = document.getElementById('pricing_root');
if(pricingRootElement != null) {
  const pricingRoot = ReactDOM.createRoot(pricingRootElement);
  pricingRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <SignupPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const feedbackRootElement = document.getElementById('feedback_root');
if(feedbackRootElement != null) {
  const feedbackRoot = ReactDOM.createRoot(feedbackRootElement);
  feedbackRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <FeedbackPopout />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}

const feedbackPanelRootElement = document.getElementById('feedback_panel_root');
if(feedbackPanelRootElement != null) {
  const feedbackPanelRoot = ReactDOM.createRoot(feedbackPanelRootElement);
  feedbackPanelRoot.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <FeedbackPanelPage />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}


// REUSABLE REACT MINI COMPONENTS
const tagSingleElement = document.getElementById('tag_single');
if(tagSingleElement != null) {
  const tagSingle = ReactDOM.createRoot(tagSingleElement);
  tagSingle.render(
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <TempProvider>
              <ThemeProvider theme={theme}>
                <TagSingle />
              </ThemeProvider>
            </TempProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ROOT LOG FOR EASY DEFINITION
// root: Main App
// landing_root: Main Landing Page
// add_contact_root: To create a new contact (used in subscriber_form)
// edit_contact_root: To edit a contact (used in subscriber_details)
// custom_field_root:
// surveys_root:
// crm_root:
// csv_upload_root:
// signup_root: 