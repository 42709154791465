import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, Grid, IconButton, Menu, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import ReusableModal from '../ui/ReusableModal';
import ReusableSearch from '../ui/ReusableSearch';
import { CopySVG, EditSVG, FilterSVG, MoreMenuSVG } from '../../assets/icons';
import { transformFields, translateFieldType } from '../../lib/Functions';
import { initHeaderColumns } from '../../lib/Data';
import { getStorageFile, useGetStorageFile } from '../../hooks/mutations';

// const initHeaderColumns = [
//   // {label:'Name', field:'name', columnType:'standard'},
//   {label:'Email', field:'primary_email', columnType:'standard'},
//   {label:'Active', field:'is_active', columnType:'standard'},
// ]

const ContactsDisplayGrid = (props) => {
  const { setSelectedContact, contacts, headerColumns, selectedContacts, setSelectedContacts, setActionType, open, setOpen, setFocusField, handleFilterClick, filterOn } = props;
  const [currentContact, setCurrentContact] = useState(null);
  // const [selected, setSelected] = useState([]);
  // const [selectedColumns, setSelectedColumns] = useState(null);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [displayColumns, setDisplayColumns] = useState(initHeaderColumns);
  // const [headerColumns, setHeaderColumns] = useState(initHeaderColumns);
  const [hover, setHover] = useState(null);

  const parentRef = useRef(null);
  const theme = useTheme();

  // console.log('displayColumns', displayColumns);

  const handleFocusLeave = () => {
    // Your function logic here
    // console.log('Focus has left the parent and its children.');

    // console.log('contacts', contacts);
  };

  useEffect(() => {
    if (contacts) {
      sortContacts();
      if (currentContact) {
        updateCurrentContact();
      }
      // generateHeaderColumns();
    }
  }, [contacts])

  useEffect(() => {
    if (parentRef) {
      handleFocusLeave();
    }
  }, [parentRef]);

  // Update currently selected contact if contacts state changes
  const updateCurrentContact = () => {
    const newContacts = [ ...contacts ];
    const currentItem = newContacts.filter(x => x.id === currentContact.id);
    setCurrentContact(currentItem);
  }

  const handleFocus = (contact) => {
    setCurrentContact(contact);
    setSelectedContact(contact);
  }

  // // Create header column list based on incoming contact fields
  // const generateHeaderColumns = () => {
  //   const headers = transformFields(contacts);
  //   setHeaderColumns(headers);
  // } 

  // Sort contacts
  const sortContacts = () => {
    const newContacts = [...contacts];
    // const sorted = newContacts.filter(x => x.status === "Make Call");
    // console.log('sorted', sorted);
    // const unsorted = newContacts.filter(x => x.status != "Make Call");
    // console.log('unsorted', unsorted);

    setSortedContacts(newContacts);

  }

  // Determine which columns will be used
  const handleColumnDisplay = (col) => {
    let newColumns = [];
  
    if (displayColumns.some(column => column.field === col.field)) {
      // Column is already in displayColumns, remove it
      newColumns = displayColumns.filter(x => x.field !== col.field);
    } else {
      // Column is not in displayColumns, add it
      newColumns = [...displayColumns, col];
    }
  
    setDisplayColumns(newColumns);
  };
    
  // Select or unselect ONE contact from the display grid and add/remove from selectedContacts array
  const handleSingleSelect = (contact) => {
    if (selectedContacts[0] != contact) {
      setSelectedContacts([contact]);
    }
    setSelectedContact(contact);
    setCurrentContact(contact);
  }

  // Select or unselect ONE contact from the display grid and add/remove from selectedContacts array
  const handleDoubleClick = (contact) => {
    setSelectedContact(contact);
    setCurrentContact(contact);
    setOpen(true);
  }


  // Select or unselect contacts from the display grid and add/remove from selectedContacts array
  const handleMultiSelect = (contact) => {
    if (selectedContacts.includes(contact)) {
      const newSelection = selectedContacts.filter(x => x != contact);
      setSelectedContacts(newSelection);
    } else {
      const newSelection = [...selectedContacts, contact];
      setSelectedContacts(newSelection)
    }
    setActionType('Update Multiple Contacts')
  }

  return (
    <Grid
      style={{
        height:'calc(100vh - 115px)',
        display:'flex',
        flexDirection:'column',
        // flex:1,
      }}
    >
      <Grid
        container
        style={{
          justifyContent:'space-between',
          padding:16
        }}
      >
        <ReusableModal>
          <Button
            variant='contained'
            style={{
              lineHeight:1,
              padding:'10px 16px'
            }}
          >
            Select Columns
            <MoreMenuSVG height={16} color1="#fff" style={{transform:'rotate(90deg)', marginLeft:8}} />
          </Button>

          <Grid
            style={{
              padding:24
            }}
          >
            <Typography
              variant="h5"
              textAlign="center"
            >
              Display Columns
            </Typography>
            {
              headerColumns.map(col => { 
                return (
                  <Grid
                    key={col.field}
                    variant="space-between"
                    onClick={() => handleColumnDisplay(col)}
                    style={{
                      background: displayColumns.some(column => column.field === col.field) ? theme.palette.primary.light : '#eee',
                      padding:'4px 8px 4px 16px',
                      margin:'4px 0',
                      borderRadius:32,
                      cursor:'pointer'
                    }}
                  >
                    <Typography>
                      {col.label}
                    </Typography>
                    <Typography style={{fontSize:'.85rem', textTransform:'uppercase', background:'#ffffffcc', borderRadius:16, lineHeight:1, padding:'2px 8px'}}>
                      {translateFieldType(col.type ? col.type : 'required')}
                    </Typography>
                  </Grid>
              )})
            }
            <Typography
              textAlign="center"
            >
              Rich text cannot be displayed in the list view
            </Typography>
          </Grid>
        </ReusableModal>

        <ReusableSearch
          useSearch
          isOpen
          content={contacts}
          setContent={setSortedContacts}
          placeholder="Search for name or email"
          searchFields={['name', 'primary_email']}
        />

        {/* <Button
          onClick={handleFilterClick}
          variant='contained'
          disabled={filterOn}
          style={{
            lineHeight:1,
            padding:'10px 16px',
            marginLeft:16
          }}
        >
          Filter Columns
          <FilterSVG height={16} color1="#fff" style={{marginLeft:8}} />
        </Button> */}

      </Grid>

      <Grid
        container
        style={{
          width:'100%', //'calc(100% - 50px)',
          background:theme.palette.primary.dark
          // marginLeft:50,
        }}
      >
        { // Return the display name of every field represented in the displayColumns array
          displayColumns.map((item) => (
            <TextCell
              key={item.field}
              field={item.field}
              input={item.label}
              displayColumns={displayColumns}
              header
            />
          ))
        }
      </Grid>

      <Grid
        style={{
          // maxHeight:'calc(100vh - 200px)',
          flex:1,
          overflowY:'auto'
        }}
      >

      {
        sortedContacts &&
          sortedContacts.map((contact, index) => {
            const handleDoubleClick = () => {
              setCurrentContact(contact);
              setSelectedContact(contact);
              setOpen(true)
            }

            const handleTripleClick = () => {
              setCurrentContact(contact);
              setSelectedContact(contact);
              setActionType('Update Contact');
              setFocusField()
              if(!open) setOpen(true);
            }

            const selectedContact = contact.id && (currentContact?.id === contact.id);

            let input = null
            if (selectedContact) {
              input = currentContact;
            } else {
              input = contact;
            }

            return (
              <Grid
                container
                key={contact.id}
                onMouseEnter={() => setHover(contact.id)}
                onMouseLeave={() => setHover(null)}
                alignItems="center"
                style={{
                  margin:2,
                  width:'calc(100% - 4px)'
                }}
              >
                {
                //  <Grid
                //   style={{
                //     width:50,
                //     height:'100%',
                //     display:'flex',
                //     alignItems:'center',
                //     justifyContent:'center'
                //   }}
                // >
                //   <IconButton
                //     color='primary'
                //     onClick={() => handleDoubleClick(contact)}
                //     style={{
                //       display: hover === contact.id ? 'inherit' : 'none'
                //     }}
                //   >
                //     <EditSVG width={20} color1={theme.palette.primary.main} />
                //   </IconButton>
                // </Grid> 
                }
                <Grid
                  key={contact.id}
                  id={`parent-${contact.id}`}
                  ref={parentRef}
                  container
                  onClick={(e) => {
                    if (e.shiftKey) {handleMultiSelect(contact)}
                    else handleSingleSelect(contact)
                  }}
                  // onClick={handleDoubleClick}
                  // onFocus={() => handleFocus(contact)}
                  // onBlur={handleParentFocusOut}
                  style={{
                    alignItems:'center',
                    width: '100%', //'calc(100% - 50px)',
                    background: selectedContacts.length > 1 && selectedContacts.includes(contact) ? theme.palette.primary.main : (index % 2 ? `${theme.palette.primary.main}20` : '#fff'),
                    outline: currentContact?.id === contact.id ? `2px solid ${theme.palette.primary.main}` : null,
                    zIndex: currentContact?.id === contact.id ? 2 : 1
                  }}
                >
                  { // Return the value for every field represented by a display column in the displayColumns array
                    displayColumns.map((item, index) => {
                      const handleDoubleClick = () => {
                        setCurrentContact(contact);
                        setSelectedContact(contact);
                        setActionType('Update Contact');
                        setFocusField(item.field);
                        setSelectedContacts([])
                        if(!open) setOpen(true);
                      }

                      return (
                        <TextCell
                          contactId={contact.id}
                          key={item.field}
                          field={item.field}
                          type={item.type}
                          input={item.columnType === 'custom'
                            ? input.custom_fields?.[item.id] // We use the id in custom fields because the format is { [id]: value }
                            // : item.type === 'list' ? input[item.field] : input[item.field]
                            : typeof input[item.field] === 'object' && Array.isArray(input[item.field]) ? input[item.field].map(item => item.name) : input[item.field]
                          }
                          selectedContact={selectedContact}
                          setCurrentContact={setCurrentContact}
                          displayColumns={displayColumns}
                          onClick={(e) => {
                            if (e.detail === 1) {

                            }
                            if (e.detail === 2) {
                              handleDoubleClick()
                            }
                          }}
                        />
                      )
                    })
                  }
                </Grid>
              </Grid>
            )
          })
      }
      </Grid>
    </Grid>
  )
}

export default ContactsDisplayGrid

const TextCell = (props) => {
  const { contactId, input, type, field, onClick, setCurrentContact, displayColumns, header } = props;
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [file, setFile] = useState(null);
  const [openListDialog, setOpenListDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (input && type === 'image_file') {
        // console.log('input', input)
        try {
          const imgObj = {
            folderId: 1,
            fileId: input
          };
          const file = await getStorageFile(imgObj);
          setFile(file.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      setFile(null); // cleanup function
    };
  }, [type, input]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied])

  const copyText = async () => {
    const copiedText = document.getElementById(`id-${input}`).innerHTML;
    try {
      await navigator.clipboard.writeText(copiedText);
      setCopied(true);
    }
    catch (error) {
      console.log('failed to copy text value')
    }
  }

  const TextWrap = () => {
    
    switch (type) {
      case 'url':
        return (
          <Typography
            id={`id-${input}`}
            style={{
              padding:'16px 12px',
              overflow:'hidden',
              textOverflow:'ellipsis',
              whiteSpace:'nowrap',
            // cursor:'default'
            }}
          >
            <a
              href={`http://${input}`}
              target="_blank"
              variant="text"
              style={{
                // textDecoration:'none',
                // color:'inherit'
              }}
            >
              {input}
            </a>
          </Typography>
        );

      case 'image_file':
        return (
          file
            ?
              <img
                src={file.file}
                style={{
                  maxHeight:56,
                  objectFit:'contain'
                }}
              />
            :
              null
        );

      case 'list':
        return (
          <Grid
            container
            style={{
              position:'relative',
              overflow:'hidden',
              textOverflow:'ellipsis',
              whiteSpace:'nowrap',
            }}
          >
            {
              input?.slice(0,2)?.map(item => (
                <Grid
                  item
                  onDoubleClick={copyText}
                  style={{
                    border:`1px solid ${theme.palette.primary.light}`,
                    background:'#ffffff70',
                    cursor:header ? 'default' : 'pointer',
                    padding:'2px 8px',
                    margin:'1px 1px',
                    borderRadius:16
                  }}
                >
                  <Typography
                    id={`id-${item}`}
                    style={{
                      padding:0,
                      lineHeight:1,
                      fontSize:'.85rem',
                      fontWeight:500,
                      overflow:'hidden',
                      textOverflow:'ellipsis',
                      whiteSpace:'nowrap',
                      color:theme.palette.primary.main
                    }}
                  >
                    {item}
                  </Typography>
                </Grid>
              ))
            }
            {
              input?.length - 2 > 0
                ?
                  <Grid container style={{position:'relative'}}>
                    <Grid
                      item
                      id={`more-${contactId}`}
                      onClick={() => setOpenListDialog(true)}
                      style={{
                        position:'relative',
                        border:`1px solid ${theme.palette.primary.light}`,
                        background:'#ffffff70',
                        cursor:header ? 'default' : 'pointer',
                        padding:'2px 8px',
                        margin:'1px 1px',
                        borderRadius:16
                      }}
                    >
                      <Typography
                        style={{
                          padding:0,
                          lineHeight:1,
                          fontSize:'.85rem',
                          fontWeight:500,
                          overflow:'hidden',
                          textOverflow:'ellipsis',
                          whiteSpace:'nowrap',
                          color:theme.palette.primary.main
                        }}
                      >
                        +{input?.length - 2} More
                      </Typography>
                    </Grid>
                    <Dialog
                      id="basic-menu"
                      open={openListDialog}
                      onClose={() => setOpenListDialog(false)}
                    >
                      <Grid
                        container
                        style={{
                          padding:24
                        }}
                      >
                        {input?.map(item => (
                          <Grid
                            item
                            onDoubleClick={copyText}
                            style={{
                              border:`1px solid ${theme.palette.primary.light}`,
                              background:'#ffffff70',
                              cursor:header ? 'default' : 'pointer',
                              padding:'2px 16px',
                              margin:'4px 4px',
                              borderRadius:16
                            }}
                          >
                            <Typography
                              id={`id-${item}`}
                              style={{
                                padding:0,
                                // lineHeight:1,
                                // fontSize:'.85rem',
                                overflow:'hidden',
                                textOverflow:'ellipsis',
                                whiteSpace:'nowrap',
                                // cursor:'default'
                              }}
                            >
                              {item}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                    </Dialog>

                  </Grid>
                :
                  null 
            }
          </Grid>
        );
      
      default:
        return (
          <Grid
            onDoubleClick={copyText}
            style={{
              cursor:header ? 'default' : 'pointer'
            }}
          >
            <Typography
              id={`id-${input}`}
              style={{
                padding:'16px 12px',
                overflow:'hidden',
                textOverflow:'ellipsis',
                whiteSpace:'nowrap',
                // cursor:'default'
              }}
            >
              {input}
            </Typography>
          </Grid>
        );
    }
  }

  const HeaderWrap = () => {
    return (
      <Grid
        onDoubleClick={copyText}
        style={{
          cursor:header ? 'default' : 'pointer'
        }}
      >
        <Typography
          id={`id-${input}`}
          style={{
            padding:'16px 12px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            color:'#fff'
          }}
        >
          {input}
        </Typography>
      </Grid>

    )
  }


  return (
    <>
      <Grid
        item
        onClick={onClick}
        xs={12 / displayColumns.length}
      >
        {
          header && !type
            ? <HeaderWrap />
            : <TextWrap />

        }
      </Grid>
      
      {
        copied &&
          <Grid
            style={{
              position:'fixed',
              bottom:50,
              right:50,
              display:'flex',
              background:'#333',
              padding:'4px 16px',
              borderRadius:8,
              zIndex:5000
            }}
          >
            <CopySVG width={20} color1="#fff" color2="#aaa" />
            <Typography style={{color:'#fff', marginLeft:8}}>
              {input}
            </Typography>
          </Grid>
      }
    </>
  )
}