import { AppBar, Button, Collapse, Drawer, Grid, IconButton, Slide, Toolbar, useScrollTrigger } from '@mui/material'
import React, { useState } from 'react'
import { LogoHorizontal } from '../../assets/logos'
import { hashScroll } from '../../lib/Functions'
import { useScreenSize } from '../../lib/Interface';
import { CloseFilledSVG, CloseRoundedSVG, MoreMenuSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const MainNav = (props) => {
  const theme = useTheme();
  const screenSize = useScreenSize();
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpen = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  }

  const handleClose = () => {
    setOpenMenu(false);
  }

  return (
    <HideOnScroll {...props}>
      <AppBar>
        {/* <Toolbar> */}
          <Grid
            container
            style={{
              background: '#fff',
              height:60,
              padding:'0 24px',
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
            <Grid
              style={{
                display:'flex',
                alignItems:'center'
              }}
            >
              <LogoHorizontal height={24} />
            </Grid>
            {
              screenSize.xs
                ?
                  <>
                    <IconButton
                      onClick={handleOpen}
                    >
                      <MoreMenuSVG width={18} color1={theme.palette.primary.main} />
                    </IconButton>
                    <Drawer
                      anchor="right"
                      open={openMenu}
                      onClose={handleClose}
                    >
                      <Grid
                        style={{textAlign:'center'}}
                      >
                        <IconButton
                          onClick={handleClose}
                          style={{
                            padding:0
                          }}
                        >
                          <CloseRoundedSVG width={18} style={{height:60}} color1={theme.palette.primary.dark} />
                        </IconButton>
                        <NavMenu />
                      </Grid>
                    </Drawer>
                  </>
                :
                  <NavMenu />
            }
          </Grid>
        {/* </Toolbar> */}
      </AppBar>
    </HideOnScroll>

  )
}

export default MainNav

const NavMenu = () => {
  const screenSize = useScreenSize();
  const isMobile = screenSize.xs;

  return (
    <Grid
      style={{
        display:'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems:'center',
        minWidth: isMobile ? 150 : 0
      }}
    >
      <NavButton
        label="About"
        hash="about"
      />
      <NavButton
        label="Features"
        hash="features"
      />
      <NavButton
        label="Pricing"
        hash="pricing"
      />
      <NavButton
        label="Sign Up"
        hash="pricing"
      />
      <NavButton
        label="Login"
        href="/login"
      />
    </Grid>

  )
}

const NavButton = (props) => {
  const { label, hash, href } = props;
  console.log('hash', hash);

  return (
    <Button
      onClick={() => hashScroll(hash)}
      href={href}
      fullWidth
      style={{
        fontWeight:600
      }}
    >
      {label}
    </Button>
  )
}
