import { Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UpAngleSVG } from '../../../assets/icons';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { createSubmission, updateSurvey, updateSubmission, useGetSurvey, useGetSubmission } from '../../../hooks/mutations';
import EntranceForm from './EntranceForm';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { getRandomInts } from '../../../lib/Functions';
import { useTemp } from '../../../context/tempContext';
import FormPage from './form-display-elements/FormPage';
import CompletePage from './form-display-elements/CompletePage';

const FormDisplayStartCollect = (props) => {
  const { incomingSurvey, preview } = props;
  const [input, setInput] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [questions, setQuestions] = useState([]); // <-- This state needs to be moved into forms state the same way we did it in the FormBuilder1 component
  const [grantEntrance, setGrantEntrance] = useState(true);
  const tempContext = useTemp();
  const theme = useTheme();
  const [form, setForm] = useState(incomingSurvey);

  // console.log('form', form);

  // // Set form state based on temp survey state if preview prop is true
  // useEffect(() => {
  //   if (preview) {
  //     setForm(tempSurvey);
  //   }
  // }, [preview])

  useEffect(() => {
    if (input) {
      getSubmissionReady();
    }
  }, [input]);

  const getSubmissionReady = () => {
    const newInput = [ ...input ];
    const reducedInput = newInput.map(x => ({ id: x.id, choices: x.choices.join(', ') }));
    console.log('reducedInput', reducedInput);
    return reducedInput;
  }

  // Set form state based on incomingForm
  useEffect(() => {
    if (incomingSurvey) {
      setForm(incomingSurvey);
    }
  }, [incomingSurvey])

  useEffect(() => {
    if (form) {
      setQuestions(form.category_set[0].question_set);
    }
  }, [form]);

  // Determine if an entrance form must be filled out to start the survey
  useEffect(() => {
    if (form && !form?.getContact) {
      setGrantEntrance(true);
    }
  }, [form]);

  // // Create initial submission when grantEntrance is set to true (after userInfo is entered)
  // useEffect(() => {
  //   if (grantEntrance && !preview) {
  //     createInitialSubmission();
  //   }
  // }, [grantEntrance])

  const reset = () => {
    setCurrentPage(0);
    setInput([]);
  };

  const nextPage = () => {
    submitForm();
    setCurrentPage(prev => prev+1);
  }

  const backPage = () => {
    setCurrentPage(prev => prev-1);
  }

  const directPage = (index) => {
    setCurrentPage(index)
  }

  const handleAnswer = (obj) => {
    const { answerIndex, answer, selected, answers, goNext } = obj;
    const newInput = [...input];
    newInput[currentPage] = {...questions[currentPage], choices:answers, selected:selected};
    // console.log('newInput', newInput);
    setInput(newInput);
    if (goNext) {
      setCurrentPage(prev => questions.length-1 > prev ? prev+1 : 'complete');
    }
  } 

  // Create the submission with initial user data
  const createInitialSubmission = async () => {
    const newInput = [ ...input ];
    const inputForSubmission = input.map(x => ({body: '', question_id: ''}))
    if (form) {
      const payload = {
        complete: false,
        formId: form.id,
        formTitle: form.name,
        answer_set: input,
      };
      // if (saved === 'success') {
      //   const updatedForm = updateSurvey({
      //     id: form.id,
      //     submissionStarted: arrayUnion(userInfo.email)
      //   })
      //   // console.log('updated form:', updatedForm);
      // } 
    } else {
      console.log('No form yet');
    }
  }

  // Updates to the submission every time the Next button is clicked
  const submitForm = async (complete) => {
    if (input) {
      const reducedInput = getSubmissionReady();
      const submissionObj = {
        answer_set: reducedInput
      }
      const saved = await updateSubmission(submissionObj);

      if (saved === 'success' && complete) {

      } 
    } else {
      console.log('No form yet');
    }
  }

  return (
    grantEntrance
      ?
        form && questions &&
          <Grid
            container
            style={{
              position:'relative',
              // height:'calc(100vh - 60px)',
              // flexDirection:'column',
              // justifyContent:'center',
              alignItems:'center',
              overflowY:'auto'
            }}
          >
            <Grid
              container
              style={{
                // position:'absolute',
                // top:60,
                // left:0
              }}
            >
              <Grid
                container
                style={{
                  padding:'0 24px'
                }}
              >
                <Typography
                  style={{
                    fontSize:'1.3rem'
                  }}
                >
                  {form.name}
                </Typography>
              </Grid>

              {
                questions.map((item, navIndex) => (
                  <Grid
                    item
                    onClick={() => {
                      if (input[navIndex]?.choices.length) {
                        directPage(navIndex);
                      } else {
                        const lastAnswered = input.length ? input.filter(x => x.choices.length).length : 1;
                        directPage(lastAnswered-1);
                      }
                    }}
                    style={{
                      position:'relative',
                      height:30,
                      cursor:'pointer'
                    }}
                    xs
                  >
                    <Grid
                      style={{
                        height:currentPage === navIndex ? '30%' : '30%',
                        transition:'.4s',
                        background: currentPage === navIndex ? theme.palette.primary.main : `${theme.palette.primary.light}70`,
                        border:'1px solid white'
                      }}
                    />
                    <UpAngleSVG
                      width="100%"
                      color1={currentPage === navIndex ? theme.palette.primary.main : theme.palette.primary.light}
                      style={{
                        position:'absolute',
                        width: currentPage == navIndex ? 14 : 0,
                        // bottom:30,
                        left: 'calc(50% - 10px)',
                        transition:'.4s'
                      }}
                    />
                  </Grid>
                ))
              }
            </Grid>
            {
              currentPage < questions.length
                ?
                  <FormPage
                    question={questions[currentPage]}
                    index={currentPage}
                    handleAnswer={handleAnswer}
                    input={input}
                    preview={preview}
                    nextPage={nextPage}
                    backPage={backPage}
                  />
                :
                  <CompletePage submitForm={submitForm} />
            }

          </Grid>
      :
          <Grid
            container
            style={{
              position:'relative',
              height:'calc(100vh - 59px)',
              flexDirection:'column',
              // justifyContent:'center',
              alignItems:'center',
              overflowY:'auto'
            }}
          >
            <Grid
              container
              style={{
                flexDirection:'column',
                maxWidth:500
              }}
            >
              <Typography
                style={{
                  textAlign:'center',
                  fontSize:'1.3rem',
                  textTransform:'uppercase',
                  fontWeight:600
                }}
              >
                {form?.name}
              </Typography>
              <Typography
                style={{
                  // textAlign:'center',
                  fontSize:'1rem'
                }}
              >
                {form?.details}
              </Typography>
            </Grid>

            {/* <EntranceForm setUserInfo={setUserInfo} setGrantEntrance={setGrantEntrance} form={form} /> */}
          </Grid>
  )
}

export default FormDisplayStartCollect
