export const mockData = {
  'contacts': {
    'contacts': [
      {
        "id": 2,
        "tags": "string-t2,another1",
        "tenant_id": 1,
        "uuid": "878eddc1-1fe0-4b71-ba05-6fc328964948",
        "open_rate": 0,
        "click_rate": 0,
        "update_date": "2023-10-15T20:25:57.925000Z",
        "status": 1,
        "optin_ip_address": null,
        "optin_date": "2023-11-20T13:05:16.435260Z",
        "confirm_ip_address": null,
        "confirm_date": null,
        "last_seen_ip_address": null,
        "last_seen_date": null,
        "last_sent": null,
        "salutation": "MR",
        "first_name": "string",
        "last_name": "string",
        "date_of_birth": "2023-10-15",
        "organization": "string",
        "title": "string",
        "primary_email": "user3d1f9d192@example.com",
        "secondary_email": "",
        "mobile_number": null,
        "secondary_number": null,
        "department": "string",
        "language": "string",
        "do_not_call": true,
        "description": "string",
        "linked_in_url": null,
        "facebook_url": null,
        "twitter_username": null,
        "created_by": null,
        "created_on": "2023-11-20T13:05:16.435804Z",
        "is_active": true,
        "country": null,
        "custom_fields": [
          {
            "id": 0,
            "tenant_id": 0,
            "data_type": "text",
            "validation_rule": "not_null",
            "validation_value": "string",
            "field_name": "display_name"
          },
          {
            "id": 1,
            "tenant_id": 0,
            "data_type": "bool",
            "validation_rule": "not_null",
            "validation_value": true,
            "field_name": "called"
          },
          {
            "id": 2,
            "tenant_id": 0,
            "data_type": "date",
            "validation_rule": "not_null",
            "validation_value": "2023-11-20T13:05:16.435260Z",
            "field_name": "last-contact"
          },
          {
            "id": 3,
            "tenant_id": 0,
            "data_type": "number",
            "validation_rule": "not_null",
            "validation_value": 4,
            "field_name": "contact attempts"
          }
        ],
        "domain": null,
        "mailing_list": null,
        "location": null,
        "address": null
      },
      {
        "id": 1,
        "tags": "",
        "tenant_id": 1,
        "uuid": "c480826e-1876-4547-83c6-8469cf487957",
        "open_rate": 0,
        "click_rate": 0,
        "update_date": "2023-10-25T01:57:40Z",
        "status": 2,
        "optin_ip_address": null,
        "optin_date": "2023-09-20T00:00:00Z",
        "confirm_ip_address": null,
        "confirm_date": null,
        "last_seen_ip_address": null,
        "last_seen_date": null,
        "last_sent": null,
        "salutation": "MR",
        "first_name": "Foo",
        "last_name": "Bar",
        "date_of_birth": null,
        "organization": null,
        "title": "",
        "primary_email": "foobar@example.com",
        "secondary_email": "",
        "mobile_number": null,
        "secondary_number": null,
        "department": null,
        "language": null,
        "do_not_call": false,
        "description": "",
        "linked_in_url": null,
        "facebook_url": null,
        "twitter_username": null,
        "created_by": null,
        "created_on": "2023-10-25T01:57:40.533765Z",
        "is_active": false,
        "country": null,
        "custom_fields": [
          {
            "id": 0,
            "tenant_id": 0,
            "data_type": "text",
            "validation_rule": "not_null",
            "validation_value": "string",
            "field_name": "nickname"
          },
          {
            "id": 1,
            "tenant_id": 0,
            "data_type": "bool",
            "validation_rule": "not_null",
            "validation_value": false,
            "field_name": "called"
          },
          {
            "id": 2,
            "tenant_id": 0,
            "data_type": "date",
            "validation_rule": "not_null",
            "validation_value": "2023-11-20T13:05:16.435260Z",
            "field_name": "last-contact"
          }
        ],
        "domain": 1,
        "mailing_list": 1,
        "location": null,
        "address": null
      }
    ],
    'contacts/{id}': {
      "id": 0,
      "tags": [
        "string"
      ],
      "tenant_id": 0,
      "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "open_rate": 0,
      "click_rate": 0,
      "update_date": "2023-11-20T20:17:33.807Z",
      "status": 1,
      "optin_ip_address": "string",
      "optin_date": "2023-11-20T20:17:33.808Z",
      "confirm_ip_address": "string",
      "confirm_date": "2023-11-20T20:17:33.808Z",
      "last_seen_ip_address": "string",
      "last_seen_date": "2023-11-20T20:17:33.808Z",
      "last_sent": "2023-11-20T20:17:33.808Z",
      "salutation": "MR",
      "first_name": "string",
      "last_name": "string",
      "date_of_birth": "2023-11-20",
      "organization": "string",
      "title": "string",
      "primary_email": "user@example.com",
      "secondary_email": "user@example.com",
      "mobile_number": "string",
      "secondary_number": "string",
      "department": "string",
      "language": "string",
      "do_not_call": true,
      "description": "string",
      "linked_in_url": "string",
      "facebook_url": "string",
      "twitter_username": "string",
      "created_by": 0,
      "created_on": "2023-11-20T20:17:33.808Z",
      "is_active": true,
      "country": "GB",
      "custom_fields": {
        "additionalProp1": "string",
        "additionalProp2": "string",
        "additionalProp3": "string"
      },
      "domain": 0,
      "mailing_list": 0,
      "location": 0,
      "address": 0
    },
    'custom-fields': [
      {
        "id": 1,
        "tenant_id": 1,
        "data_type": "text",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "First Name"
    },
    {
        "id": 2,
        "tenant_id": 1,
        "data_type": "text",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "Last Name"
    },
    {
        "id": 3,
        "tenant_id": 1,
        "data_type": "date",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "Birthday"
    }
    ],
    'custom-fields/{id}': {
        "id": 1,
        "tenant_id": 1,
        "data_type": "text",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "First Name"
    },
  },
  'user_survey': {
    'surveys': [
      {
        "id": 0,
        "category_set": [
          {
            "id": 0,
            "question_set": [
              {
                "id": 0,
                "tenant_id": 0,
                "text": "string",
                "order": 0,
                "required": true,
                "type": "text",
                "choices": "string"
              }
            ],
            "tenant_id": 0,
            "name": "string",
            "order": 0,
            "description": "string"
          }
        ],
        "name": "string",
        "description": "string",
        "need_logged_user": true,
        "editable_answers": true,
        "display_method": 1,
        "expire_date": "2023-11-20",
        "redirect_url": "string",
        "created_by_user": 0,
        "status": 1,
        "create_date": "2023-11-20T20:21:28.656Z",
        "update_date": "2023-11-20T20:21:28.656Z"
      }
    ],
    'surveys/{id}': {
      "id": 0,
      "category_set": [
        {
          "id": 0,
          "question_set": [
            {
              "id": 0,
              "tenant_id": 0,
              "text": "string",
              "order": 0,
              "required": true,
              "type": "text",
              "choices": "string"
            }
          ],
          "tenant_id": 0,
          "name": "string",
          "order": 0,
          "description": "string"
        }
      ],
      "name": "string",
      "description": "string",
      "need_logged_user": true,
      "editable_answers": true,
      "display_method": 1,
      "expire_date": "2023-11-20",
      "redirect_url": "string",
      "created_by_user": 0,
      "status": 1,
      "create_date": "2023-11-20T20:22:51.661Z",
      "update_date": "2023-11-20T20:22:51.661Z"
    },
  },
  'cms': {
    'funnels': [
      {
        "id": 1,
        "steps": [
          {
            "id": 1,
            "name": "source_trigger_1_signup_event_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "source_trigger",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": 1,
            "edges": {
              "next_yes": 2
            }
          },
          {
            "id": 2,
            "name": "action_step_2_send_email_1",
            "delay_hours": 168,
            "type_id": 1,
            "node_type": "target_action",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": 3
            }
          },
          {
            "id": 3,
            "name": "condition_step_3_user_opened_email_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "condition",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": 4,
              "next_no": 5
            }
          },
          {
            "id": 4,
            "name": "action_step_4_send_email_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "target_action",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": null
            }
          },
          {
            "id": 5,
            "name": "action_step_5_send_email_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "target_action",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": null
            }
          }
        ],
        "stages": [
          {
            "id": 1,
            "tenant_id": 1,
            "name": "stage1",
            "description": null,
            "funnel": 1
          }
        ],
        "tenant_id": 1,
        "created_by": null,
        "created_on": "2023-10-25T01:33:41.514493Z",
        "name": "internal-test-funnel",
        "description": null,
        "step_count": null
      }
    ],
    'funnels/{id}': {
      "id": 0,
      "steps": [
        {
          "id": 0,
          "name": "string",
          "delay_hours": 0,
          "type_id": 0,
          "node_type": "target_action",
          "node_params": {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
          },
          "funnel": 0,
          "stage": 0
        }
      ],
      "stages": [
        {
          "id": 0,
          "tenant_id": 0,
          "name": "string",
          "description": "string",
          "funnel": 0
        }
      ],
      "tenant_id": 0,
      "created_by": 0,
      "created_on": "2023-11-20T20:28:01.682Z",
      "name": "string",
      "description": "string",
      "step_count": 0
    },
    'funnels/{id}/stages': [
      {
        "id": 0,
        "tenant_id": 0,
        "name": "string",
        "description": "string",
        "funnel": 0
      }
    ],
    'funnels/{id}/stages/{id}': {
      "id": 0,
      "tenant_id": 0,
      "name": "string",
      "description": "string",
      "funnel": 0
    },
    'funnels/{id}/steps': [
      {
        "id": 0,
        "name": "string",
        "delay_hours": 0,
        "type_id": 0,
        "node_type": "target_action",
        "node_params": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "funnel": 0,
        "stage": 0
      }
    ],
    'funnels/{id}/steps/{id}': {
      "id": 0,
      "name": "string",
      "delay_hours": 0,
      "type_id": 0,
      "node_type": "target_action",
      "node_params": {
        "additionalProp1": "string",
        "additionalProp2": "string",
        "additionalProp3": "string"
      },
      "funnel": 0,
      "stage": 0
    }
  }
}

const funnelResponse =  {
  "id": 12,
  "steps": [
    {
      "id": 12,
      "name": "Funnel",
      "delay_hours": null,
      "type_id": 1,
      "node_type": "funnel",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": 11,
      "edges": {
        "next_yes": 20
      }
    },
    {
      "id": 20,
      "name": "trigger_1_signup_event_12",
      "delay_hours": null,
      "type_id": 1,
      "node_type": "trigger",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": 11,
      "edges": {
        "next_yes": 21
      }
    },
    {
      "id": 21,
      "name": "action_step_2_send_email_12",
      "delay_hours": 168,
      "type_id": 1,
      "node_type": "action",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": null,
      "edges": {
        "next_yes": 22
      }
    },
    {
      "id": 22,
      "name": "condition_step_3_user_opened_email_12",
      "delay_hours": null,
      "type_id": 1,
      "node_type": "condition",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": null,
      "edges": {
        // "next_yes": 23,
        // "next_no": 24
      }
    },
    // {
    //   "id": 23,
    //   "name": "action_step_4_send_email_12",
    //   "delay_hours": null,
    //   "type_id": 1,
    //   "node_type": "action",
    //   "node_params": {
    //     "email_template_id": 5
    //   },
    //   "funnel": 12,
    //   "stage": null,
    //   "edges": {
    //     "next_yes": null
    //   }
    // },
    // {
    //   "id": 24,
    //   "name": "action_step_5_send_email_12",
    //   "delay_hours": null,
    //   "type_id": 1,
    //   "node_type": "action",
    //   "node_params": {
    //     "email_template_id": 5
    //   },
    //   "funnel": 12,
    //   "stage": null,
    //   "edges": {
    //     "next_yes": null
    //   }
    // }
  ],
  "tenant_id": 1,
  "created_by": null,
  "created_on": "2023-10-24T04:45:05.940212Z",
  "name": "internal-test-funnel",
  "description": null,
  "step_count": null
}

export const realMockInitialData = () => {
  const response = {...funnelResponse}
  
  const nodes = [];
  const edges = [];
  
  // Create nodes
  response.steps.forEach((step) => {
    nodes.push({
      id: step.id.toString(),
      type: step.node_type,
      data: { label: step.name },
      position: { x: 0, y: 0 },
    });
  
    // Check if the step has edges
    if (step.edges) {
      // Handle 'next_yes' edge
      if (step.edges.next_yes) {
        edges.push({
          id: `${step.id}_next_yes`,
          source: step.id.toString(),
          target: step.edges.next_yes.toString(),
        });
      }
  
      // Handle 'next_no' edge if it exists
      if (step.edges.next_no) {
        edges.push({
          id: `${step.id}_next_no`,
          source: step.id.toString(),
          target: step.edges.next_no.toString(),
        });
      }
    }
  });
  
  // Resulting nodes and edges for React Flow
  console.log("Nodes:", nodes);
  console.log("Edges:", edges);

  return {
    nodes: nodes,
    edges: edges
  }
  
} 

export const contactMockData = {
  count:20,
  next: null,
  previous: null,
  results: [
      {
        "id": 1,
        "name": "One",
        "primary_email": "One@test.com"
      },
      {
        "id": 2,
        "name": "Two",
        "primary_email": "Two@test.com"
      },
      {
        "id": 3,
        "name": "Three",
        "primary_email": "Three@test.com"
      },
      {
        "id": 4,
        "name": "Four",
        "primary_email": "Four@test.com"
      },
      {
        "id": 5,
        "name": "Five",
        "primary_email": "Five@test.com"
      },
      {
        "id": 6,
        "name": "Six",
        "primary_email": "Six@test.com"
      },
      {
        "id": 7,
        "name": "Seven",
        "primary_email": "Seven@test.com"
      },
      {
        "id": 8,
        "name": "Eight",
        "primary_email": "Eight@test.com"
      },
      {
        "id": 9,
        "name": "Nine",
        "primary_email": "Nine@test.com"
      },
      {
        "id": 10,
        "name": "Ten",
        "primary_email": "Ten@test.com"
      },
      {
        "id": 11,
        "name": "Eleven",
        "primary_email": "Eleven@test.com"
      },
      {
        "id": 12,
        "name": "Twelve",
        "primary_email": "Twelve@test.com"
      },
      {
        "id": 13,
        "name": "Thirteen",
        "primary_email": "Thirteen@test.com"
      },
      {
        "id": 14,
        "name": "Fourteen",
        "primary_email": "Fourteen@test.com"
      },
      {
        "id": 15,
        "name": "Fifteen",
        "primary_email": "Fifteen@test.com"
      },
      {
        "id": 16,
        "name": "Sixteen",
        "primary_email": "Sixteen@test.com"
      },
      {
        "id": 17,
        "name": "Seventeen",
        "primary_email": "Seventeen@test.com"
      },
      {
        "id": 18,
        "name": "Eighteen",
        "primary_email": "Eighteen@test.com"
      },
      {
        "id": 19,
        "name": "Nineteen",
        "primary_email": "Nineteen@test.com"
      },
      {
        "id": 20,
        "name": "Twenty",
        "primary_email": "Twenty@test.com"
      },
      {
        "id": 21,
        "name": "Twenty One",
        "primary_email": "Twenty One@test.com"
      },
      {
        "id": 22,
        "name": "Twenty Two",
        "primary_email": "Twenty Two@test.com"
      },
      {
        "id": 23,
        "name": "Twenty Three",
        "primary_email": "Twenty Three@test.com"
      },
      {
        "id": 24,
        "name": "Twenty Four",
        "primary_email": "Twenty Four@test.com"
      },
      {
        "id": 25,
        "name": "Twenty Five",
        "primary_email": "Twenty Five@test.com"
      },
      {
        "id": 26,
        "name": "Twenty Six",
        "primary_email": "Twenty Six@test.com"
      },
      {
        "id": 27,
        "name": "Twenty Seven",
        "primary_email": "Twenty Seven@test.com"
      },
      {
        "id": 28,
        "name": "Twenty Eight",
        "primary_email": "Twenty Eight@test.com"
      },
      {
        "id": 29,
        "name": "Twenty Nine",
        "primary_email": "Twenty Nine@test.com"
      },
      {
        "id": 30,
        "name": "Thirty",
        "primary_email": "Thirty@test.com"
      },
      {
        "id": 31,
        "name": "Thirty One",
        "primary_email": "Thirty One@test.com"
      },
      {
        "id": 32,
        "name": "Thirty Two",
        "primary_email": "Thirty Two@test.com"
      },
      {
        "id": 33,
        "name": "Thirty Three",
        "primary_email": "Thirty Three@test.com"
      },
      {
        "id": 34,
        "name": "Thirty Four",
        "primary_email": "Thirty Four@test.com"
      },
      {
        "id": 35,
        "name": "Thirty Five",
        "primary_email": "Thirty Five@test.com"
      },
      {
        "id": 36,
        "name": "Thirty Six",
        "primary_email": "Thirty Six@test.com"
      },
      {
        "id": 37,
        "name": "Thirty Seven",
        "primary_email": "Thirty Seven@test.com"
      },
      {
        "id": 38,
        "name": "Thirty Eight",
        "primary_email": "Thirty Eight@test.com"
      },
      {
        "id": 39,
        "name": "Thirty Nine",
        "primary_email": "Thirty Nine@test.com"
      },
      {
        "id": 40,
        "name": "Forty",
        "primary_email": "Forty@test.com"
      },
      {
        "id": 41,
        "name": "Forty One",
        "primary_email": "Forty One@test.com"
      },
      {
        "id": 42,
        "name": "Forty Two",
        "primary_email": "Forty Two@test.com"
      },
      {
        "id": 43,
        "name": "Forty Three",
        "primary_email": "Forty Three@test.com"
      },
      {
        "id": 44,
        "name": "Forty Four",
        "primary_email": "Forty Four@test.com"
      },
      {
        "id": 45,
        "name": "Forty Five",
        "primary_email": "Forty Five@test.com"
      },
      {
        "id": 46,
        "name": "Forty Six",
        "primary_email": "Forty Six@test.com"
      },
      {
        "id": 47,
        "name": "Forty Seven",
        "primary_email": "Forty Seven@test.com"
      },
      {
        "id": 48,
        "name": "Forty Eight",
        "primary_email": "Forty Eight@test.com"
      },
      {
        "id": 49,
        "name": "Forty Nine",
        "primary_email": "Forty Nine@test.com"
      },
      {
        "id": 50,
        "name": "Fifty",
        "primary_email": "Fifty@test.com"
      }
    ]
}