import { useTheme } from '@emotion/react';
import { Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { InfoFilledSVG, InfoOutlineSVG } from '../../assets/icons';

// CREATED NEW REUSABLEMODALTOOLTIP.JS FILE IN /UI
import ReusableModalTooltip from '../ui/ReusableModalTooltip';

const PriceFeatureList = (props) => {
  const { features, info, standard, compact = false, wide = false, } = props;
  const theme = useTheme();

  const padding = () => {
    if (compact) {
      return '8px 0'
    }
    else if (wide) {
      return '24px 0'
    }
    else {
      return '16px 0'
    }
  }

  return (
    <Grid
      variant="center"
      flexDirection="column"
      divider={<Divider flexItem />}
      width="100%"
    >
      {
        features.map((feature, index) => (
          <>
            <Grid
              variant="center"
              style={{
                padding: padding(),
                width:'100%',
                background: 
                  standard
                    ?
                      `${theme.palette.background.main}70`
                    :
                      index % 2 ? `${theme.palette.primary.main}10` : `${theme.palette.background.main}70`
              }}
            >
              <Typography
                style={{
                  // textTransform:'uppercase',
                  textAlign:'center',
                  color:theme.palette.primary.dark,
                  lineHeight:1.3,
                  fontSize:16,
                  fontWeight:600,
                  letterSpacing:1,
                }}
              >
                {feature.name}
              </Typography>
              
              {
                info &&
                  <ReusableModalTooltip tooltipTitle={feature.info} tooltipBg={theme.palette.primary.dark} color="primary.main" arrow offset={.1}>
                    <div>
                      <InfoOutlineSVG width={20} style={{padding:'0 8px'}} />
                    </div>
                    
                    <Grid
                      variant="center"
                      flexDirection="column"
                      p={3}
                    >
                      <Typography>
                        {feature.info}
                      </Typography>
                    </Grid>
                  </ReusableModalTooltip>
              }

            </Grid>
            {
              (index != features.length-1) && standard && <Divider variant="middle" style={{ width:'100%', opacity:.6 }} />
            }
          </>
        ))
      }
    </Grid>
  )
}

export default PriceFeatureList