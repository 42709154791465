import { useTheme } from "@emotion/react";

export const Angle = (props) => {
  let { width, height, color1, color2, style } = props;
  const theme = useTheme();
  color1 = color1 ? color1 : theme.palette.primary.main
  color2 = color2 ? color2 : theme.palette.secondary.main

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', ...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width={width}
        height={height}
        preserveAspectRatio="none"
      >
        {/* <title>email</title> */}
        <polygon fill={color1} points="0,0 100,0 0,100" />
      </svg>
    </div>
  )
}
