import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  )
}

export default Loading