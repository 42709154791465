export const formSetupOld = {
  detailsFormat: {
    active:false,
    customId:null,
    details:'',
    getContact:false,
    instructions:'',
    multiSubmissions:false,
    questions:[],
    title:'',
  },
  questionFormat: {
    answers:[],
    details:'',
    question:'',
    type:'radio',
  },
  answerFormat: {
    id:'',
    value:''
  }
}

export const initSurvey = {
  category_set: [
    {
      question_set: [],
      name: "default",
      order: 0,
      description: ""
    }
  ],
  name: "",
  description: "",
  // need_logged_user: true,
  // editable_answers: true,
  // display_method: 1,
  // expire_date: "2023-12-07",
  redirect_url: "https://google.com",
  // created_by_user: 0,
  // status: 1,
  // create_date: "2023-12-07T04:41:50.694378Z",
  // update_date: "2023-12-07T02:45:14.547000Z"
}


export const initQuestionSet = {
  text: "",
  order: 0,
  required: true,
  type: "text",
  choices: []
}

export const initAnswerSet = {
  id:'',
  choice_text:''
}

export const initForm = {
  name:'',
  user_visible_name:'',
  user_visible_description:'',
  user_visible_icon:'',
  redirect_url:'',
  mailing_list_options:[],
  custom_field_options:[],
}

export const customFieldColumns = [
  {
    label: 'Name',
    id: 'field_name',
    width: null
  },
  {
    label: 'Type',
    id: 'data_type',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.075
  },
]

export const tagsColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Color',
    id: 'color',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.05
  },
]

export const surveyColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Description',
    id: 'description',
    width: null
  },
  {
    label: 'ID',
    id: 'id',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const emailTemplateColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Description',
    id: 'description',
    width: null
  },
  {
    label: 'ID',
    id: 'id',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const formColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Description',
    id: 'description',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const funnelColumns = [
  {
    label: 'Name',
    id: 'field_name',
    width: null
  },
  {
    label: 'Type',
    id: 'data_type',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.05
  },
]

export const initHeaderColumns = [
  {label:'Name', field:'name', columnType:'standard', type:'text'},
  {label:'Email', field:'primary_email', columnType:'standard', type:'text'},
  {label:'Mailing Lists', field:'mailing_lists', columnType:'standard', type:'list'},
]

export const emptyNodeData = {
  trigger: {
    // id: , <-- id is set during the update phase -- NOT HERE
    // position <-- position is inherited from original node data during the update phase
    type: 'trigger',
    data: {
      label: 'Trigger'
    },
    sourcePosition: 'bottom',
    targetPosition: 'top'
  },
  action: {
    // id: , <-- id is set during the update phase -- NOT HERE
    // position <-- position is inherited from original node data during the update phase
    type: 'action',
    data: {
      label: 'Action'
    },
    sourcePosition: 'bottom',
    targetPosition: 'top'
  },
  condition: {
    // id: , <-- id is set during the update phase -- NOT HERE
    // position <-- position is inherited from original node data during the update phase
    type: 'condition',
    data: {
      label: 'Condition'
    },
    sourcePosition: 'bottom',
    targetPosition: 'top'
  }
}

export const featureList = [
  {
    name: `Unlimited contacts`,
    short: `Expand your reach without limits.`,
    description: `There are no boundaries to the number of contacts you can manage, ensuring you can nurture relationships and grow your audience without constraints.`,
    icon: `unlimited-contacts`,
    availability: `now`,
  },
  {
    name: `Email Templates`,
    short: `Make every message memorable.`,
    description: `Choose from our wide array of professionally designed email templates, customizable to reflect your brand identity, ensuring your emails captivate and engage your audience.`,
    icon: `email-love`,
    availability: `now`,
  },
  {
    name: `Custom email builder`,
    short: `Personalize your communications with ease.`,
    description: `Design custom emails quick and easy with our drag and drop email builder. Tailored to your audience's preferences, fostering meaningful connections and driving engagement has never been easier.`,
    icon: `email-design`,
    availability: `soon`,
  },
  {
    name: `Automated email campaigns`,
    short: `Save time and boost efficiency.`,
    description: `Utilize Fill Marketing's automated email campaign feature to schedule and send targeted messages at the optimal times, nurturing leads and guiding them through the customer journey effortlessly.`,
    icon: `automated-campaigns`,
    availability: `later`,
  },
  {
    name: `Client Relationship Management`,
    short: `Strengthen connections and drive growth.`,
    description: `Centralize customer data, track interactions, and gain valuable insights to enhance customer satisfaction and drive informed decision-making.`,
    icon: `relationship-management`,
    availability: `now`,
  },
  {
    name: `Custom properties`,
    short: `Capture unique insights and segment your audience effectively.`,
    description: `Define custom properties on contacts with Fill Marketing, enabling targeted campaigns and personalized messaging based on specific criteria.`,
    icon: `custom-properties`,
    availability: `now`,
  },
  {
    name: `Dedicated IP for email sending`,
    short: `Ensure deliverability and reputation.`,
    description: `Leverage a dedicated IP for email sending to maintain control over your email reputation, maximize deliverability, and foster trust with your audience.`,
    icon: `dedicated-ip`,
    availability: `now`,
  },
  {
    name: `Role-based access`,
    short: `Safeguard sensitive data and streamline collaboration.`,
    description: `Define user roles and permissions, empowering your team to work efficiently while maintaining security and control.`,
    icon: `role-access`,
    availability: `later`,
  },
  {
    name: `Mailing list subscription forms`,
    short: `Grow your subscriber base effortlessly.`,
    description: `Create and embed mailing list subscription forms on your website, allowing visitors to opt-in and stay connected with your brand seamlessly.`,
    icon: `subscription-forms`,
    availability: `now`,
  },
  {
    name: `Send emails directly from CRM`,
    short: `Streamline your workflow and enhance efficiency.`,
    description: `Send emails directly from your CRM system, ensuring seamless communication and eliminating the need for switching between platforms.`,
    icon: `crm-emails`,
    availability: `later`,
  },
  {
    name: `Double opt-in`,
    short: `Build trust and enhance deliverability.`,
    description: `Use double opt-in to confirm subscribers' intentions and ensure compliance with regulations, fostering meaningful engagement and better deliverability rates.`,
    icon: `double-optin`,
    availability: `now`,
  },
  // {
  //   name: `Build custom surveys`,
  //   short: `Gain valuable insights and feedback.`,
  //   description: `Create custom surveys tailored to your specific needs, enabling you to gather actionable data and make informed decisions to drive growth.`,
  //   icon: ``,
  //   availability: `soon`,
  // },
  // {
  //   name: `Embedded surveys`,
  //   short: `Gather feedback and engage your audience wherever they are.`,
  //   description: `Send surveys directly in emails or embed them on your website, making it convenient for recipients to provide valuable input and insights.`,
  //   icon: ``,
  //   availability: `soon`,
  // },
  // {
  //   name: `Add contacts from email`,
  //   short: `Add email senders directly to your contact list.`,
  //   description: `Forward one or multiple emails to our automated contact creation to add the sender of each email to your contact list.`,
  //   icon: ``,
  //   availability: `later`,
  // },
  {
    name: `Early access`,
    short: `Your feature requests will be a priority with early access.`,
    description: `Want a feature that isn't available yet? This is your chance to make those requests and have direct communication with the team.`,
    icon: `early-access`,
    availability: `now`,
  },
]
