import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Landing from '../components/landing/Landing'

const LandingPage = () => {
  return (
    // <MainPageTemplate title="Welcome">
      <Landing />
    // </MainPageTemplate>
  )
}

export default LandingPage
