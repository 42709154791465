import React, { useState } from 'react'
import { Alert, AlertTitle, Button, Card, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { EmailSVG, HiddenSVG, VisibleSVG } from '../../assets/icons';

import axios from 'axios';
import { api_endpoint } from '../../lib/Backend';
import { InputField } from '../ui/Fields';


const RegisterForm = () => {
  const [registerEmail, setRegisterEmail] = useState();
  const [registerPassword, setRegisterPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [emailRegistration, setEmailRegistration] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const theme = useTheme();
  const authContext = useAuth();
  const navigate = useNavigate();

  // SHOW PASSWORD FUNCTIONALITY
  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  }

  // EMAIL REGISTRATION
  const registerUser = async () => {
    try {
      setRegisterPassword();
      setConfirmPassword();

      const response = await axios.post(api_endpoint() + `/accounts/api/register/`,
        { 
          'password' : registerPassword, 
          'email' : registerEmail 
        },
        {
          'Content-Type': 'application/json'          
        }).catch( function (error) {
          // The request was made and the server responded with a 400 Bad Request status code due to an issue with the email property
          if (error.response.data.email != null) {
            console.log(error.message);
            console.log(error.response.data);            
            setErrorDisplay("There was an error registering for an account: " + error.response.data.email[0])
        } else if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.log(error.message);
            console.log(error.response.data);
            setErrorDisplay("There was an error registering for an account")
        }
      });


      if (response.status == 201) {
        // await sendVerificationEmail(); // <-- Handled on the backend now
        navigate('/check-email', { state: { email: registerEmail}})
      }

      //authContext.setUser(response.data.id);
      //setIsAuthenticated(true);

    } catch (error) {
      
    }
  }

  const ErrorDisplay = () => {

    return (
      errorDisplay &&
      <Grid container justifyContent="center">

        <Alert severity="warning">
          <AlertTitle>{errorDisplay}</AlertTitle>
        </Alert>
      </Grid>

    )
  }

  // UPDATE USER STATE FROM authContext
  const initializeAccount = async () => {
    await registerUser();
  }

  return (
    <Grid
      container
      // justifyContent="center"
      sx={{
        p: 3,
        pt: 0
      }}
      ts={12}
    >
      <InputField
        // disabled
        label="Email"
        // variant="standard"
        placeholder="Email"
        fullWidth
        value={registerEmail}
        onChange={(e) => { setRegisterEmail(e.target.value); setErrorDisplay(null) }}
        margin
      />
      <InputField
        // disabled
        label="Password"
        type={showPassword ? 'text' : 'password'}
        // variant="standard"
        placeholder="Password"
        fullWidth
        value={registerPassword}
        onChange={(e) => setRegisterPassword(e.target.value)}
        customInputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleShowPassword}>
              {
                showPassword
                  ? <VisibleSVG width={20} style={{ padding: 12 }} color1={theme.palette.mid.main} />
                  : <HiddenSVG width={20} style={{ padding: 12 }} color1={theme.palette.mid.main} />
              }
            </InputAdornment>
          )
        }}
        margin
      />
      <InputField
        // disabled
        label="Confirm Password"
        type={showPassword ? 'text' : 'password'}
        helperText={
          registerPassword === confirmPassword
            ? null
            : <Typography style={{ width: '100%', textAlign: 'center' }}>Passwords do not match</Typography>
        }

        // variant="standard"
        placeholder="Confirm Password"
        fullWidth
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            initializeAccount()
          }
        }}
        margin
      />


      {
        registerEmail && registerPassword && confirmPassword
          ? null
          : <Typography style={{ width: '100%', textAlign: 'center' }}>Please fill out all fields</Typography>
      }

      <Grid
        container
        style={{
          justifyContent:'center',
        }}
      >
        <Button
          variant="contained"
          disabled={registerEmail && registerPassword && registerPassword === confirmPassword ? false : true}
          onClick={initializeAccount}
        >
          Register
        </Button>
        <ErrorDisplay />
      </Grid>
    </Grid>
  )
}

export default RegisterForm


