import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const NavPage = Loadable(lazy(() => import('./pages/NavPage')));
const CRMPage = Loadable(lazy(() => import('./pages/CRMPage')));
const StagesPage = Loadable(lazy(() => import('./pages/StagesPage')));
const FunnelsPage = Loadable(lazy(() => import('./pages/FunnelsPage')));
const FunnelCreatePage = Loadable(lazy(() => import('./pages/FunnelCreatePage')));
const FunnelUpdatePage = Loadable(lazy(() => import('./pages/FunnelUpdatePage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const EmailPage = Loadable(lazy(() => import('./pages/EmailPage')));
const ContactCreatePage = Loadable(lazy(() => import('./pages/ContactCreatePage')));
const ContactEditPage = Loadable(lazy(() => import('./pages/ContactEditPage')));
const SurveysPage = Loadable(lazy(() => import('./pages/SurveysPage')));
const SurveysCreatePage = Loadable(lazy(() => import('./pages/SurveysCreatePage')));
const SurveysTakePage = Loadable(lazy(() => import('./pages/SurveysTakePage')));
const FormsPage = Loadable(lazy(() => import('./pages/FormsPage')));
const FormsCreatePage = Loadable(lazy(() => import('./pages/FormsCreatePage')));
// const FormsTakePage = Loadable(lazy(() => import('./pages/FormsTakePage')));
const CustomFieldsPage = Loadable(lazy(() => import('./pages/CustomFieldsPage')));
const AutomationPage = Loadable(lazy(() => import('./pages/AutomationPage')));
const IconPreviewPage = Loadable(lazy(() => import('./pages/IconPreviewPage')));
const CsvUploadPage = Loadable(lazy(() => import('./pages/CsvUploadPage')));
const PricingPage = Loadable(lazy(() => import('./pages/PricingPage')));
const FeedbackPanelPage = Loadable(lazy(() => import('./pages/FeedbackPanelPage')));
const TagSingle = Loadable(lazy(() => import('./singles/TagSingle')));
const CompleteRegistrationPage = Loadable(lazy(() => import('./pages/CompleteRegistrationPage')));
const EmailVerificationPage = Loadable(lazy(() => import('./pages/EmailVerificationPage')));
const EmailBuilderPage = Loadable(lazy(() => import('./pages/EmailBuilderPage')));
const EmailTemplatesPage = Loadable(lazy(() => import('./pages/EmailTemplatesPage')));
const SignupPage = Loadable(lazy(() => import('./pages/SignupPage')));

export const userRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'nav', element: <NavPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'accounts/login', element: <LoginPage /> },
  { path: 'signup', element: <SignupPage /> },
  { path: 'crm', element: <CRMPage /> },
  { path: 'single-tag', element: <TagSingle /> },
  { path: 'stages', element: <StagesPage /> },
  { path: 'funnels', element: <FunnelsPage /> },
  { path: 'funnel-create', element: <FunnelCreatePage /> },
  { path: 'funnel-update/:funnelId', element: <FunnelUpdatePage /> },
  { path: 'automation', element: <FunnelsPage /> },
  { path: 'csv-upload', element: <CsvUploadPage /> },
  { path: 'email', element: <EmailPage /> },
  { path: 'add-contact', element: <ContactCreatePage /> },
  { path: 'edit-contact', element: <ContactEditPage /> },
  { path: 'surveys', element: <SurveysPage /> },
  { path: 'surveys-create', element: <SurveysCreatePage /> },
  { path: 'surveys-update/:surveyId', element: <SurveysCreatePage /> },
  { path: 'surveys-take/:surveyId', element: <SurveysTakePage /> },
  { path: 'forms', element: <FormsPage /> },
  { path: 'forms-create', element: <FormsCreatePage /> },
  { path: 'forms-update', element: <FormsCreatePage /> },
  { path: 'custom-fields', element: <CustomFieldsPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'feedback-panel', element: <FeedbackPanelPage /> },
  { path: 'icon-preview', element: <IconPreviewPage /> },
  { path: 'complete-registration', element: <CompleteRegistrationPage /> },
  { path: 'check-email', element: <EmailVerificationPage /> },
  { path: 'email-builder', element: <EmailBuilderPage /> },
  { path: 'email-builder/:templateId', element: <EmailBuilderPage /> },
  { path: 'email-templates', element: <EmailTemplatesPage /> },
  {
    // path: '/*', element: <NotFoundPage />
  }
];