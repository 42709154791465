import { useRoutes } from 'react-router-dom';
// import './App.css';
import { userRoutes } from './routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import NavPage from './pages/NavPage';
import { Dialog, IconButton } from '@mui/material';
import { useState } from 'react';
import { MoreMenuSVG } from './assets/icons';
import { LogoHorizontal } from './assets/logos';
import FeedbackPopout from './components/feedback/FeedbackPopout';


function App() {
  const userContent = useRoutes(userRoutes);
  return (
    <ThemeProvider theme={theme}>
      <Header />
      {userContent}
      <Nav />
      <FeedbackPopout />
    </ThemeProvider>
  );
}

export default App;

const Header = () => {
  return (
    <div
      style={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0 24px',
        height:'60px',
        boxShadow:'0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)'
      }}
    >
      <LogoHorizontal height={23} />
    </div>
  )
}

const Nav = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        style={{
          position:'fixed',
          bottom:20,
          right:20
        }}
      >
        <MoreMenuSVG width={25} />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style:{
            padding:24
          }
        }}
      >
        <NavPage />
      </Dialog>
    </>
  )
}