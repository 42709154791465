import { Button, Dialog, Tooltip } from '@mui/material';
import React, { useState, cloneElement } from 'react';
import { useEffect } from 'react';

const ReusableModalTooltip = (props) => {
  const { children, clickToClose, onClose, closeCondition, maxWidth, noBackdropClose, tooltipTitle, tooltipBg, color, arrow, offset, small, placement, overflowable } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  const handleInnerClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (closeCondition === true) {
      setOpen(false);
    }
  })

  const WarningElement = () => {
    return (
      console.error('You need exactly two children to use ReusableModalTooltip')
    )
  }

  return (
    <>
      <Tooltip
        title={tooltipTitle}
        placement={ placement ? placement : "right"}
        arrow={arrow ? true : false}
        sx={{
          color:'blue'
        }}
        PopperProps={{
          modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, offset ? offset : -12],
                },
            },
          ],
          sx: {
            "& .MuiTooltip-tooltip": {
              backgroundColor: tooltipBg ? tooltipBg : 'brand.eight',
              fontSize:small ? 14 : 18,
              px: small ? 1.5 : 2,
              py: small ? .25 : 1,
              borderRadius:3
              // py:1
            },
            "& .MuiTooltip-arrow": {
              color: tooltipBg ? tooltipBg : 'brand.eight',
            }
          }
        }}
      >

        { cloneElement( children.length ? children[0] : children, { onClick: handleClick } ) }
      </Tooltip>
      <Dialog
        open={open}
        onClose={noBackdropClose ? null : handleClose}
        fullWidth
        maxWidth={maxWidth ? maxWidth : "sm"}
        PaperProps={{
          sx:{
            borderRadius:3,
            overflow: overflowable ? 'visible' : 'clip'
          }
        }}
      >
        {
          clickToClose
            ?
              cloneElement( children.length ? children[1] : <WarningElement />, { onClick: handleInnerClick, handleClose: handleClose, handleOpen: handleOpen, open:open } )
            :
              <>
                {
                  noBackdropClose
                    ?
                      <Button
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    :
                      null
                }
                {cloneElement( children.length ? children[1] : <WarningElement />, { handleClose: handleClose, handleOpen: handleOpen, open:open } )}
              </>
        }
        {/* {children[1]} */}
      </Dialog>
    </>
  )
}

export default ReusableModalTooltip