import React from 'react'
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import MainNav from '../components/nav/MainNav';

const MainPageTemplate = (props) => {
  const { children, background, title, hideNav, centered } = props;
  const theme = useTheme();

  return (
    <div
      style={{
        background: background
          ? background
          : '#fff' //theme.palette.background.secondary
        ,
        // minHeight:'100vh'
      }}
    >
      <Helmet>
        <title>{title ? title : ''} | SignedPic</title>
      </Helmet>
      {
        // hideNav
        //   ?
        //     null
        //   :
        //     <MainNav />
      }
      <div
        style={{
          paddingTop: hideNav ? '0' : '0px',
          position:'relative',
          // minHeight:'100vh',
          overflow:'auto',
          display:'flex',
          flexDirection:'column'
          // alignItems:'center'
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default MainPageTemplate